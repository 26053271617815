import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import { boolean, object } from "yup";

import { ReactComponent as OTick } from "../../../Assets/Images/icn/OrangeTick.svg";
import LoadingButton from "components/shared/LoadingButton";

const validationSchema = object().shape({
  terms_acceptance: boolean().oneOf([true], 'Please accept the privacy policy').required(),
})

const PrivacyPolicyForm = ({ handleSubmit }) => {

  return (
    <Row>
      <Col lg="12" className="my-2">
        <Formik
          initialValues={{
            terms_acceptance: false
          }}
          enableReinitialize
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting
          }) => {
            return (
              <Form onSubmit={handleSubmit} autoComplete="off" >
                <div className="py-2">
                  <div className="py-2 d-flex align-items-start gap-10">
                    <div className="cstmInputCheckWrp position-relative ">
                      <input
                        type="checkbox"
                        className="position-absolute w-100 h-100"
                        name="terms_acceptance"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.terms_acceptance}
                        required
                      />

                      <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                        <span className="icn d-none">
                          <OTick />
                        </span>
                      </div>
                    </div>
                    <p className=" m-0 ">
                      I have read and agree to the{" "}
                      {/* <Link to="" className=" fw-sbold"> */}
                      Privacy Policy
                      {/* </Link> */}
                    </p>
                  </div>
                </div>
                <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-5">
                  <LoadingButton
                    type="submit"
                    className="d-inline-flex align-items-center justify-content-center commonBtn btn text-white"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'wait...' : 'Submit'}
                  </LoadingButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Col>
    </Row>
  )
}

export default PrivacyPolicyForm;