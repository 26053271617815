import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

const Address = ({
  question,
  className,
  onChange,
  handleBlur,
  setFieldValue,
  value,
  errors,
}) => {
  const [fields, setFields] = useState({
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone: "",
    phone_type: "",
  })

  useEffect(() => {
    try {
      let fieldValue = value;
      if(fieldValue && typeof fieldValue !== "object") {
        fieldValue = JSON.parse(value);
      }

      if(fieldValue && typeof fieldValue == "object") {
        setFields({
          address_1: fieldValue.address_1 || "",
          address_2: fieldValue.address_2 || "",
          city: fieldValue.city || "",
          state: fieldValue.state || "",
          zip_code: fieldValue.zip_code || "",
          country: fieldValue.country || "",
          phone: fieldValue.phone || "",
          phone_type: fieldValue.phone_type || "",
        });
      }
    } catch {
      console.log("Error parsing values");
    }
  }, [value]);

  const handleChange = (e) => {
    let fieldValue = {...fields};
    if(e.target.name) {
      fieldValue[e.target.name] = e.target.value;
      setFields(fieldValue);
      setFieldValue(`question-${question.id}`, fieldValue);
    }
  }
  
  return (
    <div className="address-fields">
      <Row>
        <Col lg="12" className="my-2">
          <label
            htmlFor=""
            className="form-label fw-bold text-dark"
          >
            Address{" "}
          </label>
          <input
            type="text"
            className={`form-control my-2 ${errors.address_1 && "is-invalid"}`}
            name="address_1"
            onChange={handleChange}
            placeholder="Street address, P.O. box etc"
            onBlur={handleBlur}
            value={fields.address_1}
          />{" "}
          {/* {errors[`question-${question.id}`] && errors[`question-${question.id}`].address_1 &&
            <p className="invalid-feedback">{errors[`question-${question.id}`].address_1}</p>
          } */}
          <input
            type="text"
            className={`form-control my-2 ${errors.address_1 && "is-invalid"}`}
            name="address_2"
            onChange={handleChange}
            placeholder="Apartment, suit, unit, building, floor etc"
            onBlur={handleBlur}
            value={fields.address_2}
          />{" "}
          {/* {errors[`question-${question.id}`] && errors[`question-${question.id}`].address_2 &&
            <p className="invalid-feedback">{errors[`question-${question.id}`].address_2}</p>
          } */}
        </Col>
        <Col lg="12" className="my-2">
          <Row>
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-bold text-dark"
              >
                City{" "}
              </label>
              <input
                type="text"
                className="form-control"
                name="city"
                onChange={handleChange}
                placeholder="City"
                onBlur={handleBlur}
                value={fields.city}
              />{" "}
            </Col>
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-bold text-dark"
              >
                State/Province/Region{" "}
              </label>
              <input
                type="text"
                className="form-control"
                name="state"
                onChange={handleChange}
                placeholder="State/Province/Region"
                onBlur={handleBlur}
                value={fields.state}
              />{" "}
            </Col>
          </Row>
        </Col>
        <Col lg="12" className="my-2">
          <Row>
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-bold text-dark"
              >
                Zip/Postal Code{" "}
              </label>
              <input
                type="text"
                className="form-control"
                name="zip_code"
                onChange={handleChange}
                placeholder="Zip Code"
                onBlur={handleBlur}
                value={fields.zip_code}
              />{" "}
            </Col>
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-bold text-dark"
              >
                Country{" "}
              </label>
              <input
                type="text"
                className="form-control"
                name="country"
                onChange={handleChange}
                placeholder="Country"
                onBlur={handleBlur}
                value={fields.country}
              />{" "}
            </Col>
          </Row>
        </Col>
        <Col lg="12" className="my-2">
          <Row>
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-bold text-dark"
              >
                Phone Number{" "}
              </label>
              <input
                type="text"
                className="form-control"
                name="phone"
                onChange={handleChange}
                placeholder="Phone #"
                onBlur={handleBlur}
                value={fields.phone}
              />{" "}
            </Col>
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-bold text-dark"
              >
                Phone Number Type{" "}
              </label>
              <input
                type="text"
                className="form-control"
                name="phone_type"
                onChange={handleChange}
                placeholder="Cell, Home, Work etc."
                onBlur={handleBlur}
                value={fields.phone_type}
              />{" "}
            </Col>
          </Row>
          {errors[`question-${question.id}`] && errors[`question-${question.id}`].address_1 &&
            <p className="invalid-feedback d-block">{errors[`question-${question.id}`].address_1}</p>
          }
        </Col>
      </Row>
    </div>
  )
};

export default Address;