import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";

const Maintenance = ({ }) => {
  
  return (
    <>
      <section className="maintenance-page">
        <Row>
          <Col lg="6" className="mx-auto">
            <p>
              Our website is currently undergoing scheduled maintenance.
              We'll be back online shortly. Thank you for your patience.
            </p>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Maintenance;
