import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

// icn
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";
import { ReactComponent as OCopy } from "../../../../../../Assets/Images/icn/Copy.svg";

const OldSite = () => {
  return (
    <>
      <div className="top py-2">
        <h4 className="m-0 py-1 fw-bold">Old Nutri-Q Site</h4>
      </div>
      <div className="TabBody py-3">
        <div className="info">
          <div className="py-2">
            <h6 className="m-0 fw-bold py-1">Link Your Old Nutri-Q Account!</h6>
            <p className="m-0 py-1">
              Please enter in the email address you used for your old Nutri-Q
              account. We'll send an email with a key to that email address to
              verify it's really you. You'll need to copy the key in the email
              and enter it in the next step so please make sure you have access
              to the email you enter.
            </p>
          </div>
          <div className="py-2">
            <Form>
              <div className="position-relative iconWithText">
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                />
                <Button
                  className="d-flex align-items-center justify-content-center theme2BorderedBtn position-absolute icn btnSm"
                  style={{ left: "unset", right: "10px" }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OldSite;
