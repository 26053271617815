import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from 'formik';

//imported
import validation from "./validation";
import LoadingButton from "components/shared/LoadingButton"

const Form_ = ({ initialValues, updateData_, isReady, isSelfSignup }) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={() => {
          return validation(isSelfSignup);
        }}
        onSubmit={updateData_}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Email*
                </label>
                <input
                  type="email"
                  className={`form-control ${errors.email && 'is-invalid'}`}
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  onBlur={handleBlur}
                  value={values.email}
                  autoComplete="off"
                />
                {(errors.email && touched.email) &&
                  <p className="invalid-feedback">{errors.email}</p>
                }
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  First Name*
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.first_name && 'is-invalid'}`}
                  name="first_name"
                  onChange={handleChange}
                  placeholder="First Name"
                  onBlur={handleBlur}
                  value={values.first_name}
                  autoComplete="off"
                />
                {(errors.first_name && touched.first_name) &&
                  <p className="invalid-feedback">{errors.first_name}</p>
                }
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  className={`form-control ${errors.last_name && 'is-invalid'}`}
                  onChange={handleChange}
                  placeholder="Last Name"
                  onBlur={handleBlur}
                  value={values.last_name}
                  autoComplete="off"
                />
                {(errors.last_name && touched.last_name) &&
                  <p className="invalid-feedback">{errors.last_name}</p>
                }
              </Col>
              {isSelfSignup == "0" &&
                <Col lg="12" className="my-2">
                  <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                    Sign Up Key
                  </label>
                  <input
                    type="text"
                    name="signup_key"
                    className={`form-control ${errors.signup_key && 'is-invalid'}`}
                    onChange={handleChange}
                    placeholder="Sign up key"
                    onBlur={handleBlur}
                    value={values.signup_key}
                    autoComplete="off"
                  />
                  {(errors.signup_key && touched.signup_key) &&
                    <p className="invalid-feedback">{errors.signup_key}</p>
                  }
                  <p className="formText m-0 py-1 text-muted">
                    Check your invite email for your key
                  </p>
                </Col>
              }
              <Col lg="12" className="my-2">
                {/* <Button className="d-flex align-items-center fw-bold justify-content-center commonBtn w-100">
                        Login Now
                      </Button> */}
                {/* <Link
                      to="/dashboard"
                      className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                    >
                      Login Now
                    </Link> */}
                <LoadingButton
                  type="submit"
                  className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                  disabled={!isReady}
                >
                  {isSubmitting ? 'Loading...' : 'Next'}
                </LoadingButton>

                <div className="mt-4">
                  <p className="formText m-0 text-center text-muted">
                    Already have an account?{" "}
                    <Link to="/login" className=" form-link fw-sbold underline">
                      Sign in here
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Form_;