//action types
export const GET_TODO = 'GET_TODO';
export const VIEW_TODO = 'VIEW_TODO';
export const SAVE_TODO_RESPONSE = "SAVE_TODO_RESPONSE";
export const CLEAR_TODO_DATA = "CLEAR_TODO_DATA";
export const TODO_AUTOSAVE_IN_PROGRESS = "TODO_AUTOSAVE_IN_PROGRESS";
export const FMJ_WORKING = "FMJ_WORKING";

//get 
export function getToDo(data, callback) {
  return {
    type: GET_TODO,
    payload : {data, callback},
  };
}

//view 
export function viewToDo(data, callback) {
  return {
    type: VIEW_TODO,
    payload : {data, callback},
  };
}

// save response 
export function saveToDoResponse(data, callback) {
  return {
    type: SAVE_TODO_RESPONSE,
    payload : {data, callback},
  };
}

// clear current todo data
export function clearToDoData(data, callback) {
  return {
    type: CLEAR_TODO_DATA,
    payload : {data, callback},
  };
}

// To-Do auto save state
export function setTodoAutoSaveState(data, callback) {
  return {
    type: TODO_AUTOSAVE_IN_PROGRESS,
    payload : data,
  };
}
