import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAppointmentTypeDetails } from "store/services/appointmentService";
import * as actions from "store/actions";

// icns
import { ReactComponent as CalIcn } from "Assets/Images/icn/Calender.svg";

// images
import { FULL_NAME, dateTimeRange, formatText } from "helpers/common";
import AppointmentDetailsPopup from "../Modals/AppointmentDetailsPopup";
import BookAppointmentPopup from "../Modals/BookAppointmentPopup";

const AppointmentList = ({ dispatch, appointments }) => {
  const [showApptDetailPopup, setShowApptDetailPopup] = useState(false);
  const [showBookApptPopup, setShowBookApptPopup] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [currentTypeData, setCurrentTypeData] = useState(null);

  const openAppointmentDetailPopup = async (appt) => {
    console.log("appt", appt)
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await getAppointmentTypeDetails(appt?.appointment_type);
      setCurrentTypeData(res?.body);
      setSelectedAppointment(appt);
      setShowApptDetailPopup(true);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err, "==============================");
    }
  }

  const closeAppointmentDetailPopup = (appt) => {
    setSelectedAppointment(null);
    setCurrentTypeData(null);
    setShowApptDetailPopup(false);
  }

  return (
    <>
      <BookAppointmentPopup 
        bookAppointment={showBookApptPopup}
        setBookAppointment={setShowBookApptPopup}
      />
      <AppointmentDetailsPopup
        reschedulePop={showApptDetailPopup}
        setReschedulePop={closeAppointmentDetailPopup}
        currentDetail={selectedAppointment}
        setCurrentDetail={setSelectedAppointment}
        currentTypeData={currentTypeData}
        setCurrentTypeData={setCurrentTypeData}
      />
      <div className="commonCardBox border h-100">
        <div className="top px-lg-4 px-3 py-2 border-bottom">
          <h3 className="m-0 py-2 fw-bold">
            Upcoming Appointments
          </h3>
        </div>
        <div className="CardBody px-lg-4 px-3 py-3">
          {appointments && appointments.length > 0 ?
            appointments.map(appointment => {
              return (
                <Link onClick={() => openAppointmentDetailPopup(appointment)}>
                  <div className="d-flex align-items-center py-2">
                    <div className="imgWrp me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="27"
                        viewBox="0 0 25 27"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_0_8148)">
                          <path
                            d="M22.0702 2.5918H20.8983V0.560547H18.9452V2.5918H6.05457V0.560547H4.10144V2.5918H2.92957C1.31414 2.5918 -0.00012207 3.95863 -0.00012207 5.63867V23.5137C-0.00012207 25.1937 1.31414 26.5605 2.92957 26.5605H22.0702C23.6856 26.5605 24.9999 25.1937 24.9999 23.5137V5.63867C24.9999 3.95863 23.6856 2.5918 22.0702 2.5918ZM23.0468 23.5137C23.0468 24.0737 22.6087 24.5293 22.0702 24.5293H2.92957C2.39109 24.5293 1.953 24.0737 1.953 23.5137V10.1074H23.0468V23.5137ZM23.0468 8.07617H1.953V5.63867C1.953 5.07866 2.39109 4.62305 2.92957 4.62305H4.10144V6.6543H6.05457V4.62305H18.9452V6.6543H20.8983V4.62305H22.0702C22.6087 4.62305 23.0468 5.07866 23.0468 5.63867V8.07617Z"
                            fill="#f4694b"
                          />
                          <path
                            d="M3.71082 12.2402H5.66394V14.2715H3.71082V12.2402ZM7.61707 12.2402H9.57019V14.2715H7.61707V12.2402ZM11.5233 12.2402H13.4764V14.2715H11.5233V12.2402ZM15.4296 12.2402H17.3827V14.2715H15.4296V12.2402ZM19.3358 12.2402H21.2889V14.2715H19.3358V12.2402ZM3.71082 16.3027H5.66394V18.334H3.71082V16.3027ZM7.61707 16.3027H9.57019V18.334H7.61707V16.3027ZM11.5233 16.3027H13.4764V18.334H11.5233V16.3027ZM15.4296 16.3027H17.3827V18.334H15.4296V16.3027ZM3.71082 20.3652H5.66394V22.3965H3.71082V20.3652ZM7.61707 20.3652H9.57019V22.3965H7.61707V20.3652ZM11.5233 20.3652H13.4764V22.3965H11.5233V20.3652ZM15.4296 20.3652H17.3827V22.3965H15.4296V20.3652ZM19.3358 16.3027H21.2889V18.334H19.3358V16.3027Z"
                            fill="#f4694b"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_0_8148">
                            <rect
                              width="25"
                              height="26"
                              fill="white"
                              transform="translate(-0.00012207 0.560547)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="content">
                      <p className="m-0">Appointment Type: {appointment.title}</p>
                      <p className="m-0">Where: {formatText(appointment.location)}</p>
                      <p className="m-0 text-dark fw-sbold">
                        {dateTimeRange(appointment.start_time, appointment.end_time)}
                      </p>
                    </div>
                  </div>
                </Link>
              )
            })
            :
            <>
              No Appointments found
            </>
          }
        </div>
        <div className="CardFooter d-flex justify-content-center py-2 px-lg-4 px-3 border-top">
          <Button
            className="d-flex align-items-center justify-content-center commonBtn btnSm text-white"
            as={Link}
            // to={Path.appointmentForm}
            onClick={() => {
              setShowBookApptPopup(true);
            }}
          >
            <span className="icn me-2">
              <CalIcn />
            </span>{" "}
            Book a new appointment
          </Button>
        </div>
      </div>
    </>
  )
}

const mapStateToPros = (state) => {
  return {
    // appointments: state.Appointment.appointmentBookings,
    appointments: state.App.dashboard?.upcoming_appointments?.data,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AppointmentList);