export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_BASIC_SETTINGS = 'GET_BASIC_SETTINGS';
export const GET_BANNER_SETTINGS = "GET_BANNER_SETTINGS";

// get dashboard data
export function dashboardData(data, callback) {
  return {
    type: GET_DASHBOARD_DATA,
    payload : {data, callback},
  };
}

export function basicSettings(data, callback) {
  return {
    type: GET_BASIC_SETTINGS,
    payload: {data, callback},
  }; 
}

export function bannerSettings(data, callback) {
  return {
    type: GET_BANNER_SETTINGS,
    payload: {data, callback},
  }; 
}