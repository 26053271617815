import ChooseProfile from "components/shared/ChooseProfile/Index";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as actions from 'store/actions';
import { getAuthProfilesList, getProfileDetails } from "store/services/authService";
import * as Path from "Routes/paths";
import { profile } from "store/services/profileService";
import { authLogin, authLogout } from "store/actions/auth";

const SwitchProfile = ({ dispatch, profileDetails }) => {
  const [profiles, setProfiles] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function init() {
      try {
        dispatch(actions.persist_store({ loader: true }));
        dispatch(profile());
        dispatch(actions.persist_store({ loader: false }));
      } catch (error) {
        console.log("error getting profile data", error);
        dispatch(actions.persist_store({ loader: false }));
      }
    }

    init();
  }, [])

  useEffect(() => {
    if (profileDetails && profileDetails?.is_multiple_profiles === "yes") {
      getProfileList();
    }
  }, [profileDetails]);

  const getProfileList = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await getAuthProfilesList();
      if (response?.success === true && response?.body?.length > 0) {
        let profiles = response?.body?.filter(profile => profile.id !== profileDetails.id);
        setProfiles(profiles);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error fetching profiles list", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleChooseProfile = async (profile) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await getProfileDetails(profile.id);
      if(response?.success === true && response?.body) {
        dispatch(authLogout());
        dispatch(authLogin(response?.body));
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error switching profile", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <section className="toDo position-relative">
      <Container fluid>
        <Row>
          <Col lg="6" className="my-2 " >
            <h3 className="m-0 py-1">Select a Profile</h3>
            <div className="py-2">
              <ChooseProfile
                profiles={profiles}
                handleChooseProfile={handleChooseProfile}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const mapStateToPros = (state) => {
  return {
    profileDetails: state.Profile?.user,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SwitchProfile);
