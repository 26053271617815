import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import * as actions from 'store/actions';
import { getPackageDetailsById, getUserByUUID } from "store/services/packageService";
import { Link } from "react-router-dom";

const PackagePaymentSuccess = ({ dispatch, packageDetails }) => {
  const [paymentDetails, setPaymentDetails] = useState();
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const paymentId = urlParams.get('payment_intent');
  console.log("paymentId", paymentId)

  useEffect(() => {
    getPaymentDetails();
    // getPackageDetails();
  }, [])

  const getPaymentDetails = async () => {
    try {
      if(!uuid) {
        return;
      }
      let data = {
        paymentId: paymentId
      }
      dispatch(actions.persist_store({ loader: true }));
      let response = await getPaymentDetails(data);
      if(response.success === true) {
        setUserDetails(response.body);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error fetching user details", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  // if(!paymentDetails) {
  //   return "";
  // }

  return (
    <>
      <section
        className="w-100 position-relative d-flex align-items-center justify-content-center"
        style={{ background: "#f2f2f2", minHeight: "100vh" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="my-2" lg="10">
              <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                <div className="top py-2 bg-white ">
                  <h2 className="m-0">
                    Success
                  </h2>
                  <p className="m-0 py-2">
                    {/* Hi {userDetails.first_name},<br/> */}
                    Your payment was successful
                  </p>
                  <Link
                    to="/dashboard"
                  >
                    Go Back
                  </Link>
                </div>
                {/* <div className="CardBody py-3">
                  <div className="py-2">
                    <div
                      className="commonCardBox p-3"
                      style={{ background: "#f6f6f6" }}
                    >
                      <div className="top py-2">
                        <h4 className="m-0 fw-sbold">{packageDetails.package_name}</h4>
                        <span className="text-muted m-0">{packageDetails.package_desc}</span>
                        <h6 className="m-0">Price: US${packageDetails.package_price}</h6>
                      </div>
                      <div className="content">
                        <div className="py-2">
                          <div className="btnWrp mt-2 FormbtnWrpper">
                            <Button
                              onClick={() => setShowPaymentForm(true)}
                              className="d-flex align-items-center justify-content-center commonBtn "
                            >
                              Purchase Package
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    packageDetails: state.Package.details
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagePaymentSuccess);