import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Path from "Routes/paths";

const LogUpdatedPopup = ({ showPopup, closePopup }) => {

  const navigate = useNavigate();

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Log Updated!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <Row>
            <Col lg="12">
              <div className="py-2">
                <p>Log Updated</p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col lg="12">
              <div className="d-flex justify-content-end gap-10">
                <Button
                  className="d-flex align-items-center justify-content-center commonBtn"
                  onClick={() => {
                    navigate(Path.viewLog)
                  }}
                >
                  View your log
                </Button>
                <Button
                  onClick={closePopup}
                  className="d-flex align-items-center justify-content-center commonBtn"
                >
                  Close
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogUpdatedPopup;
