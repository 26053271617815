import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, PUBLIC_APPT_BOOKING_DETAILS, PUBLIC_BOOK_APPOINTMENT, PUBLIC_CANCEL_APPOINTMENT, PUBLIC_CREATE_APPOINTMENT_PAYMENT_INTENT, PUBLIC_GET_APPOINTMENT_TYPE_LIST, PUBLIC_RESCHEDULE_APPOINTMENT, PUBLIC_SCHEDULER_SETTINGS } from './apiPath'
import { appointmentBookingList, appointmentTypeList, bookAppointment, rescheduleAppointment } from 'store/actions/appointment';

// get Appointment type list
export function getAppointmentTypeList(calendarId) {
  let url = `${MAIN_PATH}${PUBLIC_GET_APPOINTMENT_TYPE_LIST}${calendarId}`;
  return postData(url);
}

//appointment slots
export function getAppointmentSlots(data, callback) {
  let { calendarId, appointmentTypeId, values } = data;
  let url = `${MAIN_PATH}${PUBLIC_GET_APPOINTMENT_TYPE_LIST}${calendarId}?appt_type=${appointmentTypeId}`;
  // let url = `http://localhost:3000/dev/appointment/public/calendar/slots/${calendarId}?appt_type=${appointmentTypeId}`;
  return postData(url, values);
}

// book appointment
export function appointmentBook_(value, callback) {
  let url = `${MAIN_PATH}${PUBLIC_BOOK_APPOINTMENT}`;
  // let url = `http://localhost:3000/dev/appointment/public/book`;
  return postData(url, value);
}

// create appointment payment intent
export function createPublicApptPaymentIntent(appointmentId, callback) {
  let url = `${MAIN_PATH}${PUBLIC_CREATE_APPOINTMENT_PAYMENT_INTENT}${appointmentId}`;
  return postData(url);
}

// get scheduler settings
export function getPractSchedulerSettings(calendarId, callback) {
  let url = `${MAIN_PATH}${PUBLIC_SCHEDULER_SETTINGS}${calendarId}`;
  return getData(url);
}

// get appointment booking details
export function getApptBookingDetails(apptId) {
  let url = `${MAIN_PATH}${PUBLIC_APPT_BOOKING_DETAILS}${apptId}`;
  return getData(url);
}

// reschedule appointment
export function rescheduleAppointment_(id, value, callback) {
  let url = `${MAIN_PATH}${PUBLIC_RESCHEDULE_APPOINTMENT}${id}`;
  return postData(url, value);
}

// cancel appointment
export function cancelAppointment_(id, callback) {
  let url = `${MAIN_PATH}${PUBLIC_CANCEL_APPOINTMENT}${id}`;
  return postData(url);
}