import moment from "moment";
import { useState } from "react";
import { Button } from "react-bootstrap";

const DatePicker = ({ defaultDate, onSelectDate }) => {
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [dateToUse, setDateToUse] = useState(defaultDate);

  const handleDaySelect = (date) => {
    setSelectedDate(date);
    onSelectDate(date);
  }

  const isDateSameAsSelectedDate = (date) => {
    date = moment(date).startOf("day");
    let dateToCompare = moment(selectedDate).startOf("day");
    return moment(date).isSame(dateToCompare);
  }

  const prevWeek = () => {
    let newDateToUse = moment(dateToUse).subtract(1, "weeks");
    setDateToUse(newDateToUse);
  }

  const nextWeek = () => {
    let newDateToUse = moment(dateToUse).add(1, "weeks");
    setDateToUse(newDateToUse);
  }

  const renderDays = () => {
    let days = [];
    for(let i = 0; i <=6; i++){
      let date = moment(dateToUse).weekday(i);
      let isSelectedDate = isDateSameAsSelectedDate(date);
      let row = 
        <div>
          <Button
            variant="transparent"
            onClick={() => {
              handleDaySelect(date)
            }}
            className={`week-day-btn ${isSelectedDate ? "selected-date" : ""}`}
          >
            {moment(date).format("DD ddd")}
          </Button>
        </div>
      days.push(row);
    }
    return days;
  }

  return (
    <div className="custom-calendar-strip">
      <div className="week-btn-container">
        <Button
          variant="transparent"
          onClick={prevWeek}
          className="week-control-btn"
        >
          <i className="fa fa-arrow-left"></i>
        </Button>
      </div>
      {renderDays()}
      <div className="week-btn-container">
        <Button
          variant="transparent"
          onClick={nextWeek}
          className="week-control-btn"
        >
          <i className="fa fa-arrow-right"></i>
        </Button>
      </div>
    </div>
  )
}

export default DatePicker