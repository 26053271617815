import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const SubmitJournalPopup = ({ showPopup, setShowPopup, handleSubmit }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleJournalPop = () => setShowPopup(!showPopup);

  const submitJournal = async () => {
    await handleSubmit(setSubmitting);
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleJournalPop}
        backdrop="static"
        keyboard={false}
        centered
        className="journalPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Food & Mood Journal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <p className="py-2 m-0">
            Are you sure you want to submit your Food & Mood Journal? You won't
            be able to make any changes once it has been submitted.
          </p>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={handleJournalPop}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            No, don’t submit my journal
          </Button>
          <Button
            onClick={submitJournal}
            className="d-flex align-items-center justify-content-center commonBtn"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Please wait..." : "Yes, Submit my journal"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubmitJournalPopup;
