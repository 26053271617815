import { useDispatch } from "react-redux";

import * as actions from "store/actions"

export function useShowLoader() {
  const dispatch = useDispatch();

  function showLoader(loading) {
    dispatch(actions.persist_store({ loader: loading }));
  }
  return showLoader;
}
