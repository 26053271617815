import { useState, useEffect } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { createPaymentIntent } from 'store/services/packageService';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ packageId, userId }) => {
  const [isLoading, setLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [options, setOptions] = useState({
    clientSecret: ""
  })

  useEffect(() => {
    createPayment();
  }, [])

  const handlePaymentFormLoad = () => {
    setLoading(false);
  }

  const createPayment = async () => {
    try {
      let data = {
        packageId: packageId,
        values: {
          amount: 1400,
          currency: "USD",
          userId: userId,
        }
      }
      let response = await createPaymentIntent(data);
      if(response.success === true && response.body.clientSecret) {
        setOptions((options) => {
          return {...options, clientSecret: response.body.clientSecret }
        })
        setIsReady(true);
      }
    } catch(err) {
      console.log("Error creating payment intent", err);
    }
  }


  return (
    <>
      { !isReady ?
        <p>Loading...</p>
        :
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            onLoad={handlePaymentFormLoad}
          />
        </Elements>
      }
    </>
  );
};

export default PaymentForm