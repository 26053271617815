import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import parse from 'html-react-parser';
import { getRecommendationList } from "store/services/recommendationService";
import * as actions from "store/actions";

import { ReactComponent as CopyIcn } from "Assets/Images/icn/Copy.svg";
import { ReactComponent as EditIcon } from "Assets/Images/icn/pencil.svg";
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

const SingleFullscriptRecommendation = ({
  recommendation,
  getDetails,
}) => {

  const [recommendationDetails, setRecommendationDetails] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false);
  const [rows, setRows] = useState({});
  const [additionalRecommendation, setAdditionalRecommendation] = useState([]);
  console.log("recommendationDetails", recommendationDetails)
  useEffect(() => {
    if (recommendationDetails) {
      let rows = recommendationDetails.recommendation ? JSON.parse(recommendationDetails.recommendation) : [];
      let additionalRecommendation = recommendationDetails.additionalRecommendation ? JSON.parse(recommendationDetails.additionalRecommendation) : {};
      setRows(rows);
      setAdditionalRecommendation(additionalRecommendation);
    }
  }, [recommendationDetails])

  useEffect(() => {
    if (isOpen) {
      fetchRecommendationDetails();
    }
  }, [isOpen])

  const fetchRecommendationDetails = async () => {
    try {
      if (!recommendation.id) {
        throw new Error("Invalid recommendation ID");
      }
      setLoading(true);
      let response = await getDetails(recommendation.id);
      if (response.success === true && response.body) {
        setRecommendationDetails(response.body);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error occurred while fetching recommendation details", err);
      setLoading(false);
    }
  }

  if (!recommendation) {
    return "";
  }

  return (
    <Accordion.Item
      eventKey={recommendation.id}
      className="my-2"
      style={{
        border: "1px solid #405C40",
      }}
    >
      <Accordion.Header
        className=""
        style={{
          background: "#FFFDFA",
          color: "#405C40",
        }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <span className="toggleBtn position-absolute"></span>
        <div className="d-flex align-items-center gap-10">
          Fullscript Recommendation {recommendation && `(${recommendation.state})`}
        </div>
        <div className="right d-flex align-items-center gap-10">
          <p className="m-0 text-dark text-lowercase fw-sbold">
            {`submmitted on ${moment(recommendation.created_at).format("MMM DD, YYYY")}`}
          </p>
        </div>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #405C40",
        }}
      >
        {isLoading ?
          <p>Loading...</p>
          :
          <>
            {recommendationDetails &&
              <div className="py-2 AccordionInner bg-light p-3">
                <div className="inner">
                  {recommendationDetails.personal_message && (
                    <div className="py-2">
                      <h6>
                        Message
                      </h6>
                      <p>{parse(recommendationDetails.personal_message)}</p>
                    </div>
                  )}
                  <div className="py-2">
                    <h6>
                      Products
                    </h6>
                    {recommendationDetails.recommendations && recommendationDetails.recommendations.length > 0 ?
                      <ul style={{ listStyleType: "none" }}>
                        {recommendationDetails.recommendations.map(recommendation => {
                          return (
                            <li className="py-2">
                              {recommendation.productDetails &&
                                <Row className="align-items-center">
                                  <Col lg="2">
                                    <img className="w-100" src={recommendation.productDetails.image_url_small} />
                                  </Col>
                                  <Col lg="6">
                                    <h6>
                                      {`${recommendation.productDetails.product.name} by ${recommendation.productDetails.product.brand.name} ${recommendation.units_to_purchase}x`}
                                    </h6>
                                    <p>
                                      {`${recommendation.dosage.amount} ${recommendation.dosage.format} ${recommendation.dosage.frequency}`}
                                    </p>
                                  </Col>
                                </Row>
                              }
                            </li>
                          )
                        })}
                      </ul>
                      :
                      <p>
                        No products for this recommendation.
                      </p>
                    }
                  </div>
                  <div className="d-flex align-items-start justify-content-between gap-10">
                    <div className="right">
                      <div className="d-flex gap-10">
                        <a
                          className="d-flex align-items-center justify-content-center commonBtn btnSm btn text-white"
                          href={recommendationDetails.invitation_url}
                          target="_blank"
                        >
                          <span className="icn me-2">
                            <EditIcon />
                          </span>
                          View/Order on Fullscript
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </>
        }
      </Accordion.Body>
    </Accordion.Item>
  )
}

const TableRows = ({ rows }) => {
  return (
    <>
      {rows && rows.length > 0 &&
        rows.map((row, index) => {
          return (
            <tr key={index}>
              <td className="p-3 border-0">
                <p className="m-0">{row.recommendation}</p>
              </td>
              <td className="p-3 border-0 ws-normal">
                <p className="m-0">
                  {row.benefit}
                </p>
              </td>
              <td className="p-3 border-0">{row.per_day}</td>
              <td className="p-3 border-0">{row.upon_waking}</td>
              <td className="p-3 border-0">{row.with_breakfast}</td>
              <td className="p-3 border-0">{row.midmorning}</td>
              <td className="p-3 border-0">{row.with_lunch}</td>
              <td className="p-3 border-0">{row.midafternoon}</td>
              <td className="p-3 border-0">{row.with_dinner}</td>
              <td className="p-3 border-0">{row.before_bed}</td>
            </tr>
          )
        })
      }
    </>
  )
}

export default SingleFullscriptRecommendation;