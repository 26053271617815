import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "store/actions";
import { getActivePackagesList, getClientPackage, getPackageInvitationsList } from "store/services/packageService";
import PaymentForm from "./Components/PaymentForm/Index";

const MyPackage = ({ dispatch, packageInvitations, activePackage, userDetails }) => {
  const [step, setStep] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [activePackagesList, setActivePackagesList] = useState(null);

  useEffect(() => {
    async function init() {
      try {
        setLoading(true);
        dispatch(actions.persist_store({ loader: true }));
        await Promise.all([
          getClientActivePackagesList(),
          dispatch(getClientPackage()),
          dispatch(getPackageInvitationsList()),
          dispatch(profile()),
        ])
        dispatch(actions.persist_store({ loader: false }));
        setLoading(false);
      } catch (error) {
        console.log("error getting dashboard data", error);
        dispatch(actions.persist_store({ loader: false }));
        setLoading(false);
      }
    }

    init();
  }, []);

  const getClientActivePackagesList = async () => {
    try {
      let response = await getActivePackagesList();
      if(response.success === true && response?.body?.length) {
        setActivePackagesList(response?.body);
      }
    } catch(err) {
      console.log("Error fetching active packages", err);
    }
  }

  const openPaymentForm = (packId) => {
    setSelectedPackage(packId);
    setStep(2);
  }

  if (isLoading) {
    return ""
  }


  return (
    <>
      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">My Packages</h3>
      </div>
      <div className="TabBody py-2">
        <div className="info">
          <Row>
            {step == 1 && (
              <>
                {packageInvitations && packageInvitations.length > 0 && (
                  <Col lg="12">
                    <div className="py-2">
                      <h6 className="m-0 fw-bold text-uppercase">NTA Academics Team</h6>
                      <p className="tex-muted m-0">
                        Hi {userDetails && userDetails.first_name}, <br /> NTA Academics Team has Invited you to purchase the
                        following packages:
                      </p>
                    </div>
                    <div className="py-2">

                      {packageInvitations.map(pack => {
                        return (
                          <div className="commonCardBox my-3">
                            <div className="top px-lg-4 p-3 border-bottom commonContent d-flex align-items-center justify-content-between">
                              <div className="left">
                                <h3 className="m-0 fw-bold">{pack.package_name}</h3>
                              </div>
                              <div className="right">
                                <h6 className="m-0 fw-sbold ws-nowrap theme-clr">
                                  Price{" "}
                                  <h4 className="m-0 fw-bold d-inline-block theme-clr">
                                    ${pack.package_price} (usd)
                                  </h4>
                                </h6>
                              </div>
                            </div>
                            <div className="CardBody py-2 px-lg-4 px-3">
                              <div className="commonContent">
                                <h4 className="m-0 py-2 fw-sbold">Description</h4>
                                <p className="m-0 py-1">
                                  {pack.package_desc}
                                </p>
                              </div>
                            </div>
                            <div className="CardFooter border-top py-2 px-lg-4 px-3">
                              <a
                                href={pack?.invite_link}
                                // target="_blank"
                              >
                                <Button
                                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                                >
                                  Purchase Package
                                </Button>
                              </a>
                              {/* <Button
                                onClick={() => openPaymentForm(pack.id)}
                                className="d-flex align-items-center justify-content-center commonBtn btnSm"
                              >
                                Purchase Package
                              </Button> */}
                            </div>
                          </div>
                        )
                      })

                      }
                    </div>
                  </Col>
                )}
                {activePackagesList?.length && (
                  <Col lg="12">
                    <div className="py-2">
                      <h6 className="m-0 fw-bold text-uppercase">Active Packages</h6>
                      <p className="tex-muted m-0">
                        We have found following active package(s) on your account.
                      </p>
                    </div>
                    <Row>
                      {activePackagesList.map((activePackage) => {
                        return (
                          <Col lg="12">
                            <div className="py-2">
                              <div className="commonCardBox my-1">
                                <div className="top px-lg-4 p-3 border-bottom commonContent d-flex align-items-center justify-content-between">
                                  <div className="left">
                                    <h3 className="m-0 fw-bold">{activePackage.package_name}</h3>
                                  </div>
                                  <div className="right">
                                    <h6 className="m-0 fw-sbold ws-nowrap theme-clr">
                                      Price{" "}
                                      <h4 className="m-0 fw-bold d-inline-block theme-clr">
                                        ${activePackage.package_price} (usd)
                                      </h4>
                                    </h6>
                                  </div>
                                </div>
                                <div className="CardBody py-2 px-lg-4 px-3">
                                  <div className="commonContent">
                                    <h4 className="m-0 py-2 fw-sbold">Description</h4>
                                    <p className="m-0 py-1">
                                      {activePackage.package_desc}
                                    </p>
                                    <div className="py-2">
                                      <p className="m-0">
                                        <strong>Appointments Remaining: </strong>
                                        {activePackage.appointments_remaining}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </Col>
                )}
                { !(packageInvitations && packageInvitations.length) && !(activePackage) && (
                  <Col lg="12">
                    <div className="py-2">
                      <p className="tex-muted m-0">
                        You don't have any current or previous packages to show.
                      </p>
                    </div>
                  </Col>
                )}
              </>
            )}
            {step == 2 && selectedPackage &&
              <Col lg="8" className="py-2">
                <PaymentForm
                  packageId={selectedPackage}
                  userId={userDetails.id}
                />
              </Col>
            }
            {step == 3 &&
              <Col lg="12">
                <div className="CardFooter border-top py-2 px-lg-4 px-3">
                  <Button className="d-flex align-items-center justify-content-center commonBtn btnSm">
                    Purchase Complete
                  </Button>
                  <p className="py-2 m-0">
                    Your Purchase is complete <br />
                    You will recieve and email receipt shortly. If you have
                    any questions please contact NTA Academics.
                  </p>
                </div>
              </Col>
            }
          </Row>
        </div>
      </div>
    </>
  );
};

{/* <>
  <div className="CardFooter border-top py-2 px-lg-4 px-3">
    <Button className="d-flex align-items-center justify-content-center commonBtn btnSm">
      Purchase Complete
    </Button>
    <p className="py-2 m-0">
      Your Purchase is complete <br />
      You will recieve and email receipt shortly. If you have
      any questions please contact NTA Academics.
    </p>
  </div>
</> */}

const mapStateToPros = (state) => {
  return {
    activePackage: state.Package.activePackage,
    packageInvitations: state.Package.packageInvitations,
    userDetails: state.Profile.user,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyPackage);