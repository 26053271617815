import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";

import { accpetPolicy_ } from 'store/services/profileService';
import { viewContentPageData } from 'store/services/contentPageService';
import * as Path from 'Routes/paths';
import * as actions from 'store/actions';
import PrivacyPolicyForm from "./Components/PrivacyPolicyForm";
import ErrorComponent from "components/shared/ErrorComponent";
import { isEmpty } from "lodash";

const PrivacyPolicyAgreement = ({ dispatch, submitting, user, pageDetail }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (user && user?.terms_accepted === "yes") {
      navigate(Path.dashboard);
    }
  }, [user]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let payload = {
        client_id: location.state.clientId,
        values: {
          terms_acceptance: values.terms_acceptance ? 'yes' : 'no'
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      setSubmitting(true);
      let response = await dispatch(accpetPolicy_(payload));
      if(response.success === true) {
        navigate(location.state.redirectTo);
      }
      dispatch(actions.persist_store({ loader: false }));
      setSubmitting(false);
    } catch (err) {
      // console.log(err,  "==============================");
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  //get data
  async function getData() {
    try {
      let slug = {
        slug: 'privacy_policy',
        client_id: location.state.clientId
      }
      setIsLoading(true);
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewContentPageData(slug));
      dispatch(actions.persist_store({ loader: false }));
      setIsLoading(false);
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      setIsLoading(false);
      console.log(err);
    }
  }

  return (
    <>
      <section className="Subscription position-relative">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              {isLoading ? (
                <p>Loading...</p>
              ):isEmpty(pageDetail) ? (
                <ErrorComponent />
              ):(
                <div className="commonContent">
                  <div className="py-2">
                    <h3 className="m-0 fw-bold py-1">
                      {pageDetail.title}
                    </h3>
                  </div>
                  <div className="py-2">
                    <p dangerouslySetInnerHTML={{ __html: pageDetail?.description }} />
                  </div>
                  <PrivacyPolicyForm
                    handleSubmit={handleSubmit}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    user: state.Profile.user,
    pageDetail: state.ContentPage.page,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PrivacyPolicyAgreement);
