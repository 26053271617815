import { ACCOUNT_SETTING_LIST, GET_DEFAULT_FORM_LIST, ADD_UPDATE_ACCOUNT_SETTING, GET_SETTING_DETAIL, GET_ACCOUNT_SETTING_DETAIL, GET_SELF_REGISTERATION, ADD_SELF_REGISTERATION, GET_THIRD_PARTY_INTEGRATION_STATUS, CONNECT_STRIPE_ACCOUNT, DISCONNECT_STRIPE_ACCOUNT } from 'store/actions/accountSetting';

const initialState = {
    isAuthenticated: false,
    accountSettingList: [],
    loader:false,
};

//get 
const getAccountSettingList = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        accountSettingList:data.body,
    };
  return stateObj;
};

//add update account setting
const addUpdateAccountSetting = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
    };
    return stateObj;
};

const AccountSetting = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ACCOUNT_SETTING_LIST:
          return getAccountSettingList(state, payload);
        case ADD_UPDATE_ACCOUNT_SETTING:
          return addUpdateAccountSetting(state, payload);
        default:
        return state;
    };
}
export default AccountSetting;