import { PASSWORD_VALIDATE_REGEX } from 'common/constants';
import { PASSWORD_VALIDATION_MSG } from 'common/strMessage';
import * as Yup from 'yup';

const validation = (isSelfSignup) => {
    let validator = Yup.object({
        first_name: Yup.string().trim().required("Required").max("50", "First Name must be at most 50 characters"),
        last_name: Yup.string().trim().max("50", "Last Name must be at most 50 characters"),
        email: Yup.string().trim().required("Email Required").email("Invalid email address"),
        signup_key: Yup.lazy(value => {
            if(!isSelfSignup || isSelfSignup == "0") {  
                return Yup.string().required("Required");
            } else {
                return Yup.string().optional();
            }
        })
    });

    return validator;
};

export const passwordValidation = Yup.object({
    password: Yup.string().required("Password Required").min(8, PASSWORD_VALIDATION_MSG).matches(PASSWORD_VALIDATE_REGEX, PASSWORD_VALIDATION_MSG),
    confirm_password: Yup.string()
        .required("Please confirm your password")
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default validation;
