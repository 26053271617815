import moment from "moment";
import React, { forwardRef, useState } from "react";

// export const CustomBarChart = forwardRef(function Chart({ innerRef, dataset, title, totalsTitle }, ref) {
const CustomBarChart = ({ innerRef, dataset, title, totalsTitle, defaultShowMaxes }) => {
  const [showPriorityMaxes, setShowPriorityMaxes] = useState(!!defaultShowMaxes);
  
  const toggleShowPriorityMaxes = (e) => {
    setShowPriorityMaxes(e.target.checked);
  };

  const bars = (dataset) => {
    let count = dataset.length;
    let bars = dataset.map((row, i) => {
        return (
          <>
            <Bar data={row} showPriorityMaxes={showPriorityMaxes} />
            {/* { row.childRows &&
              row.childRows.length > 0 &&
              row.childRows.map((childRow) => {
                childRow.color = row.color;
                return (
                  <Bar
                    data={childRow}
                    showPriorityMaxes={showPriorityMaxes}
                    isChild={true}
                  />
                );
            })} */}
          </>
        )
    })

    return bars;
  }

  if (!dataset || !dataset.length > 0) {
    return "Data not found";
  }

  return (
    <>
      <style jsx>
        {`
          .cstmBars:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: var(--bar-width);
            background-color: var(--bar-color); /* Add your styles here */
            z-index: -1;
          }
          .child .cstmBars:after {
          }
          .cstmBars span:not(.unhide) {
            visibility: hidden;
          }
          .child h6 {
            font-size: 0.75rem;
          }
          .child .cstmBars {
            height: 20px;
          }
          .naq2-barchart .child .cstmBars span.unhide {
            position: relative;
            top: -8px;
          }
        `}
      </style>
      <div className="naq2-barchart cstmBarChart position-relative mt-4">
        <h6>{title}</h6>
        <div ref={innerRef} className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className=" border-end py-3 px-3"></th>
                <th className=" border-end py-3 px-3">
                  <span className="fw-sbold d-block text-center">
                    Low Priority
                  </span>
                </th>
                <th className=" border-end py-3 px-3">
                  <span className="fw-sbold d-block text-center">
                    Medium Priority
                  </span>
                </th>
                <th className=" border-end py-3 px-3">
                  <span className="fw-sbold d-block text-center">
                    High Priority
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {bars(dataset)}
            </tbody>
          </table>
        </div>
      </div>
      <div className="py-2">
        <input
          name="show_priority_maxes"
          type="checkbox"
          onChange={toggleShowPriorityMaxes}
          checked={showPriorityMaxes}
        />
        <label className="px-2">Show priority level maxes on chart</label>
      </div>
      <hr></hr>
      <div className="py-2">
        <h6>{totalsTitle}</h6>
        <ul className="symptom-totals" style={{listStyleType: "none"}}>
          {dataset &&
            dataset.length > 0 &&
            dataset.map((row) => {
              return (
                <li className={`${row.isChild === 1 ? "child-row" : ""}`}>
                  <strong>
                    {row.isChild === 1 ? "◦" : "•"}{" "}
                    {`${row.abbr}: ${row.rawTotal}`}</strong>
                  {` chart max of ${row.chart_max}`}
                  {/* {row.childRows && row.childRows.length > 0 &&
                    <ul className="child-symptom-totals">
                      {row.childRows.map(childRow => {
                        return (
                          <li>
                            <strong>{`${childRow.abbr}: ${childRow.rawTotal}`}</strong>
                            {` chart max of ${childRow.priorityMaxes?.high_max}`}
                          </li>
                        )
                      })}
                    </ul>
                  } */}
                </li>
              );
            })}
        </ul>
      </div>
      <hr></hr>
    </>
  );
}

const Bar = ({ data, showPriorityMaxes, isChild = false }) => {
  let bars = {
    low: {
      max: data.priorityMaxes?.low_max,
      width: 0,
    },
    med: {
      max: data.priorityMaxes?.med_max,
      width: 0,
    },
    high: {
      max: data.priorityMaxes?.high_max,
      width: 0,
    },
  };

  if (data.rawTotal <= bars.low.max) {
    bars.low.width = Math.min((data.rawTotal * 100) / bars.low.max, 100);
  } else if (data.rawTotal > bars.low.max && data.rawTotal <= bars.med.max) {
    bars.low.width = 100;
    bars.med.width =
      Math.min(((data.rawTotal - bars.low.max) * 100) / (bars.med.max - bars.low.max), 100);
  } else if (data.rawTotal > bars.med.max) {
    bars.low.width = 100;
    bars.med.width = 100;
    bars.high.width =
    Math.min(((data.rawTotal - bars.med.max) * 100) / (bars.high.max - bars.med.max), 100);
  }

  return (
    <>
      <tr className={`${data?.isChild === 1 && "child"}`}>
        <td className="border-bottom-0 border-end py-1 px-3">
          <h6 className="text-end m-0 fw-sbold ws-nowrap">{`${data.abbr} ${data.rawTotal}`}</h6>
        </td>
        <td className="border-bottom-0 border-end py-1 px-0">
          <p
            barVal="100"
            className={`text-end m-0 fw-sbold ws-nowrap position-relative cstmBars px-3 ${
              !isChild && "py-2"
            } text-dark`}
            style={{ "--bar-width": `${bars.low.width}%`, "--bar-color": `${data.color}`, zIndex: "9" }}
          >
            <span className={showPriorityMaxes && "unhide"}>
              {data.priorityMaxes?.low_max}
            </span>
          </p>
        </td>
        <td className="border-bottom-0 border-end py-1 px-0">
          <p
            barVal="100"
            className={`text-end m-0 fw-sbold ws-nowrap position-relative cstmBars px-3 ${
              !isChild && "py-2"
            } text-dark`}
            style={{ "--bar-width": `${bars.med.width}%`, "--bar-color": `${data.color}`, zIndex: "9" }}
          >
            <span className={showPriorityMaxes && "unhide"}>
              {data.priorityMaxes?.med_max}
            </span>
          </p>
        </td>
        <td className="border-bottom-0 border-end py-1 px-0">
          <p
            barVal="100"
            className={`text-end m-0 fw-sbold ws-nowrap position-relative cstmBars px-3 ${
              !isChild && "py-2"
            } text-dark`}
            style={{ "--bar-width": `${bars.high.width}%`, "--bar-color": `${data.color}`, zIndex: "9" }}
          >
            <span className={showPriorityMaxes && "unhide"}>
              {data.priorityMaxes?.high_max}
            </span>
          </p>
        </td>
      </tr>
    </>
  );
};

export default CustomBarChart;
