import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import * as actions from 'store/actions';
import * as Path from 'Routes/paths';
import { getPackageDetailsById, getUserByUUID } from "store/services/packageService";
import { Link, useParams } from "react-router-dom";

const PaymentSuccess = ({ dispatch, packageDetails, isAuthenticated }) => {
  const [paymentDetails, setPaymentDetails] = useState();
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const { apptId } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const paymentId = urlParams.get('payment_intent');

  useEffect(() => {
    getPaymentDetails();
    // getPackageDetails();
  }, [])

  const getPaymentDetails = async () => {
    try {
      if(!uuid) {
        return;
      }
      let data = {
        paymentId: paymentId
      }
      dispatch(actions.persist_store({ loader: true }));
      let response = await getPaymentDetails(data);
      if(response.success === true) {
        setUserDetails(response.body);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error fetching user details", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  // if(!paymentDetails) {
  //   return "";
  // }

  return (
    <>
      <section
        className="w-100 position-relative d-flex align-items-center justify-content-center"
        style={{ background: "#f2f2f2", minHeight: "100vh" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="my-2" lg="10">
              <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
                <div className="CardBody py-3">
                  <div className="bg-white ">
                    <h2 className="m-0">
                      Success
                    </h2>
                    <p className="m-0 py-2">
                      {/* Hi {userDetails.first_name},<br/> */}
                      Your payment was successful
                    </p>
                    <p>
                      <strong>Payment ID: </strong>
                      {paymentId}
                    </p>
                    <p>
                      <strong>Appointment URL: </strong>
                      <Link
                        to={Path.viewAppointmentPublic.replace(":uuid", apptId)}
                      >
                        {process.env.REACT_APP_URL}{Path.viewAppointmentPublic.replace(":uuid", apptId)}
                      </Link>
                    </p>
                    {isAuthenticated &&
                      <Link to={Path.dashboard}>Go Back</Link>
                    }
                  </div>
                </div>
              </div>
            </Col>
            <Col className="py-2" lg="10">
              
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    packageDetails: state.Package.details
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);