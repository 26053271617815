import { FULL_NAME } from "helpers/common";
import { Link } from "react-router-dom";

const ChooseProfile = ({ profiles, handleChooseProfile }) => {
  return (
    <div>
      {profiles && profiles.length > 0 && (
        <div class="card">
          <ul class="list-group list-group-flush profiles-container">
            {profiles.map(profile => {
              return (
                <li class="list-group-item py-2 profile-link">
                  <Link
                    className="text-dark"
                    onClick={() => {
                      handleChooseProfile(profile)
                    }}
                  >
                    <h6 className="m-0 py-1">{FULL_NAME(profile?.first_name, profile?.last_name)}</h6>
                    <p>{profile.practitioner_name}</p>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ChooseProfile;