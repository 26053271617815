import moment from "moment";

const DateField = ({ question }) => {
  return (
    <>
      <p className="m-0">
        {moment(question.answer).format("MM-DD-YYYY")}
      </p>

    </>
  )
}

export default DateField;