const { useParams, Link, useNavigate, useLocation } = require("react-router-dom")
import { Button, Col, Container, Row } from "react-bootstrap";
import AddDataForm from "../SideTabs/ToDo/Component/TodoForm/Component/AddDataForm/Index";
import { useEffect, useRef, useState } from "react";
import PublicTodoOnboarding from "./Components/PublicTodoOnboarding";
import FinalStep from "./Components/FinalStep";
import { getNAQv1ReportsPublic, savePublicTodoResponse, submitPublicTodo, viewPublicToDoData } from "store/services/publicTodoService";
import { connect, useSelector } from "react-redux";
import * as actions from "store/actions";

import logo from "Assets/Images/logo.png";
import { isEmpty } from "lodash";
import GenerateNAQv1ReportsPdf from "components/shared/NAQv1Reports";
import { NAQV1_ID, NAQV2_ID } from "common/constants";
import { getNAQv2Reports, submitPracticeBNAQ, submitPracticeBNAQv2 } from "store/services/todoService";
import { SUCCESS_MESSAGE } from "common/constantMessage";
import * as Path from 'Routes/paths';
import { clearToDoData } from "store/actions/todo";
import GenerateNAQv2ReportsPdf from "components/shared/NAQv2Reports";

const PublicTodoForm = ({ dispatch, todoPages, todoFormDetails, clientInfo, lastActiveStep }) => {
  const { todoId } = useParams();
  const location = useLocation();
  const todoAutoSaveInProgress = useSelector(state => state.ToDo.autoSaveInProgress);

  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [onboardingComplete, setOnBoardingComplete] = useState(false);
  const [generatingReports, setGeneratingReports] = useState(false);
  const [reportFormId, setReportFormId] = useState(null);

  // const naq1ReportsRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (todoId) {
      getToDoData();
    }

    return () => dispatch(clearToDoData());
  }, []);

  useEffect(() => {
    if(isLoading || !clientInfo?.terms_accepted || clientInfo?.terms_accepted === 'yes'){
      return;
    }

    // redirect to privacy policy acceptance page
    navigate(Path.publicPrivacyPolicyAccept, {state: {
      redirectTo: location.pathname,
      clientId: clientInfo?.client_id,
    }});
  }, [isLoading, clientInfo]);

  const handleClickStart = () => {
    setOnBoardingComplete(true);
  }

  // get ToDo data
  const getToDoData = async () => {
    if (!todoId) {
      return;
    }
    try {
      dispatch(actions.persist_store({ loader: true }));
      setLoading(true);
      await dispatch(viewPublicToDoData(todoId));
      setLoading(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error fetching ToDo details", err);
      setLoading(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const saveFormResponse = async (data, isFinalSubmit, isAutoSave) => {
    try {
      const { pageId, values } = data;
      const callback = (response) => {
        // console.log("response", response);
      };

      // values = JSON.stringify(values)
      let formData = {
        isFinalSubmit,
        questions: values,
        pageId,
      };
      let postData = {
        id: todoId,
        formData,
      };
      await savePublicTodoResponse(postData);
      if(isAutoSave) {
        return;
      }
      
      window.scrollTo(0, 0);
    } catch (err) {
      console.log(err, "==============================");
      throw new Error("Failed to save form response");
    }
  };

  const submitTodo = async () => {
    await submitPublicTodo(todoId);
    
    // if naq is submitted then generate reports
    if ([NAQV1_ID, NAQV2_ID].includes(todoFormDetails.id) && todoFormDetails?.additionalDetails?.from_practice_better === true) {
      // await submitPBNAQv1WithReports();
      setGeneratingReports(true);
      setReportFormId(todoFormDetails.id);
    } else {
      handleToDoCompleted();
    }

  }

  const handleToDoCompleted = () => {
    SUCCESS_MESSAGE("ToDo has been submitted successfully");
    setSubmitted(true);
    // navigate(todo);
  };

  const submitPBNAQv1WithReports = async (naqBlobs) => {
    let repsonse = await submitPracticeBNAQ(todoId, naqBlobs);
    if(repsonse?.success === true) {
      handleToDoCompleted();
    }
  }

  const submitPBNAQv2WithReports = async (naqBlobs) => {
    let repsonse = await submitPracticeBNAQv2(todoId, naqBlobs);
    if(repsonse?.success === true) {
      handleToDoCompleted();
    }
  }

  if (todoFormDetails && todoFormDetails?.todo_status === "completed") {
    return (
      <div className="py-2">
        <p>This form has already been completed; nothing to see here.</p>
      </div>
    )
  }

  if (!isLoading && isEmpty(todoFormDetails)) {
    return (
      <div className="py-2">
        <p>Failed to load form details.</p>
      </div>
    )
  }

  return (
    <>
      {isSubmitted ? (
        <>
          <div className="py-2">
            <h5 className="m-0 fw-bold">Success!</h5>
            <p>Your information was successfully submitted. You can now close this page.</p>
          </div>
        </>
      ) : (
        <section className="toDo position-relative" style={{ zIndex: "9", overflow: "hidden" }}>
          <div className="top-bar p-2 px-5 d-flex align-items-center justify-content-between">
            <div className="logo d-flex align-items-center gap-10">
              <img src={logo} alt="" className="img-fluid" />
              {todoAutoSaveInProgress && (
                <p className="m-0 mx-2 text-white">(Saving...)</p>
              )}
            </div>
            <div className="user-details">
              <p className="m-0">{clientInfo?.practitioner_name}</p>
              <p className="m-0">Client: {clientInfo?.client_name}</p>
            </div>
          </div>
          <Container fluid>
            <Row>
              <Col lg="12" className="my-2">
                <div className="commonContent my-2">
                  <div className="top py-2 border-bottom">
                    {todoFormDetails.form_name && (
                      <h2 className="m-0 py-2 text-capitalize fw-bold">{todoFormDetails.form_name}</h2>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg="12" className="my-2 position-relative">
                {/* <Button
                  onClick={submitPBNAQv1WithReports}
                >
                  Generate NAQ v1 Reports
                </Button> */}
                {todoFormDetails && (
                  <AddDataForm
                    StartStep={
                      <PublicTodoOnboarding
                        clientInfo={clientInfo}
                      />
                    }
                    FinishStep={
                      <FinalStep
                        handleSubmit={submitTodo}
                      />
                    }
                    loading={isLoading}
                    todoId={todoId}
                    todoPages={todoPages}
                    todoFormDetails={todoFormDetails}
                    handleSubmit={saveFormResponse}
                    onToDoCompleted={handleToDoCompleted}
                    initialStep={lastActiveStep}
                    handleSubmitTodo={submitTodo}
                  />
                )}
              </Col>
              <Col lg="12" className="my-2">
                <div className="commonContent py-3 border-top">

                </div>
              </Col>
            </Row>
            {/* NAQ v1 reports */}
            {generatingReports && (
              reportFormId === NAQV1_ID ? (
                <GenerateNAQv1ReportsPdf
                  // ref={naq1ReportsRef}
                  client={{
                    first_name: clientInfo?.client_name
                  }}
                  todoId={todoId}
                  todoFormDetails={todoFormDetails}
                  getReports={getNAQv1ReportsPublic}
                  submitReports={submitPBNAQv1WithReports}
                />
                ):reportFormId === NAQV2_ID ? (
                  <GenerateNAQv2ReportsPdf
                    // ref={naq1ReportsRef}
                    client={{
                      first_name: clientInfo?.client_name
                    }}
                    uuid={todoId}
                    getReports={getNAQv2Reports}
                    submitReports={submitPBNAQv2WithReports}
                  />
                ): (
                  null
                )
            )}
          </Container>
        </section>
      )}
    </>
  )
}

const mapStateToPros = (state) => {
  let questionCount = 1;
  let todoPages = state.ToDo.currentTodoData?.questions?.map((page) => {
    let pageWithNumberedQuestions = page.questions.map((question) => {
      question.number = questionCount;
      questionCount++;
      return question;
    });
    page.questions = pageWithNumberedQuestions;
    return page;
  });

  let lastActiveStep = 1;
  if (state.ToDo.currentTodoData?.formDetails?.additionalDetails) {
    let additionalDetails = state.ToDo.currentTodoData?.formDetails?.additionalDetails;
    let lastActivePage = additionalDetails.client_last_page || 1;
    todoPages.forEach((page, i) => {
      if (Number(page.form_page_id) === Number(lastActivePage)) {
        lastActiveStep = i + 2;
      }
    })
  }

  return {
    todoPages: todoPages,
    todoFormDetails: state.ToDo.currentTodoData.formDetails,
    loader: state.ToDo.loading,
    clientInfo: state.ToDo.currentTodoData.client_info,
    lastActiveStep,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PublicTodoForm);