import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, GET_USER_BY_UUID, GET_PACKAGE_DETAILS, CREATE_PAYMENT_INTENT, GET_CLIENT_PACKAGE, GET_CLIENT_PACKAGE_INVITATIONS, GET_ACTIVE_PACKAGES_LIST } from './apiPath';
import { getActivePackageDetails, getClientPackages, getPackageDetails } from 'store/actions/package';

// export function getUserByUUID(value, callback) {
//   const { uuid } = value;
//   let url = `${MAIN_PATH}${GET_USER_BY_UUID}/${uuid}`;
//   return (dispatch) => getData(url)
//     .then((data) => {
//       dispatch(getContentPage(data, callback));
//       return data;
//     });
// }

// get user by uuid
export function getUserByUUID(value, callback) {
  const { uuid } = value;
  let url = `${MAIN_PATH}${GET_USER_BY_UUID}/${uuid}`;
  // let url = `http://localhost:3000/dev/packages/user/${uuid}`;
  return getData(url);
}

export function getPackageDetailsById(value, callback) {
  const { packageId } = value;
  let url = `${MAIN_PATH}${GET_PACKAGE_DETAILS.replace(":id", packageId)}`;
  // let url = `http://localhost:3000/dev/packages/${packageId}/details`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getPackageDetails(data, callback));
      return data;
    });
}

export function createPaymentIntent(data, callback) {
  const { packageId, values } = data;
  let url = `${MAIN_PATH}${CREATE_PAYMENT_INTENT.replace(":id", packageId)}`;
  // let url = `http://localhost:3000/dev/packages/${packageId}/payment/create`;
  return postData(url, values);
}

// get package purchase payment details
export function getPaymentDetails(value, callback) {
  const { uuid } = value;
  let url = `${MAIN_PATH}${GET_USER_BY_UUID}/${uuid}`;
  return getData(url);
}

// get package
export function getClientPackage(data, callback) {
  let url = `${MAIN_PATH}${GET_CLIENT_PACKAGE}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getActivePackageDetails(data, callback));
      return data;
    });
}

// get package
export function getPackageInvitationsList(data, callback) {
  let url = `${MAIN_PATH}${GET_CLIENT_PACKAGE_INVITATIONS}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getClientPackages(data, callback));
      return data;
    });
}

// get active packages list
export function getActivePackagesList(callback) {
  let url = `${MAIN_PATH}${GET_ACTIVE_PACKAGES_LIST}`;
  // let url = `http://localhost:3000/dev/packages/active-package/list`;
  return getData(url);
}