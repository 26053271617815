import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { decode } from 'html-entities';
import { FULL_NAME, saveAs, UC_FIRST } from 'helpers/common';
import moment from 'moment';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "10px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "15px",
    paddingBottom: "15px",
    // borderBottom: "2px solid #ccc",
    // width: "100%"
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  tableOuter: {
    marginTop: "15px"
  },
  tableHeading: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    color: "#08415c",
    borderBottom: "2px solid #ccc"
  },
  notesContainer: {
    padding: "0px 10px 5px 5px",
    marginBottom: "5px",
    fontSize: "9px",
    backgroundColor: "#eee",
    width: "100%"
  },
  noteHeading: {
    textDecoration: "underline",
    marginTop: "5px",
  },
  noteText: {
    padding: 0,
    margin: 0,
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "start",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "10px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "25%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "8px",
    // fontWeight: "bold",
    // fontFamily: "Helvetica-Bold",
    width: "25%",
    padding: "5px",
    flexWrap: "wrap",
    borderBottom: "1px solid #ccc",
  },
});


export const NAQ2PriorityReportSBGDoc = ({ priorityReport, notes, showNotes, client, date }) => {
  return (
    <Document>

      {/* PAGE 1 */}
      {/* Potential Conditions */}
      <Page style={styles.page}>

        <View style={styles.pageHeader}>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Priority Report: SBG
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>

        {(priorityReport && priorityReport?.length > 0) &&
            priorityReport.map((section) => {
              if (!section.sectionName) {
                return null;
              }
              return (
                <View style={styles.tableOuter}>
                  <Text style={styles.tableHeading}>
                    {section.sectionName}
                  </Text>
                  <View style={styles.tableContainer}>
                    <PriorityTable section={section} notes={notes} showNotes={showNotes}/>
                  </View>
                </View>
              )
            })
        }

        {/* {(priorityReport && priorityReport?.length > 0) &&
          <View style={styles.tableContainer}>
            <PriorityTable items={priorityReport} />
          </View>
        } */}

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

// export const getNAQv2PriorityReportData = async (todoId, client) => {
//   try {
//     if (!todoId) {
//       throw new Error("Invalid ToDo ID");
//     }
//     let data = {
//       todoId,
//       clientId: client.id
//     };
//     const response = await getPriorityReportV2(data);
//     if (response.success === true) {
//       return response.body;
//     }
//     return false;
//   } catch (err) {
//     console.log(err);
//     return false;
//   }
// }

const PriorityTable = ({ section, notes, showNotes }) => {
  const rows = section?.questions && section?.questions.map((row, i) => {
    if(!row) {
      return null;
    }
    let rowNotes = notes?.find(notes => notes.question_id === row.question_id && ( notes.page_id ? notes.page_id === row.page_id : true));
    return (
      <>
        <View style={[tableStyles.row]} key={i}>
          <Text style={[tableStyles.cell, {width: "15%"}]}>{UC_FIRST(row.category)}</Text>
          <Text style={[tableStyles.cell, {width: "35%"}]}>{`${decode(row.question)}`}</Text>
          <Text style={[tableStyles.cell, {width: "35%"}]}>{decode(row.answer)}</Text>
          <Text style={[tableStyles.cell, {width: "15%"}]}>{row.scoreVsMax}</Text>
        </View>
        {showNotes && rowNotes &&
          <View key={`${i}-notes`} style={styles.notesContainer}>
            {rowNotes?.general_notes &&
              <>
                <Text style={styles.noteHeading}>
                  Notes
                </Text>
                <Text style={styles.noteText}>
                  {rowNotes?.general_notes}
                </Text>
              </>
            }
            {(rowNotes?.specific_notes && rowNotes?.specific_notes["Indicated Nutrients"]) &&
              <>
                <Text style={styles.noteHeading}>
                  Indicated Nutrients
                </Text>
                <Text style={styles.noteText}>
                  {rowNotes?.specific_notes["Indicated Nutrients"]}
                </Text>
              </>
            }
            {(rowNotes?.specific_notes && rowNotes?.specific_notes["Targeted Supplements"]) &&
              <>
                <Text style={styles.noteHeading}>
                  Targeted Supplements
                </Text>
                <Text style={styles.noteText}>
                  {rowNotes?.specific_notes["Targeted Supplements"]}
                </Text>
              </>
            }
            {(rowNotes?.specific_notes && rowNotes?.specific_notes["Food Support Sources"]) &&
              <>
                <Text style={styles.noteHeading}>
                  Food Support Sources
                </Text>
                <Text style={styles.noteText}>
                  {rowNotes?.specific_notes["Food Support Sources"]}
                </Text>
              </>
            }
            {(rowNotes?.specific_notes && rowNotes?.specific_notes["Lifestyle Support Ideas"]) &&
              <>
                <Text style={styles.noteHeading}>
                  Lifestyle Support Ideas
                </Text>
                <Text style={styles.noteText}>
                  {rowNotes?.specific_notes["Lifestyle Support Ideas"]}
                </Text>
              </>
            }
            {(rowNotes?.specific_notes && rowNotes?.specific_notes["Foundational Connections"]) &&
              <>
                <Text style={styles.noteHeading}>
                  Foundational Connections
                </Text>
                <Text style={styles.noteText}>
                  {rowNotes?.specific_notes["Foundational Connections"]}
                </Text>
              </>
            }
            {(rowNotes?.specific_notes && rowNotes?.specific_notes["Related Client Concern(s)"]) &&
              <>
                <Text style={styles.noteHeading}>
                  Related Client Concern(s)
                </Text>
                <Text style={styles.noteText}>
                  {rowNotes?.specific_notes["Related Client Concern(s)"]}
                </Text>
              </>
            }
          </View>
        }
      </>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
      <Text style={[tableStyles.headerCell, {width: "15%"}]}>Priority</Text>
        <Text style={[tableStyles.headerCell, {width: "35%"}]}>Question</Text>
        <Text style={[tableStyles.headerCell, {width: "35%"}]}>Answer</Text>
        <Text style={[tableStyles.headerCell, {width: "15%"}]}>Score vs Max</Text>
      </View>
      {rows}
    </View>
  )
};