import React from "react";
import { Button, Modal, Form } from "react-bootstrap";

const EnableDisablePop = ({
  onOffPop,
  setOnOffPop,
  heading,
  currentTabValue,
  setCurrentTabValue,
  handleSubmit,
  handleChange,
  sending,
}) => {
  const handleOnOff = () => {
    setOnOffPop(!onOffPop);
    setCurrentTabValue(null);
  };
  return (
    <>
      <Form>
        <Modal
          show={onOffPop}
          onHide={handleOnOff}
          backdrop="static"
          keyboard={false}
          centered
          className="FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-unstyled ps-0 mb-0">
              <li className="py-1">
                <div className="py-2 d-flex align-items-start">
                  <div className="cstmInputCheckWrp radio position-relative me-2">
                    <input
                      type="radio"
                      name={currentTabValue?.id}
                      className="position-absolute w-100 h-100"
                      value="enable"
                      onChange={handleChange}
                      defaultChecked={
                        currentTabValue?.data_set === "enable" ? true : false
                      }
                    />
                    <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                      <span className="icn d-none rounded-circle">
                        {/* <OTick /> */}
                      </span>
                    </div>
                  </div>
                  <h6 className="fw-sbold m-0 ">Enable</h6>
                </div>
              </li>
              <li className="py-1">
                <div className="py-2 d-flex align-items-start">
                  <div className="cstmInputCheckWrp radio position-relative me-2">
                    <input
                      type="radio"
                      name={currentTabValue?.id}
                      className="position-absolute w-100 h-100"
                      value="disable"
                      onChange={handleChange}
                      defaultChecked={
                        currentTabValue?.data_set === "disable" ? true : false
                      }
                    />
                    <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
                      <span className="icn d-none rounded-circle">
                        {/* <OTick /> */}
                      </span>
                    </div>
                  </div>
                  <h6 className="fw-sbold m-0 ">Disable</h6>
                </div>
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer className="FormbtnWrpper">
            <Button
              className="d-flex align-items-center justify-content-center commonBtn btnSm"
              onClick={handleOnOff}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit}
              className="d-flex align-items-center justify-content-center commonBtn btnSm"
            >
              {sending ? "wait..." : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default EnableDisablePop;
