const PAGE_LIMIT = 10
const LOGIN = {
  CURRENT_MODULE: 'Login',
  TOP_CONTENT: 'Welcome back',
  MAIN_CONTENT: 'Login to your account',
  BUTTON: 'Login now'
}
const FORGOT_PASSWORD = {
  CURRENT_MODULE: 'Forgot Password',
  MAIN_CONTENT: 'We will send a link to reset your password',
  BUTTON: 'Forgot Password',
  BACK: 'Back to login ?'
}
const RESET_PASSWORD = {
  CURRENT_MODULE: 'Reset Password',
  BUTTON: 'Reset Password',
  BACK: 'Back to login ?'
}
const CHANGE_PASSWORD = {
  CURRENT_MODULE: 'Change Password',
  BUTTON: 'Submit',
}
const PROFILE = {
  CURRENT_MODULE: 'Profile',
}
const DASHBOARD = {
  CURRENT_MODULE: 'Dashboard',
}

const CATEGORIES = {
  CURRENT_MODULE: 'Categories',
  TOP_CONTENT: 'Listing',
  ADD: 'Add',
  EDIT: 'Edit',
  VIEW: 'View',
  DELETE: 'Delete',
}
const SUBCATEGORIES = {
  CURRENT_MODULE: 'SubCategories',
  TOP_CONTENT: 'Listing',
  ADD: 'Add',
  EDIT: 'Edit',
  VIEW: 'View',
  DELETE: 'Delete',
}
const RESOURCES = {
  CURRENT_MODULE: 'Resources',
  TOP_CONTENT: 'Listing',
  ADD: 'Add',
  EDIT: 'Edit',
  VIEW: 'View',
  DELETE: 'Delete',
}
const PRACTITIONER = {
  CURRENT_MODULE: 'Practitioner',
  TOP_CONTENT: 'Listing',
  ADD: 'Add',
  EDIT: 'Edit',
  VIEW: 'View',
  DELETE: 'Delete',
}
const FAQ = {
  CURRENT_MODULE: 'FAQ',
  TOP_CONTENT: 'Listing',
  ADD: 'Add',
  EDIT: 'Edit',
  VIEW: 'View',
  DELETE: 'Delete',
}
const SYSTEM_ACCESS = {
  CURRENT_MODULE: 'System Access',
  TOP_CONTENT: 'Listing',
  ADD: 'Add',
  EDIT: 'Edit',
  VIEW: 'View',
  DELETE: 'Delete',
}
const CONTENT_PAGES = {
  CURRENT_MODULE: 'Content Pages',
  TOP_CONTENT: 'Listing',
}

const EARNING = {
  CURRENT_MODULE: 'Package Earning',
  TOP_CONTENT: 'Listing',
  VIEW: 'View',
}
const SUBSCRIPTION_HISTORY = {
  CURRENT_MODULE: 'Subscription History',
  TOP_CONTENT: 'Listing',
  VIEW: 'View',
}


const CONFIGURATION = {
  CURRENT_MODULE: 'Configuration',
  TOP_CONTENT: 'Detail',
}

const SUBSCRIPTION = {
  CURRENT_MODULE: 'Subscription',
  TOP_CONTENT: 'Listing',
}

const TODO = {
  "in-process": {
    status: "Paused",
    icon: `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M12.6694 2.5957C7.14943 2.5957 2.66943 7.0757 2.66943 12.5957C2.66943 18.1157 7.14943 22.5957 12.6694 22.5957C18.1894 22.5957 22.6694 18.1157 22.6694 12.5957C22.6694 7.0757 18.1894 2.5957 12.6694 2.5957ZM13.6694 17.5957H11.6694V15.5957H13.6694V17.5957ZM13.6694 13.5957H11.6694V7.5957H13.6694V13.5957Z"
        fill="#FFCE20"
      />
    </svg>`,
    actionLabel: "Finish",
    className: "WarningText text-capitalize fw-sbold m-0"
  },
  "pending": {
    status: "Not Complete",
    icon: `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clip-path="url(#clip0_0_3453)">
        <path
          d="M12.6694 2.54492C7.13943 2.54492 2.66943 7.01492 2.66943 12.5449C2.66943 18.0749 7.13943 22.5449 12.6694 22.5449C18.1994 22.5449 22.6694 18.0749 22.6694 12.5449C22.6694 7.01492 18.1994 2.54492 12.6694 2.54492ZM16.9694 16.8449C16.5794 17.2349 15.9494 17.2349 15.5594 16.8449L12.6694 13.9549L9.77943 16.8449C9.38943 17.2349 8.75943 17.2349 8.36943 16.8449C7.97943 16.4549 7.97943 15.8249 8.36943 15.4349L11.2594 12.5449L8.36943 9.65492C7.97943 9.26492 7.97943 8.63492 8.36943 8.24492C8.75943 7.85492 9.38943 7.85492 9.77943 8.24492L12.6694 11.1349L15.5594 8.24492C15.9494 7.85492 16.5794 7.85492 16.9694 8.24492C17.3594 8.63492 17.3594 9.26492 16.9694 9.65492L14.0794 12.5449L16.9694 15.4349C17.3494 15.8149 17.3494 16.4549 16.9694 16.8449Z"
          fill="#EE5D50"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_3453">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.669434 0.544922)"
          />
        </clipPath>
      </defs>
    </svg>`,
    actionLabel: "Get Started",
    className: "DangerText text-capitalize fw-sbold m-0",
  },
  "completed": {
    status: "Completed"
  }
}

export { PAGE_LIMIT, LOGIN, RESET_PASSWORD, FORGOT_PASSWORD, PROFILE, DASHBOARD, TODO, CATEGORIES, SUBCATEGORIES, SYSTEM_ACCESS, PRACTITIONER, FAQ, CONTENT_PAGES, CHANGE_PASSWORD, SUBSCRIPTION_HISTORY, CONFIGURATION, SUBSCRIPTION, RESOURCES, EARNING };