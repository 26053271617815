import { EDIT_FEELING_LOG, EDIT_MEAL_LOG } from 'store/actions/myLog';

const initialState = {
    editMealData: null,
    editFeelingData: null,
};

// edit meal log
const editMealLog = (state, payload) => {
  const stateObj = {
    ...state,
    editMealData: payload,
  };
  return stateObj;
};

// edit feeling log
const editFeelingLog = (state, payload) => {
  const stateObj = {
    ...state,
    editFeelingData: payload,
  };
  return stateObj;
};

const MyLog = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case EDIT_MEAL_LOG:
          return editMealLog(state, payload);
        case EDIT_FEELING_LOG:
          return editFeelingLog(state, payload);
        default:
        return state;
    };
}
export default MyLog;