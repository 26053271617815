import { GET_CONTENT_PAGE, ADD_CONTENT_PAGE, VIEW_CONTENT_PAGE, UPDATE_CONTENT_PAGE, DELETE_CONTENT_PAGE } from 'store/actions/contentPage';

const initialState = {
    isAuthenticated: false,
    pages: [],
    page: {},
    loader:false,
    totalItemsCount:0,
    itemsCountPerPage:0,
};

//get 
const getContentPage = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        pages:data.body.data,
        itemsCountPerPage:data.body.per_page,
        totalItemsCount:data.body.total_count
    };
  return stateObj;
};

//add 
const addContentPage = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      pages:data.body,
    };
    return stateObj;
  };

  //update 
const viewContentPage = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      page:data.body,
    };
    return stateObj;
};

  //update 
const updateContentPage = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      pages:data.body,
    };
    return stateObj;
};

//delete 
const deleteContentPage = (state, payload) => {
  const { data } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    pages:[],
  };
  return stateObj;
};

const ContentPage = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_CONTENT_PAGE:
          return getContentPage(state, payload);
        case ADD_CONTENT_PAGE:
            return addContentPage(state, payload);
        case VIEW_CONTENT_PAGE:
          return viewContentPage(state, payload);
        case UPDATE_CONTENT_PAGE:
          return updateContentPage(state, payload);
        case DELETE_CONTENT_PAGE:
          return deleteContentPage(state, payload);
        default:
        return state;
    };
}
export default ContentPage;