import { useState } from "react";
import { Button } from "react-bootstrap";

const FinalStep = ({ previousStep, handleSubmit }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const onClickSubmit = async () => {
    try {
      setSubmitting(true);
      await handleSubmit();
      setSubmitting(false);
    } catch(err) {
      console.log("Error submitting ToDo", err);
      setSubmitting(false);
    }
  }
  return (
    <>
      <div className="py-2">
        <h5 className="m-0 fw-bold">Almost Done!</h5>
        <div className="disclaimer py-2">
          <p>All of your data looks good. Please click 'Submit' to save everything off and exit.</p>
        </div>
        <div className="d-flex align-items-center gap-10">
          <Button
            onClick={previousStep}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
          >
            Back
          </Button>
          <Button
            onClick={onClickSubmit}
            className="d-flex align-items-center justify-content-center commonBtn btnSm"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Please wait..." : "Submit"}
          </Button>
        </div>
      </div>
    </>
  )
}

export default FinalStep;