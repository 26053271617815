import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import AccountDetails from "./components/AccountDetails/Index";
import UpdatePassword from "./components/EditProfile/UpdatePassword";
import UpdateEmail from "./components/EditProfile/UpdateEmail";
import { ReactComponent as BackIcn } from "Assets/Images/icn/Back.svg";
import * as actions from "store/actions";
import { profile as fetchProfile } from "store/services/profileService";
import { connect } from "react-redux";

const AccountInfo = ({ dispatch, loading, profile }) => {
  const [editingProfile, setEditingProfile] = useState(false);

  const handleClickEditProfile = () => {
    setEditingProfile(true);
  }

  const getProfileDetails = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(fetchProfile());
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error getting profile details", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  if(loading && !profile) {
    return <p>Loading...</p>
  }
  
  return (
    <>
      { !editingProfile && profile &&
        <AccountDetails data={profile} editProfile={handleClickEditProfile} />
      }
      { editingProfile &&
        <>
          <div className="top py-2 d-flex align-items-center gap-10">
            <Link
              onClick={() => {
                setEditingProfile(false);
              }}
            >
              <BackIcn />
            </Link>
            <h3 className="m-0 py-1 fw-bold">Edit Log In Information</h3>
          </div>
          <div className="TabBody py-3">
            <div className="info">
              <UpdatePassword
                lastPasswordUpdatedAt={profile.last_password_updated}
              />
              <UpdateEmail
                currentEmail={profile.email}
                getProfile={getProfileDetails}
              />
            </div>
          </div>
        </>
      }
    </>
  );
};

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AccountInfo);