const Weight = ({ question }) => {
    // let answer = question.answer && JSON.parse(question.answer);
    let answer = question.answer;
    let unit = answer?.unit;
    let value = answer?.value;

    if(!value) {
      return <p><i>Nothing Entered</i></p>
    }

    console.log("wegiht", value)

    return (
      <>
          {value &&
            <p className="m-0">
              {value}{` `}
              {unit}
            </p>
          }
      </>
    )
}

export default Weight;