import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// icn

const MyTags = () => {
  return (
    <>
      <div className="top py-2">
        <h4 className="m-0 py-1 fw-bold">My Tags</h4>
      </div>
      <div className="TabBody py-3">
        <div className="info">
          <div className="py-2">
            <p className="m-0 text-dark py-1">
              {" "}
              Click on a tag to edit, update or delete it. The number next to
              the tag indicates how many clients have this tag.
            </p>
            <div className="btnWrp mt-3">
              <Button
                className="d-inline-flex align-items-center justify-content-center CstmLabel btnSm"
                variant="transparent"
                style={{
                  borderColor: "rgb(255, 87, 87)",
                  color: "rgb(255, 87, 87)",
                }}
              >
                <span className="icn me-2 fw-bold">+</span>
                Add New Tag
              </Button>
              <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center gap-10 mt-3">
                <li className="">
                  <span
                    className="CstmLabel"
                    style={{
                      borderColor: "#FF5757 ",
                      color: "#FF5757",
                      background: "#FFF4F4",
                    }}
                  >
                    Important (1)
                  </span>
                </li>
                <li className="">
                  <span
                    className="CstmLabel"
                    style={{
                      borderColor: "#B7B7B7 ",
                      color: "#000",
                      background: "#E8E8E8",
                    }}
                  >
                    Test (0)
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyTags;
