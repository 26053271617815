const DatePicker = ({
  question,
  className,
  onChange,
  handleBlur,
  value,
}) => {
  return (
    <input
      key={`question-${question.id}`}
      name={`question-${question.id}`}
      placeholder="Answer"
      className={className}
      onChange={onChange}
      onBlur={handleBlur}
      value={value}
      type="date"
    />
  );
};

export default DatePicker;