import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// icn
import { ReactComponent as WPlus } from "../../../Assets/Images/icn/plus.svg";
import TableData from "./Component/TableData/TableData";
const Research = () => {
  const location = useLocation();

  return (
    <>
      <section className="Research position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="commonCardBox position-relative bordedr">
                <div className="top border-0 py-3 px-lg-4 px-3 d-flex align-items-center justify-content-between gap-10 flex-wrap">
                  <div className="left">
                    <Form>
                      <div className="position-relative searchForm">
                        <input
                          type="seaerch"
                          placeholder="Search (e.g. 'Carrots' or 'Meat')"
                          className="form-control"
                        />
                      </div>
                    </Form>
                  </div>
                  <div className="right">
                    <ul className="list-unstyled mb-0 d-flex align-items-center gap-10 denotionList flex-wrap">
                      <li className="d-flex align-items-center px-2">
                        <div
                          className="denotion me-2"
                          style={{ background: "#548b54" }}
                        ></div>
                        <p className="m-0">Safe</p>
                      </li>
                      <li className="d-flex align-items-center px-2">
                        <div
                          className="denotion me-2"
                          style={{ background: "#f4694b" }}
                        ></div>
                        <p className="m-0">Uncertain</p>
                      </li>
                      <li className="d-flex align-items-center px-2">
                        <div
                          className="denotion me-2"
                          style={{ background: "#ff5757" }}
                        ></div>
                        <p className="m-0">Avoid</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="CardBody">
                  <TableData />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Research;
