import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

// icn
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";
import { ReactComponent as OCopy } from "../../../../../../Assets/Images/icn/Copy.svg";

const ClientSelfRegisteration = () => {
  return (
    <>
      <div className="top py-2">
        <h4 className="m-0 py-1 fw-bold">Client Self Registration</h4>
      </div>
      <div className="TabBody py-3">
        <div className="info">
          <div className="py-2">
            <h6 className="m-0 fw-bold py-1">
              One link for your clients to sign-up and complete questionnaires.
            </h6>
            <p className="m-0 py-1">
              When clients click on your Client Self-Registration Link they are
              able to self-register to the Nutri-Q site and will be asked to
              fill out the following checked form(s):
            </p>
          </div>
          <div className="py-2">
            <h6 className="m-0 fw-bold py-1">Self-Registration Link #1</h6>
            <div className="commonCardBox p-3 my-2">
              <div className="top mb-2">
                <h4 className="m-0 fw-bold">Default Forms</h4>
                <p className="m-0 py-1">
                  The following checked forms are shown when you select a new
                  To-Do item for an existing or new client. If you no longer
                  wish to see a particular form listed when choosing a new To-Do
                  item then you can uncheck the box next to the form name.
                </p>
              </div>
              <div className="CardBody">
                <Row>
                  <Col lg="6" className="my-2">
                    <ul className="list-unstyled p-0 mb-0">
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col lg="6" className="my-2">
                    <ul className="list-unstyled p-0 mb-0">
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <div className="commonCardBox p-2 d-flex align-items-center justify-content-between gap-10 flex-wrap bg-light">
                  <p className="m-0">
                    https://client.nutri-q.com/private/app/#/signIn?signup=1&ssk=7a86ecb31e9c
                  </p>
                  <Button className="d-flex align-items-center justify-content-center theme2BorderedBtn btnSm">
                    <span className="icn me-2">
                      <OCopy />
                    </span>
                    Copy Link
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2">
            <h6 className="m-0 fw-bold py-1">Self-Registration Link #2</h6>
            <div className="commonCardBox p-3 my-2">
              <div className="top mb-2">
                <h4 className="m-0 fw-bold">Default Forms</h4>
                <p className="m-0 py-1">
                  The following checked forms are shown when you select a new
                  To-Do item for an existing or new client. If you no longer
                  wish to see a particular form listed when choosing a new To-Do
                  item then you can uncheck the box next to the form name.
                </p>
              </div>
              <div className="CardBody">
                <Row>
                  <Col lg="6" className="my-2">
                    <ul className="list-unstyled p-0 mb-0">
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col lg="6" className="my-2">
                    <ul className="list-unstyled p-0 mb-0">
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="py-2 d-flex align-items-start gap-10">
                          <div className="cstmInputCheckWrp position-relative ">
                            <input
                              type="checkbox"
                              name="c-ToDo"
                              className="position-absolute w-100 h-100"
                            />
                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                              <span className="icn d-none">
                                <OTick />
                              </span>
                            </div>
                          </div>
                          <p className=" m-0 ">
                            Initial Interview Questionnaire v1
                          </p>
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <div className="commonCardBox p-2 d-flex align-items-center justify-content-between gap-10 flex-wrap bg-light">
                  <p className="m-0">
                    https://client.nutri-q.com/private/app/#/signIn?signup=1&ssk=7a86ecb31e9c
                  </p>
                  <Button className="d-flex align-items-center justify-content-center theme2BorderedBtn btnSm">
                    <span className="icn me-2">
                      <OCopy />
                    </span>
                    Copy Link
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSelfRegisteration;
