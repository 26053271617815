import React, { useEffect, useState } from "react";
import { Accordion, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as actions from "store/actions"
import CustomBarChart from "../CustomChart";
import DownloadFSBurdenGraph from "../NAQGraphsPdf/FoundationSymptomGraphPdf";
import { getFoundationSymptomBurdenGraphV2 } from "store/services/todoService";

import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

const foundationGroups = [
  {
    "Digestion" : [
      "upperGI",
      "liverGallbladder",
      "smallIntestine",
      "largeIntestine",
    ]
  },
  "Blood Sugar Regulation",
  "Stress",
  "Sleep",
  {
    "Nutrient Dense Diet": [
      "mineralNeeds",
      "vitaminNeed",
      "essentialFattyAcids"
    ]
  }
]

const FoundationSymptomGraph = ({ dispatch, eventKey, todoId, client, submittedOn }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [foundationSBGraphData, setFoundationSBGraphData] = useState(null);

  useEffect(() => {
    if (isOpen && !foundationSBGraphData) {
      getFoundationSBGraphData();
    }
  }, [isOpen]);

  const getFoundationSBGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
      };
      setIsLoading(true);
      dispatch(actions.persist_store({ loader: true }));
      const response = await getFoundationSymptomBurdenGraphV2(data);
      if (response?.success === true && response?.body) {
        // const data = formatFoundations(response.body);
        setFoundationSBGraphData(response?.body);
      }
      setIsLoading(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      setIsLoading(false);
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const formatFoundations = (data) => {
    const foundations = foundationGroups.map(group => {
      let item = {};
      if(typeof group == "object") {
        for(let key in group) {
          let foundationNames = group[key];
          let highestSymptomChild = null
          let childRows = foundationNames.map(name => {
            let match = data.find(foundation => foundation.name == name);
            if(!highestSymptomChild || highestSymptomChild.rawTotal < match.rawTotal ) {
              highestSymptomChild = match;
            }
            return match;
          })
          item = {
            ...highestSymptomChild,
            childRows,
            "name": key,
            "abbr": key,
          };
        }
      } else {
        item = data.find(foundation => foundation.abbr == group);
      }
      return item;
    })

  return foundations
}

  return (
    <Accordion.Item eventKey={eventKey} className="priority-report my-2">
      <Accordion.Header
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        Foundations Symptom Burden Graph
        <span className="toggleBtn position-absolute"></span>
      </Accordion.Header>
      <Accordion.Body
        // onEnter={() => {
        //   setIsOpen(true)
        // }}
        // onExit={() => {
        //   setIsOpen(false)
        // }}
      >
        <div className="accordion-inner">
          {/* <div className="py-2">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left">
              </div>
              <div className="right">
                <DownloadFSBurdenGraph
                  todoId={todoId}
                  client={client}
                  date={submittedOn}
                  label={"Download Graph"}
                  linkType={"button"}
                />
              </div>
            </div>
          </div> */}
          {isLoading ?
            <p>Loading...</p>
            :
            foundationSBGraphData ?
              <>
                <CustomBarChart
                  dataset={foundationSBGraphData}
                  title="Foundations Symptom Burden Graph"
                  totalsTitle="Foundations Totals"
                  defaultShowMaxes={true}
                />
              </>
              :
              <>
                <p>Data not found</p>
              </>

          }
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FoundationSymptomGraph);