import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import "./Public.scss";
// images
import AuthBg from "../../Assets/Images/AuthImg.png";
import { connect } from "react-redux";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";

const PublicLayout = ({ loader }) => {
  let [color, setColor] = useState("#f4694b");
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  return (
    <>
      <section
        className="py-5 d-flex align-items-center justify-content-center w-100"
        style={{ zIndex: "9" }}
      >
        {loader==true &&
          <div className="loader-overlay">
          <div className="main-loader_">
            <FadeLoader
              color={color}
              loading={loader}
              css={override}
              size={300}
            />
          </div>
        </div>
        }
        <Container>
          <Row className="justify-content-end">
            <Col lg="12" className="my-2">
              <Outlet />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return{
      loader: state.persistStore.loader,
  }
};

export default connect(mapStateToPros)(PublicLayout);

