import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { CREATE_FM_LOG, DELETE_FM_LOG, DELETE_FMJ_DAY_LOG, GET_FM_LOG_LIST, GET_FOOD_MOOD_JOURNAL_DATA, GET_FOOD_MOOD_JOURNAL_PROGRESS, MAIN_PATH, SAVE_FOOD_MOOD_JOURNAL_DATA, SUBMIT_FOOD_MOOD_JOURNAL, UPDATE_FM_LOG, VIEW_FOOD_MOOD_SUBMITTED_DATA  } from './apiPath'

// save Food & Mood Journal data
export function saveFoodMoodJournalData(data, callback) {
  let { values } = data;
  let url = `${MAIN_PATH}${SAVE_FOOD_MOOD_JOURNAL_DATA}`;
  return postData(url, values);
}

// get Food & Mood Journal data
export function getFoodMoodJournalData(data, callback) {
  let { values } = data;
  let url = `${MAIN_PATH}${GET_FOOD_MOOD_JOURNAL_DATA}`;
  return postData(url, values);
}

// get Food & Mood Journal progress state
export function getFoodMoodJournalProgress(id) {
  let url = `${MAIN_PATH}${GET_FOOD_MOOD_JOURNAL_PROGRESS}${id}`;
  return getData(url); 
}

// submit Food & Mood Journal
export function submitFoodMoodJournal(id) {
  let url = `${MAIN_PATH}${SUBMIT_FOOD_MOOD_JOURNAL}${id}`;
  return postData(url); 
}

// view Food & Mood Journal submitted data
export function viewFoodMoodSubmittedData(id) {
  let url = `${MAIN_PATH}${VIEW_FOOD_MOOD_SUBMITTED_DATA}${id}`;
  return getData(url); 
}

// log a meal or feeling
export function logFoodOrFeeling(values) {
  let url = `${MAIN_PATH}${CREATE_FM_LOG}`;
  return postData(url, values);
}

// update log
export function updateFoodOrFeelingLog(id, values) {
  let url = `${MAIN_PATH}${UPDATE_FM_LOG}${id}`;
  return putData(url, values);
}

// view submitted food / feeling logs
export function getSubmittedLogData() {
  let url = `${MAIN_PATH}${GET_FM_LOG_LIST}`;
  return getData(url); 
}

// delete food/mood log
export function deleteFoodOrFeelingLog(values){
  console.log("values", values)
  let url = `${MAIN_PATH}${DELETE_FM_LOG}`;
  return deleteData(url, values);
}

// delete food/mood log
export function deleteFMJDayLog(values){
  let url = `${MAIN_PATH}${DELETE_FMJ_DAY_LOG}`;
  return deleteData(url, values);
}