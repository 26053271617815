import { FULL_NAME } from "helpers/common";
import { Button } from "react-bootstrap";

const PublicTodoOnboarding = ({ clientInfo, nextStep, previousStep, currentStep, totalSteps }) => {
  return (
    <>
      <div className="py-2">
        <h5 className="m-0 fw-bold">Hello {clientInfo?.client_name}</h5>
        <div className="disclaimer py-2">
          <p>This form was sent to <strong>{clientInfo?.client_name}</strong> by <strong>{clientInfo?.practitioner_name}</strong> and should only be completed by <strong>{clientInfo?.client_name}.</strong> If this is not <strong>{clientInfo?.client_name}</strong> please close this window.</p>
          <p>This shouldn't take long to fill out but this information is incredibly important so please be as accurate as possible with your answers.</p>
          <p>We save everything off after each step so feel free to take your time filling it out.</p>
        </div>
        <Button
          onClick={nextStep}
          className="d-flex align-items-center justify-content-center commonBtn btnSm"
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default PublicTodoOnboarding;