//action types
export const ACCOUNT_SETTING_LIST = 'ACCOUNT_SETTING_LIST';
export const ADD_UPDATE_ACCOUNT_SETTING = 'ADD_UPDATE_ACCOUNT_SETTING';

//get 
export function getAccountSettingList_(data, callback) {
    return {
      type: ACCOUNT_SETTING_LIST,
      payload : {data, callback},
    };
}

//add setting
export function addUpdateAccountSetting(data, callback) {
    return {
      type: ADD_UPDATE_ACCOUNT_SETTING,
      payload : {data, callback},
    };
}