import React from "react";
import { Link } from "react-router-dom";

const MyTemplate = () => {
  return (
    <>
      <div className="top py-2">
        <h4 className="m-0 py-1 fw-bold">My Template</h4>
      </div>
      <div className="TabBody py-3">
        <div className="info">
          <div className="py-2">
            <h6 className="m-0 fw-bold py-1">Recommendation Templates</h6>
            <p className="m-0 text-muted py-1">
              You don't have any Recommendation Templates yet. Create a template
              based off of an existing client's recommendation using the
              'Duplicate' button. Then you can easily use that same
              recommendation for other clients in the future.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyTemplate;
