import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./Auth.scss";
// images
import AuthBg from "../../Assets/Images/AuthImg.png";
import { connect, useSelector } from "react-redux";
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import * as Path from "Routes/paths";
import BannerMessage from "components/shared/BannerMessage";

const AuthLayout = ({ loader }) => {
  let [color, setColor] = useState("#f4694b");
  const location = useLocation();
  const { pathname } = location;

  const bannerSettings = useSelector((state) => state.App.bannerSettings);

  const override = css`
    display: block;
    margin: 0 auto;
  `;
  return (
    <>
      {bannerSettings?.message && (
        <div className="nta-banner mb-2 fixed-top">
          <BannerMessage
            data={bannerSettings}
          />
        </div>
      )}
      <section
        className="AuthLayout py-5 d-flex align-items-center justify-content-center w-100"
        style={{ zIndex: "9" }}
      >
        {loader == true &&
          <div className="main-loader_">
            <FadeLoader color={color} loading={loader} css={override} size={300} />
          </div>
        }
        <Col lg="6" className="imgWrpper h-100 position-absolute">
          <img
            src={AuthBg}
            alt=""
            className="img-fluid w-100 h-100"
            style={{ objectFit: "cover" }}
          />
        </Col>
        <Container>
          <Row className="justify-content-end">
            <Col lg="6" className="my-2">
              <Outlet />
            </Col>
          </Row>
          {pathname === Path.login && (
            <Row className="justify-content-end relative">
              <Col lg="6" className="position-absolute" style={{ right: 0, bottom: 10 }}>
                <a href="https://nutri-q.com/privacy-policy/" target="_blank" className="" style={{ left: 20, bottom: 10 }}>Privacy Policy</a>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    loader: state.persistStore.loader,
  }
};

export default connect(mapStateToPros)(AuthLayout);

