import React, { useEffect, useState } from "react";

const Weight = ({
  question,
  className,
  onChange,
  handleBlur,
  setFieldValue,
  value,
  errors,
}) => {
  const [fields, setFields] = useState({
    unit: "lbs",
    value: ""
  })

  useEffect(() => {
    try {
      let fieldValue = value;
      if(fieldValue && typeof fieldValue !== "object") {
        fieldValue = JSON.parse(value);
      }

      if(fieldValue && typeof fieldValue == "object") {
        setFields({
          unit: fieldValue.unit,
          value: fieldValue.value,
        });
      }
    } catch {
      console.log("Error parsing values");
    }
  }, [value]);

  const handleChangeInput = (e) => {
    let fieldValue = {
      unit: fields.unit,
      value: e.target.value
    }
    setFieldValue(`question-${question.id}`, fieldValue)
  }

  const onChangeUnit = (unit) => {
    setFields({
      unit,
      value: "",
    })
  }

  return (
    <div key={`question-${question.id}`}>
      <div className="d-flex justify-content-start gap-10">
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp radio position-relative ">
            <input
              type="radio"
              className={`position-absolute w-100 h-100 ${className}`}
              value={"lbs"}
              onChange={() => {
                onChangeUnit("lbs");
              }}
              checked={fields.unit == "lbs"}
            />
            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">
                {/* <OTick /> */}
              </span>
            </div>
          </div>
          <p className="m-0">Pounds</p>
        </div>
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp radio position-relative ">
            <input
              type="radio"
              className={`position-absolute w-100 h-100 ${className}`}
              value={"kg"}
              onChange={() => {
                onChangeUnit("kg");
              }}
              checked={fields.unit == "kg"}
            />
            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">
                {/* <OTick /> */}
              </span>
            </div>
          </div>
          <p className="m-0">Kilograms</p>
        </div>
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp radio position-relative ">
            <input
              type="radio"
              className={`position-absolute w-100 h-100 ${className}`}
              value={"st"}
              onChange={() => {
                onChangeUnit("st");
              }}
              checked={fields.unit == "st"}
            />
            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">
                {/* <OTick /> */}
              </span>
            </div>
          </div>
          <p className="m-0">Stones</p>
        </div>
      </div>
      <div className="py-2 w-50">
        <div class="input-group">
          <input
            type="number"
            name={`question-${question.id}`}
            className={className}
            onChange={handleChangeInput}
            value={fields.value}
          />
          <div class="input-group-append">
            <span class="input-group-text" style={{height: "50px"}}>{fields.unit}</span>
          </div>
        </div>
        {errors[`question-${question.id}`] &&
          <p className="invalid-feedback">{errors[`question-${question.id}`].value}</p>
        }
      </div>
    </div>
  );
};

export default Weight;