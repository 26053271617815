const TextArea = ({
  question,
  className,
  onChange,
  handleBlur,
  value,
}) => {
  return (
    <textarea
      key={`question-${question.id}`}
      name={`question-${question.id}`}
      placeholder="Answer"
      className={`${className} resizable`}
      onChange={onChange}
      onBlur={handleBlur}
      value={value}
      rows={5}
    ></textarea>
  );
};

export default TextArea;