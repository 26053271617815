import { FOOD_MOOD_DIRECTIONS_PDF } from "common/constants";
import { convertLocalDateToUTC } from "helpers/common";
import moment from "moment";

export const fieldLabels = {
  foods: "Foods",
  drinks: "Drinks",
  supplements: "Supps, Herbs & Meds",
  feeling: "Energy & Mood",
  movement: "Movement & Relaxation",
  digestion: "Digestion & Reactions"
}

export const timeRowFields = {
  foods: {
    value: ""
  },
  drinks: {
    value: ""
  },
  supplements: {
    value: ""
  },
  feeling: {
    value: ""
  },
  movement: {
    value: ""
  },
  digestion: {
    value: ""
  }
}

export const fieldInstructions = {
  foods: "Log all food eaten during the selected time period.",
  drinks: "Log all beverages consumed during the selected time period including coffee, tea, alcohol, water, etc.",
  supplements: "Log all supplements, herbs, or medications you took during the selected time period; include product name and dosage of each.",
  feeling: "Keep track of your energy level and mood throughout the day, especially as related to meals.",
  movement: "Log all relaxation, movement and exercise during the selected time period.",
  digestion: `Log all digestive reactions and/or bowel movements during the selected time period. If you have a bowel movement, note the time and <a target="_blank" href="${FOOD_MOOD_DIRECTIONS_PDF}#page-2">Bristol number</a>.`
}

export const singleTimeRow = {
  start_time: "",
  end_time: "",
  ...timeRowFields
}

export const FMSingleDayFields = {
  log_date: "",
  wake_time: "00:00",
  sleep_time: "00:00",
  food_served: []
}

export const LOG_DATE_FORMAT = "YYYY-MM-DD";

export const TIME_FORMAT_24 = "HH:mm";

export const TIME_FORMAT_12 = "hh:mm a";

export const getDefaultCurrentDayData = (date) => {
  return ({
    ...FMSingleDayFields,
    // log_date: convertLocalDateToUTC(date, LOG_DATE_FORMAT),
    log_date: moment(date).format(LOG_DATE_FORMAT),
  })
}
