import { useStripe, useElements, PaymentElement, ExpressCheckoutElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import * as Path from "Routes/paths";

const CheckoutForm = ({ onLoad, appointment, returnUrl, callback }) => {
  const [isLoading, setLoading] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if(elements) {
      const element = elements.getElement('payment')
      element.on('ready', () => {
        setLoading(false);
        onLoad();
      })
    }
  }, [elements])

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: returnUrl || process.env.REACT_APP_URL,
        },
        redirect: typeof callback == "undefined" ?  "always" : "if_required"
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
        typeof callback == "function" && callback(result.error);
      } else if(result.paymentIntent?.status === "succeeded") {
        typeof callback == "function" && callback(null, result.paymentIntent);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {/* <ExpressCheckoutElement /> */}
      <div className="btnWrp mt-2 FormbtnWrpper py-2">
        <Button
          className="d-flex align-items-center justify-content-center commonBtn "
          disabled={!stripe || isLoading}
          type="submit"
        >
          {isLoading ? "Please wait..." : "Submit"}
        </Button>
      </div>
    </form>
  )
};
export default CheckoutForm;
// export default function InjectedCheckoutForm() {
//   return (
//     <ElementsConsumer>
//       {({stripe, elements}) => (
//         <CheckoutForm stripe={stripe} elements={elements} />
//       )}
//     </ElementsConsumer>
//   )
// }