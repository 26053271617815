import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
//imported
import { login } from 'store/services/authService';
import * as Path from 'Routes/paths';
import * as actions from 'store/actions';
import Form from './Form'
import { authLogin, getAuthProfiles, setAuthToken } from "store/actions/auth";

const Login = ({ dispatch, submitting }) => {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [storedCredentials, setStoredCredentials] = useState(null);

  const redirectTo = decodeURI(localStorage.getItem("redirectTo"));

  const initialValues = {
    email: storedCredentials ? storedCredentials?.email : "",
    password: storedCredentials ? storedCredentials?.password : ""
  };

  // Handle changes in the "Remember Me" checkbox
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    let values_ = localStorage.getItem('credentials_');
    values_ = JSON.parse(values_);
    if (values_) {
      setStoredCredentials({ email: values_?.email, password: values_?.password })
      setRememberMe(true)
    }
  }, [])

  const submitData_ = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const callback = (response) => {
        
      }
      if (rememberMe) {
        let email = values.email
        let password = values.password
        const credentials = { email, password };
        localStorage.setItem('credentials_', JSON.stringify(credentials));
      } else {
        localStorage.removeItem('credentials_');
      }
      values = JSON.stringify(values)
      let response = await login(values, callback);
      const { body, success } = response;
      if(success == true){
        if(body?.is_multiple_profiles === true) {
          if(body?.token) {
            dispatch(setAuthToken(body?.token));
          }
          dispatch(getAuthProfiles(response));
          navigate(Path.selectProfile);
        } else {
          let user = body && body?.profiles[0];
          user.token = body?.token;
          dispatch(authLogin(user));
          if(redirectTo){
            navigate(redirectTo);
            localStorage.removeItem("redirectTo");
          }
          // if (profile && profile.terms_accepted === 'no') {
          //   navigate(Path.dashboardTermsCondition)
          // }
        }
      }
      setSubmitting(false);
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
    }
  }

  return (
    <>
      <div className="FormInner py-5 bg-white px-sm-5 px-4">
        <div className="top mb-3">
          <label className="m-0 form-label">Welcome back</label>
          <h2 className="m-0 fw-bold">Sign in to your client account</h2>
        </div>
        <div className="FormBody">
          <Form
            initialValues={initialValues}
            submitting={submitting}
            updateData_={submitData_}
            rememberMe={rememberMe}
            handleRememberMeChange={handleRememberMeChange}
          />
        </div>
      </div>
    </>
  );
};


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(Login);