import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { MAIN_PATH, RECOMMENDATION_LIST, RECOMMENDATION_DETAILS, FULLSCRIPT_RECOMMENDATION_LIST, FULLSCRIPT_RECOMMENDATION_DETAILS  } from './apiPath'
import { getFullscriptRecommendations_, getRecommendationList_ } from 'store/actions/recommendation';

// get recommendations list
export function getRecommendationList(callback) {
  let url = `${MAIN_PATH}${RECOMMENDATION_LIST}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getRecommendationList_(data, callback));
      return data;
    });
}

export function getRecommendationDetails(data, callback) {
  let { recommendationId } = data;
  let url = `${MAIN_PATH}${RECOMMENDATION_DETAILS}${recommendationId}`;
  return getData(url)
}

// get fullscript recommendations list
export function getFullscriptRecommendations(callback) {
  let url = `${MAIN_PATH}${FULLSCRIPT_RECOMMENDATION_LIST}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getFullscriptRecommendations_(data, callback));
      return data;
    });
}

// get fullscript recommendations list
export function getFullscriptRecommendationDetails(data, callback) {
  let { recommendationId } = data;
  let url = `${MAIN_PATH}${FULLSCRIPT_RECOMMENDATION_DETAILS}${recommendationId}`;
  return getData(url)
}