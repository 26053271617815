const Checkbox = ({ question }) => {
  let answers = [];
  try {
    // let answer = question.answer && JSON.parse(question.answer);
    let answer = question.answer;
    let checkboxOptions = question.other_column_data;
    answers = checkboxOptions.map((option) => {
      if (
        option !== null &&
        typeof option === "object" &&
        !Array.isArray(option) &&
        typeof option.answer !== "undefined"
      ) {
        if(answer.includes(option.answer)) {
          return option.answer;
        }
      } else {
        if(answer.includes(option)) {
          return option;
        }
      }
    })
    answers = answers && answers.filter(ans => !!ans);
  } catch(err) {
    console.log("Error parsing checkbox response", err);
  }

  return (
    <>
      <p className="m-0">
        {answers && answers.length > 0 && (
          answers.join(", ")
        )}
      </p>

    </>
  )
}

export default Checkbox;