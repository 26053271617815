const NumberRangeInput = ({
  question,
  className,
  onChange,
  handleBlur,
  value,
}) => {
  return (
    <>
      <p>{question.question_help}</p>
      <input
        key={`question-${question.id}`}
        name={`question-${question.id}`}
        className={className}
        onChange={onChange}
        onBlur={handleBlur}
        value={value}
        type="number"
        placeholder="Please enter a number"
      />
    </>
  );
};

export default NumberRangeInput;