import React, { useEffect } from "react";
import { Accordion, Col, Container, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MiniQuestionnaire from "./Component/AccordionContent/MiniQuestionnaire/Index";
import NTAStudent from "./Component/AccordionContent/NTAStudent/Index";
import NutritionalAssessment from "./Component/AccordionContent/NutritionalAssessment/Index";
import InitialInterview from "./Component/AccordionContent/InitialInterview/Index";
import { getToDoList } from "store/services/todoService";
import ToDoList from "./Component/ToDoList/Index";
import SubmittedData from "./Component/SubmittedData/Index";
import * as actions from 'store/actions';
import { getPractitionerSettings } from "store/services/authService";

const ToDo = ({ dispatch, pendingToDoItems, completedTodoItems, practSettings }) => {

  useEffect(() => {
    getToDo();
    getPractSetttings();
  }, [])
  
  const getToDo = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getToDoList());
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error occurred while fetching ToDo listing", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const getPractSetttings = async () => {
    try {
      await dispatch(getPractitionerSettings());
    } catch (err) {
      console.log("Error fetching pracitioner settings", err);
    }
  }

  return (
    <>
      <section className="toDo position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <ToDoList
                data={pendingToDoItems}
                practName={practSettings?.scheduler_display_name}
              />
              <div className="py-2">
                <div className="commonContent py-2">
                  <h3 className="m-0 text-uppercase">Submitted Data</h3>
                  <p className="m-0 py-1">
                    Below is all of your previously submitted data.
                  </p>
                </div>
                <SubmittedData completedTodoItems={completedTodoItems} />
                {/* <Accordion className="my-3 ColoredAcorrdion">
                  <Accordion.Item
                    eventKey="0"
                    className="my-2"
                    style={{
                      border: "1px solid #405C40",
                    }}
                  >
                    <Accordion.Header
                      className=""
                      style={{
                        background: "#FFFDFA",
                        color: "#405C40",
                      }}
                    >
                      <span className="toggleBtn position-absolute"></span>
                      <div className="d-flex align-items-center gap-10">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                          >
                            <path
                              d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        Mini Questionnaire
                      </div>
                      <div className="right d-flex align-items-center gap-10">
                        <p className="m-0 text-dark text-lowercase fw-sbold">
                          submitted on Sep 12, 2022
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body
                      style={{
                        borderTop: "1px solid #405C40",
                      }}
                    >
                      <MiniQuestionnaire />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="1"
                    className=" my-2"
                    style={{
                      border: "1px solid #f4694b",
                    }}
                  >
                    <Accordion.Header
                      className=""
                      style={{
                        background: "#FFF8EF",
                        // border: "1px solid #f4694b",
                        color: "#f4694b",
                      }}
                    >
                      <span className="toggleBtn position-absolute"></span>
                      <div className="d-flex align-items-center gap-10">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                          >
                            <path
                              d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        Nutritional Assessment Questionnaire
                      </div>
                      <div className="right d-flex align-items-center gap-10">
                        <p className="m-0 text-dark text-lowercase fw-sbold">
                          submitted on Sep 12, 2022
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body
                      style={{
                        borderTop: "1px solid #f4694b",
                      }}
                    >
                      <NutritionalAssessment />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="2"
                    className=" my-2"
                    style={{
                      border: "1px solid #2B3674",
                    }}
                  >
                    <Accordion.Header
                      className=""
                      style={{
                        background: "#F9FAFF",
                        // border: "1px solid #2B3674",
                        color: "#2B3674",
                      }}
                    >
                      <span className="toggleBtn position-absolute"></span>

                      <div className="d-flex align-items-center gap-10">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                          >
                            <path
                              d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        Initial Interview Questionnaire
                      </div>
                      <div className="right d-flex align-items-center gap-10">
                        <p className="m-0 text-dark text-lowercase fw-sbold">
                          submitted on Sep 12, 2022
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body
                      style={{
                        borderTop: "1px solid #2B3674",
                      }}
                    >
                      <InitialInterview />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="3"
                    className="my-2"
                    style={{
                      border: "1px solid #8E8E8E",
                    }}
                  >
                    <Accordion.Header
                      className=""
                      style={{
                        background: "#EBEBEB",
                        color: "#8E8E8E",
                      }}
                    >
                      <span className="toggleBtn position-absolute"></span>
                      <div className="d-flex align-items-center gap-10">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                          >
                            <path
                              d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        NTA Student Disclaimer
                      </div>
                      <div className="right d-flex align-items-center gap-10">
                        <p className="m-0 text-dark text-lowercase fw-sbold">
                          submitted on Sep 12, 2022
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body
                      style={{
                        borderTop: "1px solid #405C40",
                      }}
                    >
                      <NTAStudent />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey="4"
                    className="my-2"
                    style={{
                      border: "1px solid #405C40",
                    }}
                  >
                    <Accordion.Header
                      className=""
                      style={{
                        background: "#FFFDFA",
                        color: "#405C40",
                      }}
                    >
                      <span className="toggleBtn position-absolute"></span>
                      <div className="d-flex align-items-center gap-10">
                        <span className="icn me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                          >
                            <path
                              d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                              fill="currentColor"
                            />
                            <path
                              d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        Food & Mood Journal
                      </div>
                      <div className="right d-flex align-items-center gap-10">
                        <p className="m-0 text-dark text-lowercase fw-sbold">
                          submitted on Sep 12, 2022
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body
                      style={{
                        borderTop: "1px solid #405C40",
                      }}
                    ></Accordion.Body>
                  </Accordion.Item>
                </Accordion> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
const mapStateToPros = (state) => {
  return {
    pendingToDoItems: state.ToDo.items?.pending,
    completedTodoItems: state.ToDo.items?.completed,
    practSettings: state.Auth.practitionerSettings,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ToDo);