//action types
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_ACCEPT_INVITE = 'AUTH_ACCEPT_INVITE';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const GET_PRACTITIONER_DETAILS = "GET_PRACTITIONER_DETAILS";
export const GET_PRACTITIONER_SETTINGS = "GET_PRACTITIONER_SETTINGS";
export const GET_AUTH_PROFILES = "GET_AUTH_PROFILES";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";

//auth
export function authLogin(user, callback) {
  return {
    type: AUTH_LOGIN,
    payload : {user, callback},
  };
}

export function authRegister(user, callback) {
  return {
    type: AUTH_REGISTER,
    payload : {user, callback},
  };
}

export function authAcceptInvite(user, callback) {
  return {
    type: AUTH_ACCEPT_INVITE,
    payload : {user, callback},
  };
}

//check auth
export function authCheck() {
    return {
      type: AUTH_CHECK,
    };
}

//auth logout
export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  };
}

//forgot password
export function authForgotPassword(data, callback) {
  return {
    type: FORGOT_PASSWORD,
    payload : {data, callback},
  };
}

//reset form password
export function resetPasswordForm(payload) {
  return {
    type: RESET_PASSWORD_FORM,
    payload
  };
}

//reset password
export function resetPassword(payload) {
  return {
    type: RESET_PASSWORD,
    payload
  };
}


//account activation
export function activateAccount(payload) {
  return {
    type: ACCOUNT_ACTIVATION,
    payload
  };
}

//change password
export function updatePassword(data, callback) {
  return {
    type: UPDATE_PASSWORD,
    payload : {data, callback},
  };
}

// get practitioner details associated with client account
export function practitionerDetails(data, callback) {
  return {
    type: GET_PRACTITIONER_DETAILS,
    payload : {data, callback},
  };
}

// get practitioner account settings associated with client account
export function practitionerSettings(data, callback) {
  return {
    type: GET_PRACTITIONER_SETTINGS,
    payload : {data, callback},
  };
}

export function getAuthProfiles(data, callback) {
  return {
    type: GET_AUTH_PROFILES,
    payload : {data, callback},
  };
}

export function setAuthToken(token, callback) {
  return {
    type: SET_AUTH_TOKEN,
    payload : {token, callback},
  };
}