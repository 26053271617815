import { FOOD_MOOD_DIRECTIONS_PDF } from "common/constants";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

//icn
import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";

const Instructions = ({ onClickStart }) => {
  const [isRead, setRead] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => { 
    if(isRead) {
      setError(null);
    }
  }, [isRead])

  const handleClick = () => {
    if(!isRead) {
      setError("Please confirm.")
      return;
    }

    setError(null)
    onClickStart();
  }

  return (
    <div>
      <div className="py-2" style={{ width: "100%", height: "100vh" }}>
        <object data={FOOD_MOOD_DIRECTIONS_PDF} type="application/pdf" width="100%" height="100%">
          <p><a target="_blank" href={FOOD_MOOD_DIRECTIONS_PDF}>View PDF</a></p>
        </object>
      </div>
      <div className="py-2">
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp position-relative ">
            <input
              type="checkbox"
              className="position-absolute w-100 h-100"
              name="terms_acceptance"
              onChange={(e) => {
                setRead(e.target.checked);
              }}
              checked={isRead}
              required
            />

            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
              <span className="icn d-none">
                <OTick />
              </span>
            </div>
          </div>
          <p cl assName=" m-0 ">
            I have read the directions and I'm ready to get started!
          </p>
        </div>
        {error && (
          <p className="invalid-feedback d-block">{error}</p>
        )}
        <Button
          className="my-2 d-inline-flex align-items-center justify-content-center commonBtn btn-sm"
          onClick={handleClick}
        >
            Begin Logging
        </Button>
      </div>
    </div>
  )
}

export default Instructions;