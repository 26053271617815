import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik  } from 'formik';

//imported
import validation from "./validation";
import LoadingButton from "components/shared/LoadingButton"

const Form_ = ({initialValues, submitting, updateData_, loader}) => {
    return(
        <>
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validation}
            onSubmit={updateData_}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form onSubmit={handleSubmit}  autoComplete="off" >
                <Row>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                      Email*
                    </label>
                    <input
                      type="email"
                      className={`form-control ${errors.email && 'is-invalid'}`}
                      name="email"
                      onChange={handleChange}
                      placeholder="Email"
                      onBlur={handleBlur}
                      value={values.email}
                      autoComplete="off" 
                    />
                    {(errors.email && touched.email) &&
                      <p className="invalid-feedback">{errors.email}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <LoadingButton
                      type="submit"
                      className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                      loading={loader}
                      disabled={submitting}
                    >
                      {isSubmitting ? 'Loading...' : 'Submit'}
                    </LoadingButton>
                  </Col>
                </Row>
              </Form>
            )}
        </Formik>
        </>
    )
}

export default Form_;