//action types
export const GET_PACKAGE_DETAILS = "GET_PACKAGE_TYPE";
export const GET_ACTIVE_PACKAGE = "GET_ACTIVE_PACKAGE";
export const GET_CLIENT_PACKAGE_INVITATIONS = "GET_CLIENT_PACKAGE_INVITATIONS";

export function getPackageDetails(data, callback) {
  return {
    type: GET_PACKAGE_DETAILS,
    payload: {data, callback},
  }
}

export function getActivePackageDetails(data, callback) {
  return {
    type: GET_ACTIVE_PACKAGE,
    payload: {data, callback},
  }
}

export function getClientPackages(data, callback) {
  return {
    type: GET_CLIENT_PACKAGE_INVITATIONS,
    payload: {data, callback},
  }
}