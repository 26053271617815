import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const Height = ({
  question,
  className,
  onChange,
  handleBlur,
  setFieldValue,
  value,
  errors,
}) => {
  const [fields, setFields] = useState({
    unit: "ft_in",
    value: ""
  })

  useEffect(() => {
    try {
      let fieldValue = value;
      if(fieldValue && typeof fieldValue !== "object") {
        fieldValue = JSON.parse(value);
      }

      if(fieldValue && typeof fieldValue == "object") {
        setFields({
          unit: fieldValue.unit,
          value: fieldValue.value,
        });
      }
    } catch {
      console.log("Error parsing values");
    }
  }, [value]);

  const handleChangeInput = (e) => {
    let fieldValue = {
      unit: fields.unit,
      value: e.target.value
    }
    setFieldValue(`question-${question.id}`, fieldValue)
  }

  const onChangeUnit = (unit) => {
    setFields({
      unit,
      value: "",
    })
  }

  return (
    <div key={`question-${question.id}`}>
      <div className="d-flex justify-content-start gap-10">
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp radio position-relative ">
            <input
              type="radio"
              className={`position-absolute w-100 h-100 ${className}`}
              value={"ft_in"}
              onChange={() => {
                onChangeUnit("ft_in");
              }}
              checked={fields.unit == "ft_in"}
            />
            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">
                {/* <OTick /> */}
              </span>
            </div>
          </div>
          <p className="m-0">Feet & Inches</p>
        </div>
        <div className="py-2 d-flex align-items-start gap-10">
          <div className="cstmInputCheckWrp radio position-relative ">
            <input
              type="radio"
              className={`position-absolute w-100 h-100 ${className}`}
              value={"cm"}
              onChange={() => {
                onChangeUnit("cm");
              }}
              checked={fields.unit == "cm"}
            />
            <div className="cstmInputCheck h-100 w-100 rounded-circle d-flex align-items-center justify-content-center">
              <span className="icn d-none rounded-circle">
                {/* <OTick /> */}
              </span>
            </div>
          </div>
          <p className="m-0">Centimeters</p>
        </div>
      </div>
      <div className="py-2 w-50">
        {fields.unit == "ft_in" &&
          <Form.Select
            className={className}
            name={`question-${question.id}`}
            onBlur={handleBlur}
            onChange={handleChangeInput}
            value={fields.value}
          >
            <option value="">Select Height</option>
            <option value="300">3' 0"</option>
            <option value="301">3' 1"</option>
            <option value="302">3' 2"</option>
            <option value="303">3' 3"</option>
            <option value="304">3' 4"</option>
            <option value="305">3' 5"</option>
            <option value="306">3' 6"</option>
            <option value="307">3' 7"</option>
            <option value="308">3' 8"</option>
            <option value="309">3' 9"</option>
            <option value="310">3' 10"</option>
            <option value="311">3' 11"</option>
            <option value="400">4' 0"</option>
            <option value="401">4' 1"</option>
            <option value="402">4' 2"</option>
            <option value="403">4' 3"</option>
            <option value="404">4' 4"</option>
            <option value="405">4' 5"</option>
            <option value="406">4' 6"</option>
            <option value="407">4' 7"</option>
            <option value="408">4' 8"</option>
            <option value="409">4' 9"</option>
            <option value="410">4' 10"</option>
            <option value="411">4' 11"</option>
            <option value="500">5' 0"</option>
            <option value="501">5' 1"</option>
            <option value="502">5' 2"</option>
            <option value="503">5' 3"</option>
            <option value="504">5' 4"</option>
            <option value="505">5' 5"</option>
            <option value="506">5' 6"</option>
            <option value="507">5' 7"</option>
            <option value="508">5' 8"</option>
            <option value="509">5' 9"</option>
            <option value="510">5' 10"</option>
            <option value="511">5' 11"</option>
            <option value="600">6' 0"</option>
            <option value="601">6' 1"</option>
            <option value="602">6' 2"</option>
            <option value="603">6' 3"</option>
            <option value="604">6' 4"</option>
            <option value="605">6' 5"</option>
            <option value="606">6' 6"</option>
            <option value="607">6' 7"</option>
            <option value="608">6' 8"</option>
            <option value="609">6' 9"</option>
            <option value="610">6' 10"</option>
            <option value="611">6' 11"</option>
            <option value="700">7' 0"</option>
            <option value="701">7' 1"</option>
            <option value="702">7' 2"</option>
            <option value="703">7' 3"</option>
            <option value="704">7' 4"</option>
            <option value="705">7' 5"</option>
            <option value="706">7' 6"</option>
            <option value="707">7' 7"</option>
            <option value="708">7' 8"</option>
            <option value="709">7' 9"</option>
            <option value="710">7' 10"</option>
            <option value="711">7' 11"</option>
          </Form.Select>
        }

        {fields.unit == "cm" &&
          <div class="input-group">
            <input
              type="number"
              name={`question-${question.id}`}
              className={className}
              onChange={handleChangeInput}
              value={fields.value}
            />
            <div class="input-group-append">
              <span class="input-group-text" style={{height: "50px"}}>cm</span>
            </div>
          </div>
        }
        {errors[`question-${question.id}`] &&
          <p className="invalid-feedback">{errors[`question-${question.id}`].value}</p>
        }
      </div>
    </div>
  );
};

export default Height;