import React, {useEffect} from "react";
import {  Col, Container, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

import { viewContentPageData } from 'store/services/contentPageService';
import * as actions from 'store/actions';

const TermsCondition = ({dispatch, pageDetail}) => {
    //get data
    async function getData() {
      try{
        let slug = {
            "slug" : 'privacy_policy'
        } 
        dispatch(actions.persist_store({ loader:true}));
        await dispatch(viewContentPageData(slug));
        dispatch(actions.persist_store({ loader:false}));
      }catch(err) {
        dispatch(actions.persist_store({ loader:false}));
        console.log(err);
    }
  }
  useEffect(() => {
      getData();
  }, []);
  return (
    <>
      <section className="Subscription position-relative">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="commonContent">
                <div className="py-2">
                  <h3 className="m-0 fw-bold py-1">
                    {pageDetail.title}
                  </h3>
                </div>
                <div className="py-2">
                  <p dangerouslySetInnerHTML={{ __html: pageDetail?.description }} />
              
                </div>
              </div>
              <Link to="/my-account">Back to account page</Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
const mapStateToPros = (state) => {
  return{
    pageDetail: state.ContentPage.page,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TermsCondition);
