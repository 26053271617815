import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// icn
import { ReactComponent as EditIcn } from "../../../../../../Assets/Images/icn/pencil.svg";
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";

const AccountSetting = () => {
  return (
    <>
      <div className="top py-2">
        <h4 className="m-0 py-1 fw-bold">Account Setting</h4>
      </div>
      <div className="TabBody py-3">
        <div className="py-2">
          <div className="commonCardBox p-3">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">
                  Display Name:{" "}
                  <span className="fw-normal">NTA Academics Team</span>
                </h6>
              </div>
              <div className="right">
                <Button className="d-flex align-items-center fw-bold text-dark justify-content-center bg-white border py-2 px-3">
                  <span className="icn me-2">
                    <EditIcn />
                  </span>
                  Edit
                </Button>
              </div>
            </div>
            <div className="CardBody py-2">
              <p className="m-0">
                Enabling the 'Direct Link Clients' option allows you to send
                your clients direct links that will take them directly to their
                To-Do Items. They will not create a Nutri-Q account and they
                will not have access to the site other than the To-Do items that
                you send to them. Please use this option if your clients won't
                be logging their foods and you'd prefer to have them go directly
                to their To-Do items without having access to the rest of the
                site. This is also a great option if you are working with
                multiple family members that are all sharing a single email
                address. Otherwise they will need to share a single site login.
              </p>
            </div>
          </div>
        </div>
        <div className="py-2">
          <div className="commonCardBox p-3">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">
                  Direct Link Clients:{" "}
                  <span className="fw-normal"> (Disabled)</span>
                </h6>
              </div>
              <div className="right">
                <Button className="d-flex align-items-center fw-bold text-dark justify-content-center bg-white border py-2 px-3">
                  <span className="icn me-2">
                    <EditIcn />
                  </span>
                  Edit
                </Button>
              </div>
            </div>
            <div className="CardBody py-2">
              <p className="m-0">
                Enabling the 'Direct Link Clients' option allows you to send
                your clients direct links that will take them directly to their
                To-Do Items. They will not create a Nutri-Q account and they
                will not have access to the site other than the To-Do items that
                you send to them. Please use this option if your clients won't
                be logging their foods and you'd prefer to have them go directly
                to their To-Do items without having access to the rest of the
                site. This is also a great option if you are working with
                multiple family members that are all sharing a single email
                address. Otherwise they will need to share a single site login.
              </p>
            </div>
          </div>
        </div>
        <div className="py-2">
          <div className="commonCardBox p-3">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">
                  Client Anytime Logging:{" "}
                  <span className="fw-normal"> (Disabled)</span>
                </h6>
              </div>
              <div className="right">
                <Button className="d-flex align-items-center fw-bold text-dark justify-content-center bg-white border py-2 px-3">
                  <span className="icn me-2">
                    <EditIcn />
                  </span>
                  Edit
                </Button>
              </div>
            </div>
            <div className="CardBody py-2">
              <p className="m-0">
                There are two food log options. Anytime Logging allows your
                Active Clients to log their foods and feelings at any time and
                it also lets you assign the "Food & Feeling Log" to them. (This
                is the logging that has been in place since this site launched.)
                If you disable Anytime Logging then you can assign the "Food &
                Mood Journal" to your client. This new food log corresponds to
                the one currently used by the NTA.
              </p>
            </div>
          </div>
        </div>
        <div className="py-2">
          <div className="commonCardBox p-3">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">
                  Show NAQ Symptom Burden Graphs to Clients:{" "}
                  <span className="fw-normal"> (Disabled)</span>
                </h6>
              </div>
              <div className="right">
                <Button className="d-flex align-items-center fw-bold text-dark justify-content-center bg-white border py-2 px-3">
                  <span className="icn me-2">
                    <EditIcn />
                  </span>
                  Edit
                </Button>
              </div>
            </div>
            <div className="CardBody py-2">
              <p className="m-0">
                When enabled new Active Clients will automatically be able to
                see their Symptom Burden Graphs after they fill out a new NAQ.
                This setting will be the default for NEW Active Clients but this
                can also be set and changed at the client level.
              </p>
            </div>
          </div>
        </div>
        <div className="py-2">
          <div className="commonCardBox p-3">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">
                  Multi-Report Download:{" "}
                  <span className="fw-normal"> (Enabled)</span>
                </h6>
              </div>
              <div className="right">
                <Button className="d-flex align-items-center fw-bold text-dark justify-content-center bg-white border py-2 px-3">
                  <span className="icn me-2">
                    <EditIcn />
                  </span>
                  Edit
                </Button>
              </div>
            </div>
            <div className="CardBody py-2">
              <p className="m-0">
                When enabled new Active Clients will automatically be able to
                see their Symptom Burden Graphs after they fill out a new NAQ.
                This setting will be the default for NEW Active Clients but this
                can also be set and changed at the client level.
              </p>
            </div>
          </div>
        </div>
        <div className="py-2">
          <div className="commonCardBox p-3">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left">
                <h6 className="m-0 fw-bold">Default Forms</h6>
              </div>
              <div className="right">
                <Button className="d-flex align-items-center fw-bold text-dark justify-content-center bg-white border py-2 px-3">
                  <span className="icn me-2">
                    <EditIcn />
                  </span>
                  Edit
                </Button>
              </div>
            </div>
            <div className="CardBody py-2">
              <p className="m-0">
                The following checked forms are shown when you select a new
                To-Do item for an existing or new client. If you no longer wish
                to see a particular form listed when choosing a new To-Do item
                then you can uncheck the box next to the form name.
              </p>
              <Row>
                <Col lg="6">
                  <ul className="list-unstyled p-0 mb-0">
                    <li className="py-1">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            name="c-ToDo"
                            className="position-absolute w-100 h-100"
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">
                          Initial Interview Questionnaire v1
                        </p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            name="c-ToDo"
                            className="position-absolute w-100 h-100"
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">
                          Initial Interview Questionnaire v1
                        </p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            name="c-ToDo"
                            className="position-absolute w-100 h-100"
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">
                          Initial Interview Questionnaire v1
                        </p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            name="c-ToDo"
                            className="position-absolute w-100 h-100"
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">
                          Initial Interview Questionnaire v1
                        </p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            name="c-ToDo"
                            className="position-absolute w-100 h-100"
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">
                          Initial Interview Questionnaire v1
                        </p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            name="c-ToDo"
                            className="position-absolute w-100 h-100"
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">
                          Initial Interview Questionnaire v1
                        </p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            name="c-ToDo"
                            className="position-absolute w-100 h-100"
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">
                          Initial Interview Questionnaire v1
                        </p>
                      </div>
                    </li>
                    <li className="py-1">
                      <div className="py-2 d-flex align-items-start gap-10">
                        <div className="cstmInputCheckWrp position-relative ">
                          <input
                            type="checkbox"
                            name="c-ToDo"
                            className="position-absolute w-100 h-100"
                          />
                          <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                            <span className="icn d-none">
                              <OTick />
                            </span>
                          </div>
                        </div>
                        <p className=" m-0 ">
                          Initial Interview Questionnaire v1
                        </p>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSetting;
