// import React, { useState } from 'react';
// import TimePicker from 'react-time-picker-input';

// function TimePickerComp({ ...props }) {
//   return (
//     <div>
//       <TimePicker
//         hour12Format
//         eachInputDropdown
//         manuallyDisplayDropdown
//         {...props}
//       />
//     </div>
//   );

// }

// export default TimePickerComp;

import React, { useEffect, useState } from 'react';

const TimePicker = ({ value = "00:00", onChange }) => {
  const [time, setTime] = useState(value);
  
  useEffect(() => {
    setTime(value);
  }, [value])

  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    setTime(newTime);
    if (onChange) {
      onChange(newTime);
    }
  };

  return (
    <input
      className="nta-time-picker"
      aria-label="Time"
      type="time"
      value={time}
      onChange={handleTimeChange}
    />
  );
};

export default TimePicker;
