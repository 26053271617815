import { decode } from "html-entities";
import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";

const CheckBoxes = (props) => {
  let checkOptions = null;
  try {
    checkOptions = JSON.parse(props.question.other_column_data);
  } catch (error) {
    console.log("Error while parsing radio options", props.question.question);
  }

  return (
    <ul className="list-unstyled ps-0 mb-0 py-2">
      {checkOptions &&
        checkOptions.length > 0 &&
        checkOptions.map((option, i) => {
          let optionValue = "";
          if (
            option !== null &&
            typeof option === "object" &&
            !Array.isArray(option) &&
            typeof option.answer !== "undefined"
          ) {
            optionValue = option.answer;
          } else {
            optionValue = option;
          }

          return (
            <li key={`${props.question.id}-${i}`}>
              <div className="py-2 d-flex align-items-start gap-10">
                <div className="cstmInputCheckWrp position-relative">
                  <input
                    type="checkbox"
                    className={`position-absolute w-100 h-100 ${props.className}`}
                    name={`question-${props.question.id}`}
                    onBlur={props.handleBlur}
                    value={optionValue}
                    onChange={props.onChange}
                    checked={props.value?.includes(optionValue)}
                  />
                  <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                    <span className="icn d-none">
                      <OTick />
                    </span>
                  </div>
                </div>
                <p className="m-0 ">{decode(optionValue)}</p>
              </div>
            </li>
          );
        })}
    </ul>
  );
};

export default CheckBoxes