import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";


const EditMealPopup = ({ showPopup, closePopup, currentMeal, editMeal, deleteMeal }) => {

  return (
    <>
      <Modal
        show={showPopup}
        onHide={closePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Edit/Delete Meal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <Row>
            <Col lg="6" className="mx-auto">
              <div className="d-flex justify-content-end gap-10 flex-column">
                <Button
                  className="d-flex align-items-center justify-content-center commonBtn"
                  onClick={editMeal}
                >
                  Edit Meal
                </Button>
                <Button
                  className="d-flex align-items-center justify-content-center btn-danger"
                  onClick={() => {
                    deleteMeal(currentMeal)
                  }}
                >
                  Delete Meal
                </Button>
                <Button
                  onClick={closePopup}
                  className="d-flex align-items-center justify-content-center commonBtn"
                >
                  Close
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditMealPopup;
