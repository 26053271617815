import { APPOINTMENT_BOOKING_LIST, BOOK_APPOINTMENT, GET_APPOINTMENT_TYPE_LIST, RESCHEDULE_APPOINTMENT } from 'store/actions/appointment';

const initialState = {
  appointmentTypes: [],
  appointmentBookings: [],
  currentBooking: null,
};

const getAppointmentTypeList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }

  const stateObj = {
    ...state,
    appointmentTypes: data.body,
    loading: false,
  };
  return stateObj;
};

const bookAppointment = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }

  const stateObj = {
    ...state,
    currentBooking: data.body,
    loading: false,
  };
  return stateObj;
};

const getBookingList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(payload);
  }

  const stateObj = {
    ...state,
    appointmentBookings: data.body,
  };
  return stateObj;
}

const rescheduleAppointment = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  const stateObj = {
    ...state,
  };
  return stateObj;
};

const Appointment = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case GET_APPOINTMENT_TYPE_LIST:
      return getAppointmentTypeList(state, payload);
    case BOOK_APPOINTMENT:
      return bookAppointment(state, payload);
    case APPOINTMENT_BOOKING_LIST:
      return getBookingList(state, payload);
    case RESCHEDULE_APPOINTMENT:
      return rescheduleAppointment(state, payload);
    default:
    return state;
  };
}
export default Appointment;