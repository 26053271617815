import React, { useState, useEffect, useCallback} from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

// icn
import { ReactComponent as EditIcn } from "../../../../../../Assets/Images/icn/pencil.svg";
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";
import EnableDisablePop from "components/Modals/EnableDisablePop";
//imported
import { addUpdateAccountSetting_ } from 'store/services/accountSettingService';

const NotificationSetting = ({dispatch, accountSettingList, currentTabValue, setCurrentTabValue, sending, setSending, setSuccessResponse}) => {
  const [onOffPop, setOnOffPop] = useState();
  const [popHeading, setPopHeading] = useState("");

  const [fields, setfields] = useState({
    data_set : currentTabValue ? currentTabValue.data_set : ""
  })

  const handleOnOff = (heading, value) => {
    setOnOffPop(!onOffPop);
    setPopHeading(heading);
    setCurrentTabValue(value)
  };

  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  }

  //handle changes
  const handleChange = useCallback(
    name => evt => {
      setfields(prevState => ({
        ...prevState,
        [name]: evt.target.value,
      }))
    },
    []
  )
  useEffect(() => {
    if (currentTabValue) {
      setfields(prevState => ({
        ...prevState,
        ...currentTabValue,
      }))
    }
  }, [currentTabValue, accountSettingList])

  //submit setting data
  const handleSubmit = async (evt) => {
    try{
      evt.preventDefault();
        let final_obj = {
          data_set : fields.data_set
        }
        let fieldsId = fields.id
        // return
        final_obj = JSON.stringify(final_obj)
        setSending(true)
        setSuccessResponse(false)
        await dispatch(addUpdateAccountSetting_(fieldsId, final_obj));
        setSending(false)
        setSuccessResponse(true)
        setOnOffPop(false)
        setCurrentTabValue(null)
    } catch (err) {
        console.log(err);
        setSending(false)
        setSuccessResponse(false)
    }
  }

  return (
    <>
      <EnableDisablePop
        heading={popHeading}
        onOffPop={onOffPop}
        setOnOffPop={setOnOffPop}
        currentTabValue={currentTabValue}
        setCurrentTabValue={setCurrentTabValue}
        handleSubmit={handleSubmit}
        handleChange={handleChange('data_set')}
        sending={sending}
      />
      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">Notification Setting</h3>
      </div>
      <div className="TabBody py-3">
      {accountSettingList?.length > 0 && accountSettingList?.map((settingList, idx) => {
          return(
            <div key={idx}>
              {settingList.data_key === 'email_notifications' &&
                <div className="py-2">
                  <div className="commonCardBox p-3">
                    <div className="top d-flex align-items-center justify-content-between gap-10">
                      <div className="left">
                        <h6 className="m-0 fw-bold">
                          Email Notifications:{" "}
                          <span className="fw-normal">
                            ({ settingList.data_set == "enable" ? "Enabled" : "Disabled" })
                          </span>
                        </h6>
                      </div>
                      <div className="right">
                        <Button
                          onClick={() => handleOnOff("Email Notification", settingList)}
                          className="d-flex align-items-center fw-bold commonBtn btnSm justify-content-center py-2 px-3"
                        >
                          <span className="icn me-2">
                            <EditIcn />
                          </span>
                          Edit
                        </Button>
                      </div>
                    </div>
                    <div className="CardBody py-2">
                      <p className="m-0">
                        Receive all site notifications via email. If disabled, you will
                        not receive any notifications from the site.
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>
          )
        })}
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return{
  }
};

function mapDispatchToProps(dispatch) {
return { dispatch };
}

export default connect(
mapStateToPros,
mapDispatchToProps
)(NotificationSetting);
