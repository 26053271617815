//action types
export const GET_ACTION_PLAN_LIST = 'GET_ACTION_PLAN_LIST';
// export const VIEW_ACTION_PLAN = 'VIEW_ACTION_PLAN';

//get 
export function getClientActionPlansList(data, callback) {
  return {
    type: GET_ACTION_PLAN_LIST,
    payload : {data, callback},
  };
}
