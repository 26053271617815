import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import AuthLayout from "../../../layout/AuthLayout/AuthLayout";
import { resetPasswordForm, validateResetPasswordToken } from 'store/services/authService';
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import * as Path from 'Routes/paths';
import Form from "./Form";
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "common/constantMessage";

const ResetPassword = ({ dispatch, submitting }) => {
  const [isReady, setIsReady] = useState(false);
  let { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async function() {
      if(!token) {
        return;
      }
      try {
        const response = await validateResetPasswordToken(token);
        if( response.success == true ) {
          setIsReady(true);
        }
      } catch(error) {
        console.log("error", error);
        if(error.data) {
          ERROR_MESSAGE(error.data?.message);
        }
        setIsReady(false);
        navigate(Path.login);
      }
    })()
  }, [])
  const initialValues = {
    password: "",
    confirm_password: ""
  };

  const submitData = async (values, {setSubmitting}) => {
    try{
      setSubmitting(true);
      const callback = (response) => {
        const { body } = response;
        console.log(response)
        SUCCESS_MESSAGE(response.message);
        navigate("/");
      }

      let data = {
        password: values.password,
        confirm_password: values.confirm_password,
      }
      
      const response = await resetPasswordForm(data, token);
      console.log("response", response)
      if(response.success == true) {
        SUCCESS_MESSAGE(response.message);
        navigate(Path.login);
      }
      setSubmitting(false);
    } catch (err) {
        console.log(err,  "==============================");
        setSubmitting(false);
    }
  }

  return (
    <>
      {/* <AuthLayout> */}
      <div className="FormInner py-5 bg-white px-sm-5 px-4">
        <div className="top mb-3">
          <h2 className="m-0 fw-bold">Reset Password</h2>
        </div>
        <div className="FormBody">
          <Form
            initialValues={initialValues}
            submitting={submitting}
            updateData_={submitData}
            isReady={isReady}
          />
        </div>
      </div>
      {/* </AuthLayout> */}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(ResetPassword);