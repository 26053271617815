//action types
export const GET_CONTENT_PAGE = 'GET_CONTENT_PAGE';
export const ADD_CONTENT_PAGE = 'ADD_CONTENT_PAGE';
export const VIEW_CONTENT_PAGE  = 'VIEW_CONTENT_PAGE';
export const UPDATE_CONTENT_PAGE = 'UPDATE_CONTENT_PAGE';
export const DELETE_CONTENT_PAGE = 'DELETE_CONTENT_PAGE';

//get 
export function getContentPage(data, callback) {
  return {
    type: GET_CONTENT_PAGE,
    payload : {data, callback},
  };
}
//add 
export function addContentPage(data, callback) {
  return {
    type: ADD_CONTENT_PAGE,
    payload : {data, callback},
  };
}

//view 
export function viewContentPage(data, callback) {
  return {
    type: VIEW_CONTENT_PAGE,
    payload : {data, callback},
  };
}
//update 
export function updateContentPage(data, callback) {
    return {
      type: UPDATE_CONTENT_PAGE,
      payload : {data, callback},
    };
}
  
//delete 
export function deleteContentPage(payload) {
  return {
    type: DELETE_CONTENT_PAGE,
    payload
  };
}
