import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import parse from 'html-react-parser';
import { string } from 'yup';
import { fieldInstructions } from "../FMJournalStructure";
import { FMJ_ENTRY_MAX_CHAR } from "common/constants";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useFieldValidation from "hooks/useFieldValidation";

const schema = string()
  .optional()
  .max(
    FMJ_ENTRY_MAX_CHAR,
    `Entry must be within ${FMJ_ENTRY_MAX_CHAR} characters`
  );

const EditFieldPopup = ({ showPopup, setShowPopup, fieldData, handleSave, handleDelete }) => {
  const [fieldValue, setFieldValue] = useState("");
  
  const fieldError = useFieldValidation(fieldValue, schema);

  useEffect(() => {
    if(fieldData) {
      setFieldValue(fieldData?.value);
    }
  }, [fieldData]);

  const hidePopup = () => setShowPopup(!showPopup);

  const saveFieldValue = () => {
    if(fieldError) {
      return;
    }
    handleSave(fieldData.key, fieldValue, fieldData.index);
    setFieldValue("");
  }

  const deleteFieldValue = () => {
    handleDelete(fieldData.key, fieldData.index);
    setFieldValue("");
  }

  return (
    <>
      <Modal
        show={showPopup}
        onHide={hidePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="journalPop FormPop"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-sbold">Food & Mood Journal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <div className="py-2">
            <p className="py-2 m-0">
              {fieldData && fieldInstructions && parse(fieldInstructions[fieldData?.key])}
            </p>
            <textarea
              className={`form-control ${fieldError && 'is-invalid'}`}
              rows={4}
              value={fieldValue}
              onChange={(e) => {
                setFieldValue(e.target.value);
              }}
            ></textarea>
            <div className="d-flex justify-content-between">
              <p className="invalid-feedback d-block w-auto">{fieldError || ""}</p>
              <p>
                <span className="me-1">Characters used: {fieldValue?.length} / {FMJ_ENTRY_MAX_CHAR}</span>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      id="fmj-limit-tooltip"
                    >
                      If you need to add more content than the specified limit, please add another time entry.
                    </Tooltip>
                  }
                >
                  <a href="#"><i className="fa fa-info-circle"></i></a>
                </OverlayTrigger>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="FormbtnWrpper">
          <Button
            onClick={hidePopup}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Cancel
          </Button>
          <Button
            onClick={deleteFieldValue}
            className="d-flex align-items-center justify-content-center btn-danger"
            disabled={!fieldValue}
          >
            Delete
          </Button>
          <Button
            onClick={saveFieldValue}
            className="d-flex align-items-center justify-content-center commonBtn"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditFieldPopup;
