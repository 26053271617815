import { useState, useEffect } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { createPaymentIntent } from 'store/services/packageService';
import CheckoutForm from './CheckoutForm';
import { createAppointmentPaymentIntent } from 'store/services/appointmentService';
import { createPublicApptPaymentIntent } from 'store/services/publicAppointmentService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ appointment, userId, returnUrl, callback }) => {
  const [isLoading, setLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [options, setOptions] = useState({
    clientSecret: ""
  })

  useEffect(() => {
    createPayment();
  }, [])

  const handlePaymentFormLoad = () => {
    setLoading(false);
  }

  const createPayment = async () => {
    try {
      let response = null;
      if(userId) {
        let data = {
          appointmentId: appointment.id,
          values: {
            userId: userId,
          }
        }
        response = await createAppointmentPaymentIntent(data);
      } else {
        response = await createPublicApptPaymentIntent(appointment.id);
      }

      if(response && response.success === true && response.body.clientSecret) {
        setOptions((options) => {
          return {...options, clientSecret: response.body.clientSecret }
        })
        setIsReady(true);
      }
    } catch(err) {
      console.log("Error creating payment intent", err);
    }
  }


  return (
    <>
      { !isReady ?
        <p>Loading...</p>
        :
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            onLoad={handlePaymentFormLoad}
            appointment={appointment}
            returnUrl={returnUrl}
            callback={callback}
          />
        </Elements>
      }
    </>
  );
};

export default PaymentForm