import React, { useEffect, useRef, useState } from "react";
import {
  Accordion, Dropdown,
} from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import AllQuestionAnswers from "./Component/AllQuestionAnswers";
import { Link, useParams } from "react-router-dom";

import { ReactComponent as DownloadIcon } from "Assets/Images/icn/download.svg";
import * as actions from "store/actions";
import NAQBodySymptomsGraph from "./Component/NAQBodySymptomsGraph/Index";
import NAQQuestionAnswersPDF from "components/shared/NAQv1Reports/NAQQuestionAnswersPDF";
import DownloadSBLineGraph from "components/shared/NAQv1Reports/SymptomBurdenGraph/SBLineGraphPDF";
import DownloadSBBarGraph from "components/shared/NAQv1Reports/SymptomBurdenGraph/SBBarGraphPDF";

const foundationGroups = [
  {
    "Digestion" : [
      "upperGI",
      "liverGallbladder",
      "smallIntestine",
      "largeIntestine",
    ]
  },
  "Blood Sugar Regulation",
  "Stress",
  "Sleep",
  {
    "Nutrient Dense Diet": [
      "mineralNeeds",
      "vitaminNeed",
      "essentialFattyAcids"
    ]
  }
]

const NutritionalAssessmentV1 = ({ dispatch, todoId, formId, formName, submittedOn, userProfile, showNaqSBGraph }) => {

  const showLoader = () => {
    dispatch(actions.persist_store({ loader: true }));
  }

  const hideLoader = () => {
    dispatch(actions.persist_store({ loader: false }));
  }

  return (
    <Accordion.Item
      eventKey={todoId}
      className=" my-2"
      style={{
        border: "1px solid #2B3674",
      }}
    >
      <Accordion.Header
        className=""
        style={{
          background: "#F9FAFF",
          // border: "1px solid #2B3674",
          color: "#2B3674",
        }}
      >
        <span className="toggleBtn position-absolute"></span>

        <div className="d-flex align-items-center gap-10">
          <span className="icn me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                fill="currentColor"
              />
              <path
                d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                fill="currentColor"
              />
              <path
                d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {formName}
        </div>
        <div className="right d-flex align-items-center gap-10">
          <p className="m-0 text-dark text-lowercase fw-sbold">
            {submittedOn && `Submitted on ${moment(submittedOn).format("MMM DD, YYYY")}`}
          </p>
        </div>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #2B3674",
        }}
      >
        <div className="AccordionInner">
          <div className="py-2">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left d-flex align-items-center gap-10">
                
              </div>
              <div className="right">
                  <Dropdown className="download-pdf-dropdown">
                    <Dropdown.Toggle
                      className=" commonBtn btnSm text-uppercase fw-sbold border pe-5 position-relative"
                      id="dropdown-basic"
                    >
                      <span className="icn me-2">
                        <DownloadIcon />
                      </span>
                      Download Report
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {showNaqSBGraph === "yes" && (
                        <>
                          <Dropdown.Item as={"div"}>
                            <DownloadSBLineGraph
                              date={submittedOn}
                              client={userProfile}
                              todoId={todoId}
                              showLoader={showLoader}
                              hideLoader={hideLoader}
                            />
                          </Dropdown.Item>
                          <Dropdown.Item as={"div"}>
                          <DownloadSBBarGraph
                            date={submittedOn}
                            client={userProfile}
                            todoId={todoId}
                            showLoader={showLoader}
                            hideLoader={hideLoader}
                          />
                          </Dropdown.Item>
                        </>
                      )}
                      <Dropdown.Item as={"div"}>
                        <NAQQuestionAnswersPDF
                          date={submittedOn}
                          client={userProfile}
                          todoId={todoId}
                          formName={formName}
                          label="Download Report"
                          showLoader={showLoader}
                          hideLoader={hideLoader}
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                
              </div>
            </div>
          </div>
          <div className="inner">
            <Accordion>
              {showNaqSBGraph === "yes" && (
                <NAQBodySymptomsGraph
                  eventKey="0"
                  todoId={todoId}
                  formId={formId}
                />
              )}
              <AllQuestionAnswers
                eventKey="1"
                todoId={todoId}
                formId={formId}
              />
            </Accordion>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const mapStateToPros = (state) => {
  const user = state.Profile?.user;
  const showNaqSBGraph = user?.client_show_naq_burden_graph;
  return {
    user,
    showNaqSBGraph
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(NutritionalAssessmentV1);