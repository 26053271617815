import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

// img
import GoogleCalIcn from "../../../../../../Assets/Images/icn/googleCal.svg";
import FScriptIcn from "../../../../../../Assets/Images/icn/fullScript.svg";
import PBetterIcn from "../../../../../../Assets/Images/icn/PracticeBetter.svg";

// icn
import { ReactComponent as StripeIcn } from "../../../../../../Assets/Images/icn/stripe.svg";
import { ReactComponent as LinkIcn } from "../../../../../../Assets/Images/icn/Link.svg";

const ThirdPartyIntegeration = () => {
  return (
    <>
      <div className="top py-2">
        <h4 className="m-0 py-1 fw-bold">3rd Party Integrations</h4>
      </div>
      <div className="TabBody py-3">
        <div className="py-2">
          <ul className="list-unstyled ps-0 mb-0 pt-2">
            <li className="d-flex align-items-start gap-10 py-3 position-relative">
              <div className="imgWrp">
                <StripeIcn />
              </div>
              <div className="content">
                <h6 className="fw-sbold m-0 pb-1">Stripe</h6>
                <p className="m-0">
                  Link your Stripe account to accept payments for your products
                  and services. View the{" "}
                  <Link className="text-dark underline">
                    Stripe Integration Guide
                  </Link>{" "}
                  for step by step instructions.
                </p>
                <Button
                  variant="transparent"
                  className="d-flex fw-sbold text-dark border my-2 align-items-center justify-content-center btnSm"
                >
                  <span className="icn me-2">
                    <LinkIcn />
                  </span>{" "}
                  Link you stripe account
                </Button>
                <p className="m-0">
                  Not familiar with Stripe?{" "}
                  <Link className="text-dark underline">
                    Learn more about Stripe here.
                  </Link>{" "}
                </p>
              </div>
            </li>
            <li className="d-flex align-items-start gap-10 py-3 position-relative">
              <div className="imgWrp GOOGLE">
                {/* <GoogleCalIcn /> */}
                <img src={GoogleCalIcn} alt="" className="img-fluid" />
              </div>
              <div className="content">
                <h6 className="fw-sbold m-0 pb-1">Google Calendar</h6>
                <p className="m-0">
                  Sync your Scheduler Appointments with your Google Calendar.
                  View the{" "}
                  <Link className="text-dark underline">
                    Google Calendar Integration Guide
                  </Link>{" "}
                  for step by step instructions.
                </p>
                <Button
                  variant="transparent"
                  className="d-flex fw-sbold text-dark border my-2 align-items-center justify-content-center btnSm"
                >
                  <span className="icn me-2">
                    <LinkIcn />
                  </span>{" "}
                  Link you stripe account
                </Button>
              </div>
            </li>
            <li className="d-flex align-items-start gap-10 py-3 position-relative">
              <div className="imgWrp GOOGLE">
                {/* <PBetterIcn /> */}
                <img src={PBetterIcn} alt="" className="img-fluid" />
              </div>
              <div className="content">
                <h6 className="fw-sbold m-0 pb-1">Practice Better</h6>
                <p className="m-0">
                  Link with your Practice Better account to send NAQs and
                  receive results from within Practice Better. View the
                  <Link className="text-dark underline">
                    Practice Better Integration Guide
                  </Link>{" "}
                  for step by step instructions.
                </p>
                <Button
                  variant="transparent"
                  className="d-flex fw-sbold text-dark border my-2 align-items-center justify-content-center btnSm"
                >
                  <span className="icn me-2">
                    <LinkIcn />
                  </span>{" "}
                  Link you stripe account
                </Button>
              </div>
            </li>
            <li className="d-flex align-items-start gap-10 py-3 position-relative">
              <Button
                className="d-flex align-items-center CstmLabel justify-content-center position-absolute"
                style={{
                  right: "10px",
                  top: "10px",
                  background: "#F1FFF1",
                  color: "#548B54",
                  borderColor: "#548B54",
                }}
              >
                {" "}
                Linked
              </Button>
              <div className="imgWrp GOOGLE">
                {/* <FScriptIcn /> */}
                <img src={FScriptIcn} alt="" className="img-fluid" />
              </div>
              <div className="content">
                <h6 className="fw-sbold m-0 pb-1">Full Script</h6>
                <p className="m-0">
                  Your Fullscript account is linked. <br />
                  View the{" "}
                  <Link to="" className="underline text-dark">
                    Fullscript Integration Guide
                  </Link>{" "}
                  to see how to:
                </p>
                <ul className="m-0">
                  <li className="py-1">Link Clients</li>
                  <li className="py-1">Create Recommendations</li>
                  <li className="py-1">Generate Recommendations from a NAQ</li>
                  <li className="py-1">View Client Orders</li>
                </ul>
                <p className="m-0 py-1">
                  <b>Linked Fullscript Practitioner:</b> Practitioner Ln
                  (fsprac@mailinator.com)
                  <Link to="" className="underline text-dark d-block">
                    Unlink your Fullscript account
                  </Link>{" "}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ThirdPartyIntegeration;
