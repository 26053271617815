import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
//imported
import { register_, acceptInvite, validateSignUpKey, validateSelfSignUpKey, acceptInviteLogin } from 'store/services/authService';
import * as Path from 'Routes/paths';
import * as actions from 'store/actions';
import Form from './Form'
import SetPasswordForm from './SetPasswordForm'
import { ERROR_MESSAGE } from "common/constantMessage";
import AcceptInviteLogin from "./AcceptInviteLogin";

const SignUp = ({ dispatch }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isReady, setIsReady] = useState(false);
  // const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [selfSignupKeyDetails, setSelfSignupKeyDetails] = useState(null);

  const [step, setStep] = useState(1); // 1 = Signup page | 2 = Set password | 3 = Accept Invite Login

  const signupKey = searchParams.get("token");
  const isSelfSignup = searchParams.get("signup") || "0";
  const selfSignupKey = searchParams.get("ssk");

  const [passwordFormInitialValues, setPasswordFormInitialValues] = useState({
    password: "",
    confirm_password: "",
    isSelfSignup
  });

  const [acceptInvitePassIntValues, setAcceptInvitePassIntValues] = useState({
    email: "",
    password: "",
  });

  const initialValues = {
    first_name: (userDetails && userDetails.first_name) || "",
    email: (userDetails && userDetails.email) || "",
    last_name: (userDetails && userDetails.last_name) || "",
    signup_key: (userDetails && userDetails.signup_key) || "",
    isSelfSignup: isSelfSignup,
    selfSignupKey: selfSignupKey,
  };


  useEffect(() => {
    if (isSelfSignup && isSelfSignup == "1") {
      getSelfSignupKeyDetails();
    } else {
      validateSignupKey();
    }
  }, [])

  const getSelfSignupKeyDetails = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));

      let response = await validateSelfSignUpKey(selfSignupKey);
      if (response?.success == true && response?.body?.pract_first_name) {
        setSelfSignupKeyDetails(response?.body);
        setIsReady(true);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.log("error", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const validateSignupKey = async () => {
    if (!signupKey) {
      setIsReady(true);
      return;
    }
    try {
      const validateKeyCallback = (data) => {
        setUserDetails(data.body);
        setIsReady(true);
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(validateSignUpKey(signupKey, validateKeyCallback));
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.log("error", error);
      if (error.data) {
        ERROR_MESSAGE(error.data?.message);
      }
      setIsReady(true);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const submitData_ = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const callback = (response) => {
        const { success, body } = response;
        if (success == true) {
          if (isSelfSignup == "1") {
            setPasswordFormInitialValues(prevState => ({
              ...prevState,
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              isSelfSignup: isSelfSignup,
              selfSignupKey: selfSignupKey,
            }))
          } else {
            localStorage.setItem("signup_key", body?.signup_key);
          }

          /**
           * If email is associated with any other account, then show accept invite login screen
           */
          if (body?.email_exist === true) {
            setAcceptInvitePassIntValues(prevState => ({
              ...prevState,
              email: values.email,
              first_name: values.first_name,
              last_name: values.last_name,
            }));
            setStep(3);
          } else {
            setStep(2);
          }
        }
      }

      await dispatch(register_(values, callback));
      setSubmitting(false);
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
    }
  }

  const setPassword = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      if (isSelfSignup == "1") {
        values.isSelfSignup == isSelfSignup
      } else {
        values.signup_key = localStorage.getItem("signup_key");
      }

      await dispatch(acceptInvite(values));
      setSubmitting(false);
      navigate(Path.login);
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
    }
  }

  const handleAcceptInviteLogin = async (values, { setSubmitting }) => {
    try {
      console.log("isSelfSignup", isSelfSignup)
      setSubmitting(true);
      let payload = null;
      if(isSelfSignup == "1") {
        payload = {
          ...values,
          selfSignupKey: selfSignupKey,
          isSelfSignup: isSelfSignup
        };
      } else {
        payload = { 
          password: values.password,
          signup_key: signupKey
        };
      }

      console.log("payload", payload);
      let response = await acceptInviteLogin(payload);
      if(response?.success == true) {
        navigate(Path.login);
      }
      setSubmitting(false);
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
    }
  }

  return (
    <>
      {/* <AuthLayout> */}
      <div className="FormInner py-5 bg-white px-sm-5 px-4">
        <div className="top mb-3">
          {/* <label className="m-0 form-label">Welcome back</label> */}
          <h2 className="m-0 fw-bold">Client Sign Up</h2>
          {isSelfSignup == "1" && selfSignupKeyDetails &&
            <p className="pt-2">Sign up to work with {selfSignupKeyDetails.pract_first_name} {selfSignupKeyDetails.pract_last_name}</p>
          }
        </div>
        <div className="FormBody">
          {step == 1 ? (
            <Form
              initialValues={initialValues}
              updateData_={submitData_}
              isReady={isReady}
              isSelfSignup={isSelfSignup}
            />
          ) : step == 2 ? (
            <SetPasswordForm
              initialValues={passwordFormInitialValues}
              updateData_={setPassword}
              isReady={isReady}
            />
          ) : (
            <>
              <p className="m-0">This email already has an account associated with it so please sign in to accept invitation.</p>
              <AcceptInviteLogin
                initialValues={acceptInvitePassIntValues}
                handleSubmit={handleAcceptInviteLogin}
                goBack={() => {
                  setStep(1);
                }}
              />
            </>
          )}
        </div>
      </div>
      {/* </AuthLayout> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);