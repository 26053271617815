//action types
export const PERSIST_STORE = 'persist/REHYDRATE';

export function persist_store(payload) {
    return {
      type: PERSIST_STORE,
      payload
    };
}





