import { cloneDeep, isArray, isObject } from "lodash";

/**
 * Function used to parse all the JSON data for submitted food mood logs
 */
export const transformFMJData = (data) => {
  try {
    const parseFMJRow = (row) => {
      let rowClone = cloneDeep(row);
      if(rowClone.additional_notes) {
        rowClone.additional_notes = row.additional_notes && JSON.parse(row.additional_notes);
      }
      if(rowClone.food_served) {
        rowClone.food_served = row.food_served && JSON.parse(row.food_served);
      }
      return rowClone;
    }

    if(!isArray(data) && isObject(data)) {

      return parseFMJRow(data);

    } else if(isArray(data)){

      return data.map(row => {
        return parseFMJRow(row);
      });

    }
  } catch(err) {
    console.log("Error transforming food mood logs data", err);
    return data;
  }
}

/**
 * Function used to format the todo response
 */

export function formatTodoResponse(todoDetails) {
  try {
    if(!todoDetails || !todoDetails?.length) {
      return;
    }
    return todoDetails.map(page => {
      page.questions = page.questions.map(question => {
        question.answer = JSON.parse(question.client_response)?.response;
        return question;
      })
      return page;
    })
  } catch(err) {
    console.log("Error formatting todo response", err);
    return todoDetails;
  }
}
