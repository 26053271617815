import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from "components/shared/LoadingButton"
import { changePassword } from "store/services/authService";
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "common/constantMessage";
import { PASSWORD_VALIDATION_MSG } from "common/strMessage";
import { passwordStrength } from 'check-password-strength'
import { PASSWORD_VALIDATE_REGEX } from "common/constants";

const UpdatePassword = ({ lastPasswordUpdatedAt }) => {

  const handleSubmit = async (values, {setSubmitting, resetForm}) => {
    try{
      setSubmitting(true);
      const callback = (response) => {
        const { body } = response;
        console.log(response)
        SUCCESS_MESSAGE(response.message);
        // navigate("/");
      }
      values = JSON.stringify(values)
      const response = await changePassword(values);
      console.log("response", response)
      if(response.success == true) {
        SUCCESS_MESSAGE(response.message);
        resetForm();
      }
      setSubmitting(false);
    } catch (err) {
        console.log(err,  "==============================");
        setSubmitting(false);
    }
  }

  return (
    <>
      <div className="py-2">
        <p className="text-bold m-0 text-dark fw-bold text-uppercase">
          Update Password
        </p>
        <p className="tex-muted m-0">
          Your password was last updated on . To
          update your password please enter in your current password and
          then your new password below.
        </p>
      </div>
      <div className="py-2">
        <Form_ onSubmit={handleSubmit} />
      </div>
    </>
  )
}

const Form_ = ({ onSubmit }) => {
  const validation = Yup.object({
    current_password: Yup.string().required("Password Required").min(8, "Password must be at least 8 characters long."),
    new_password: Yup.string()
      .required("Password Required")
      .min(8, PASSWORD_VALIDATION_MSG)
      .matches(PASSWORD_VALIDATE_REGEX, PASSWORD_VALIDATION_MSG)
      .notOneOf([Yup.ref("current_password")], "New password must be different from the current password"),
    confirm_password: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
  });
  return (
    <>
      <Formik
        initialValues={{
          current_password: "",
          new_password: "",
          confirm_password: "",
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
          <Row className="py-2">
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-sbold theme-clr m-0"
              >
                Current Password
              </label>
              <input
                type="password"
                className={`form-control ${errors.current_password && 'is-invalid'}`}
                name="current_password"
                onChange={handleChange}
                placeholder="Current Password"
                onBlur={handleBlur}
                value={values.current_password}
                autoComplete="off"
              />
              {(errors.current_password && touched.current_password) &&
                <p className="invalid-feedback">{errors.current_password}</p>
              }
            </Col>
          </Row>
          <Row className="py-2">
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-sbold theme-clr m-0"
              >
                New Password
              </label>
              <input
                type="password"
                className={`form-control ${errors.new_password && 'is-invalid'}`}
                name="new_password"
                onChange={handleChange}
                placeholder="New Password"
                onBlur={handleBlur}
                value={values.new_password}
                autoComplete="off"
              />
              {values?.password && (
                  <p className="m-0 py-1 password-strength">Password Strength: <span className={`strength strength-${passwordStrength(values.password).id}`}>{passwordStrength(values.password)?.value}</span></p>
                )}
              {(errors.new_password && touched.new_password) &&
                <p className="invalid-feedback">{errors.new_password}</p>
              }
            </Col>
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-sbold theme-clr m-0"
              >
                Re-enter New Password
              </label>
              <input
                  type="password"
                  className={`form-control ${errors.confirm_password && 'is-invalid'}`}
                  name="confirm_password"
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  onBlur={handleBlur}
                  value={values.confirm_password}
                  autoComplete="off"
                />
                {(errors.confirm_password && touched.confirm_password) &&
                  <p className="invalid-feedback">{errors.confirm_password}</p>
                }
            </Col>
            <Col lg="12" className="my-2 btnWrp">
              {/* <Button
                    onClick={() => ""}
                    className="d-flex align-items-center justify-conten-center commonBtn btnSm"
                  >
                    Submit
                  </Button> */}
              <Button
                type="submit"
                className="d-flex align-items-center justify-conten-center commonBtn btnSm"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Loading...' : 'Submit'}
              </Button>
            </Col>
          </Row>
        </Form>
        )}
      </Formik>
    </>
  )
}
export default UpdatePassword;