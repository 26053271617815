import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
//imported
import { getProfileDetails, login } from 'store/services/authService';
import * as Path from 'Routes/paths';
import * as actions from 'store/actions';
import { authLogin } from "store/actions/auth";
import { FULL_NAME } from "helpers/common";
import ChooseProfile from "components/shared/ChooseProfile/Index";

const SelectProfile = ({ dispatch, profiles }) => {
  const navigate = useNavigate();

  const redirectTo = decodeURI(localStorage.getItem("redirectTo"));

  useEffect(() => {
    if (!profiles) {
      navigate(Path.login);
    }
  }, [profiles])

  // const handleChooseProfile = async (profile) => {
  //   try {
  //     if(profile) {
  //       dispatch(authLogin(profile));
  //     }
  //   } catch (err) {
  //     console.log(err, "==============================");
  //   }
  // }

  const handleChooseProfile = async (profile) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await getProfileDetails(profile.id);
      if(response?.success === true && response?.body) {
        dispatch(authLogin(response?.body));
        if(redirectTo) {
          navigate(redirectTo);
          localStorage.removeItem("redirectTo");
        }
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error switching profile", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <>
      <div className="FormInner py-5 bg-white px-sm-5 px-4 select-profile">
        <div className="top mb-3">
          {/* <label className="m-0 form-label">Welcome back</label> */}
          <h2 className="m-0 fw-bold">Select a Profile</h2>
        </div>
        <div className="FormBody">
          <ChooseProfile
            profiles={profiles}
            handleChooseProfile={handleChooseProfile}
          />
        </div>
        <div className="mt-4">
          <p className="formText m-0 text-center text-muted">
            <Link to={Path.login} className=" form-link fw-sbold underline">
              Go Back
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profiles: state.Auth.authProfiles?.profiles,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProfile);