import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { decode } from "html-entities";
import TextArea from "./InputComponents/TextArea";
import RadioButtons from "./InputComponents/RadioButtons";
import TextInput from "./InputComponents/TextInput";
import CheckBoxes from "./InputComponents/CheckBoxes";
import NumberRangeInput from "./InputComponents/NumberRangeInput";
import Scale from "./InputComponents/Scale";
import DatePicker from "./InputComponents/DatePicker";
import NumberInput from "./InputComponents/NumberInput";
import Disclaimer from "./InputComponents/Disclaimer";
import Height from "./InputComponents/Height";
import Weight from "./InputComponents/Weight";
import Address from "./InputComponents/Address";
import { CLINICAL_PRESENTATION_FORM_ID } from "common/constants";

const ToDoQuestion = (props) => {

  useEffect(() => {
    props.onValueChange(props.question.id, props.value)
  }, [props.value])

  const questionType = props.question.question_type;

  /**
   * Hide the "Optional" or "*" text for clinical presentation form
   */
  let showFormRequiredLabel = props.formId !== CLINICAL_PRESENTATION_FORM_ID;

  return (
    <li className="py-2 todo-question">
      <h4 className="m-0 fw-bold">
        {props.showQuestionNumbers &&
          `${props.question.sorting}. `
        }
        {decode(props.question.question)}

        {showFormRequiredLabel && (
          props.question.is_required === "yes" ? " *" : " (optional)"
        )}
      </h4>
      {/* <InputComponent questionType={props.question.question_type} {...props} /> */}
      {questionType == "textLong" || questionType == "textXLong" || questionType == "textXXLong" ?
        <TextArea {...props} />
        :
        questionType == "radioButtons" ?
          <RadioButtons {...props} />
          :
          questionType == "textTiny" || questionType == "textXShort" || questionType == "textShort" ?
            <TextInput {...props} />
            :
            questionType == "checkBoxes" ?
              <CheckBoxes {...props} />
              :
              questionType == "numberRange" ?
                <NumberRangeInput {...props} />
                :
                questionType == "ratingScale" ?
                  <Scale {...props} />
                  :
                  questionType == "date" ?
                    <DatePicker {...props} />
                    :
                    questionType == "weight" ?
                      <Weight {...props} />
                      :
                      questionType == "disclaimer" ?
                        <Disclaimer {...props} />
                        :
                        questionType == "height" ?
                          <Height {...props} />
                          :
                          questionType == "address" ?
                            <Address {...props} />
                            :
                            "" // default
      }
      <p className="question-help">{decode(props?.question?.question_help)}</p>
      {props.errors[`question-${props.question.id}`] && props.errors[`question-${props.question.id}`] && typeof props.errors[`question-${props.question.id}`] == "string" && (
        <p className="invalid-feedback d-block">{props.errors[`question-${props.question.id}`]}</p>
      )}
    </li>
  )
}

export default ToDoQuestion;