import { PDFDownloadLink, Document, Page, Text, StyleSheet, View, Image, pdf } from '@react-pdf/renderer';
import { Fragment, useEffect, useRef, useState } from 'react';
import { decode } from 'html-entities';
import { FULL_NAME } from 'helpers/common';
import moment from 'moment';
import { getBodySymptomBurdenGraphV1 } from "store/services/todoService";
import { Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import { BarChart } from './BarChart';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  chartImage: {
    width: "100%",
    height: "auto"
  }
});

const MyDoc = ({ base64Image, client, date }) => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>

        <View style={styles.pageHeader}>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Symptom Burden Graph
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>
        <Image 
          style={styles.chartImage}
          src={base64Image}
        />

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const DownloadSBBarGraph = ({ client, date, todoId, showLoader, hideLoader }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [bodySymptomBurdenGraphData, setBodySymptomBurdenGraphData] = useState(null);

  const chartRef = useRef(null);
  const downloadLink = useRef(null);
  
  useEffect(() => {
    if (!bodySymptomBurdenGraphData) {
      getBodySymptomBurdenGraphData();
    }
  }, []);

  const getBodySymptomBurdenGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      const response = await getBodySymptomBurdenGraphV1(todoId);
      if (response.success === true) {
        const data = response.body?.symptomBurdenGraph;
        setBodySymptomBurdenGraphData(data);
      }
      setLoaded(true);
    } catch (err) {
      setLoaded(false);
      console.log(err);
    }
  }

  const downloadChartPdf = async (event) => {
    try {
      if(chartRef.current) {
        showLoader();
        const base64Image = chartRef.current.downloadChartImage();
        const doc = <MyDoc base64Image={base64Image} client={client} date={date}/>;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, 'Symptom-Burden-Bar-Graph.pdf');
        hideLoader();
      }
    } catch(err) {
      console.log("Error downloading graph", err);
      hideLoader();
    }
  }

  return (
    <>
      <Link
        onClick={downloadChartPdf}
      >
        Symptom Burden Bar Graph
      </Link>
      <div style={{position: "fixed", right: "-99999px", width: "1000px", height: "1000px"}}>
        <BarChart
          ref={chartRef}
          chartData={bodySymptomBurdenGraphData}
          height={160}
          screenSize={"large"}
          isPdf={true}
        />
      </div>
    </>
  )
}

export default DownloadSBBarGraph;