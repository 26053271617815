import {postData, getData} from 'helpers/apiHelper'
import { MAIN_PATH, LOGIN, APP_PATH, REGISTER, ACCEPT_INVITE, VALIDATE_SIGNUP_KEY, LOGOUT, FORGOT_PASSWORD, RESET_PASSWORD, RESET_PASSWORD_FORM, VALIDATE_RESET_PWD_TOKEN, CHANGE_PASSWORD, ACCOUNT_ACTIVATION, UPDATE_EMAIL, GET_PRACTITIONER_DETAILS, VALIDATE_SELF_SIGNUP_KEY, GET_PRACTITIONER_SETTINGS, GET_AUTH_PROFILES_LIST, GET_AUTH_PROFILE_DETAILS, ACCEPT_INVITE_LOGIN } from './apiPath'
import { authLogin, authRegister, authAcceptInvite, authLogout, authForgotPassword, updatePassword, practitionerDetails, practitionerSettings} from 'store/actions/auth';

//login
export function login(credentials, callback) {
  let url = `${MAIN_PATH}${LOGIN}`;
  // let url = `http://localhost:3000/dev/auth/login`;
  return postData(url, credentials)
}

//register
export function register_(credentials, callback) {
  let url = `${MAIN_PATH}${REGISTER}`;
  // let url = `http://localhost:3000/dev/auth/register`;
  return (dispatch) => postData(url, credentials)
    .then((data) => {
      dispatch(authRegister(data, callback));
      return data;
    });
}

// accept invite and set password
export function acceptInvite(credentials, callback) {
  let url = `${MAIN_PATH}${ACCEPT_INVITE}`;
  // let url = `http://localhost:3000/dev/auth/acceptInvite`;
  return (dispatch) => postData(url, credentials)
    .then((data) => {
      dispatch(authAcceptInvite(data, callback));
      return data;
    });
}

// accept invite and set password
export function validateSignUpKey(key, callback) {
  return () => getData(`${MAIN_PATH}${VALIDATE_SIGNUP_KEY}/${key}`)
    .then((data) => {
      if(typeof callback == "function") {
        callback(data);
      }
      return data;
    });
}

// validate self signup key
export function validateSelfSignUpKey(key) {
  let url = `${MAIN_PATH}${VALIDATE_SELF_SIGNUP_KEY}/${key}`;
  return getData(url);
}

//logout
export function logout(credentials) {
  return (dispatch) => postData(`${MAIN_PATH}${LOGOUT}`, credentials)
    .then((data) => {
      dispatch(authLogout(data));
      return data;
    });
}

//forgot passord
export function forgotPassword(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${FORGOT_PASSWORD}`, value)
    .then((data) => {
      dispatch(authForgotPassword(data, callback));
      return data;
    });
}


// reset passord
export function resetPasswordForm(value, token) {
  return postData(`${MAIN_PATH}${RESET_PASSWORD_FORM}/${token}`, value)
}


//forgot passord
export function resetPassword(value) {
  return (dispatch) => postData(`${MAIN_PATH}${RESET_PASSWORD}`, value)
    .then((data) => {
      return data;
    });
}

//forgot passord
export function validateResetPasswordToken(token) {
  return getData(`${MAIN_PATH}${VALIDATE_RESET_PWD_TOKEN}/${token}`)
}

//account activation
export function activateAccount(value) {
  return (dispatch) => postData(`${APP_PATH}${ACCOUNT_ACTIVATION}`, value)
    .then((data) => {
      return data;
    });
}

//change password
export function changePassword(value) {
  return postData(`${MAIN_PATH}${CHANGE_PASSWORD}`, value)
}
// export function changePassword(value, callback) {
//   return (dispatch) => postData(`${MAIN_PATH}${CHANGE_PASSWORD}`, value)
//     .then((data) => {
//       dispatch(updatePassword(data, callback));
//       return data;
//     });
// }


// Update email
export function updateEmail(value) {
  return postData(`${MAIN_PATH}${UPDATE_EMAIL}`, value)
}

export function getPracitionerDetails(value, callback) {
  let url = `${MAIN_PATH}${GET_PRACTITIONER_DETAILS}`; 
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(practitionerDetails(data, callback));
      return data;
    });
}

export function getPractitionerSettings(value, callback) {
  let url = `${MAIN_PATH}${GET_PRACTITIONER_SETTINGS}`; 
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(practitionerSettings(data, callback));
      return data;
    });
}

export function getAuthProfilesList() {
  let url = `${MAIN_PATH}${GET_AUTH_PROFILES_LIST}`; 
  return getData(url);
}

export function getProfileDetails(profileId) {
  let url = `${MAIN_PATH}${GET_AUTH_PROFILE_DETAILS}${profileId}`; 
  return postData(url);
}

export function acceptInviteLogin(values) {
  let url = `${MAIN_PATH}${ACCEPT_INVITE_LOGIN}`; 
  // let url = `http://localhost:3000/dev/auth/acceptInvite/password`;
  return postData(url, values);
}