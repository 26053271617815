import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import { passwordStrength } from 'check-password-strength'

//imported
import { passwordValidation } from "./validation";
import LoadingButton from "components/shared/LoadingButton"

const Form_ = ({ initialValues, updateData_, isReady }) => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={passwordValidation}
        onSubmit={updateData_}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Password*
                </label>
                <div style={{position: "relative"}}>
                  <input
                    type={`${showPassword ? "text" : "password"}`}
                    className={`form-control ${errors.password && 'is-invalid'}`}
                    name="password"
                    onChange={handleChange}
                    placeholder="********"
                    onBlur={handleBlur}
                    value={values.password}
                    autoComplete="off"
                  />
                  {!errors.password &&
                    <span
                      onClick={togglePasswordVisibility}
                      className="password-eye-icon"
                    >
                      <i className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                    </span>
                  }
                </div>
                {values?.password && (
                  <p className="m-0 py-1 password-strength">Password Strength: <span className={`strength strength-${passwordStrength(values.password).id}`}>{passwordStrength(values.password)?.value}</span></p>
                )}
                {(errors.password && touched.password) &&
                  <p className="invalid-feedback d-block">{errors.password}</p>
                }
              </Col>
              <Col lg="12" className="my-2">
                <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                  Re-enter Password*
                </label>
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  className={`form-control ${errors.confirm_password && 'is-invalid'}`}
                  name="confirm_password"
                  onChange={handleChange}
                  placeholder="********"
                  onBlur={handleBlur}
                  value={values.confirm_password}
                  autoComplete="off"
                />
                {(errors.confirm_password && touched.confirm_password) &&
                  <p className="invalid-feedback">{errors.confirm_password}</p>
                }
              </Col>
              <Col lg="12" className="my-2">
                {/* <Button className="d-flex align-items-center fw-bold justify-content-center commonBtn w-100">
                        Login Now
                      </Button> */}
                {/* <Link
                      to="/dashboard"
                      className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                    >
                      Login Now
                    </Link> */}
                <LoadingButton
                  type="submit"
                  className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                  disabled={!isReady}
                >
                  {isSubmitting ? 'Loading...' : 'Sign Up'}
                </LoadingButton>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Form_;