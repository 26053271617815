const Scale = ({ question, value, setFieldValue, onChange, handleBlur }) => {
  let scaleColors = [
    "#6A9FA6",
    "#83AFB5",
    "#9CBFC4",
    "#B5CFD3",
    "#CDDFE1",
    "#DEDEDE",
    "#D5DFD7",
    "#C0D0C3",
    "#ABC0AF",
    "#96B09B",
    "#81A087",
  ];
  let columnData = {
    scale: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  };
  try {
    columnData = JSON.parse(question.other_column_data);
    if(columnData && typeof columnData?.minNum === "number" && typeof columnData?.maxNum === "number") {
      let scale = Array.apply(null, {length: columnData?.maxNum + 1 - columnData?.minNum}).map(function(_, idx) {
          return idx + columnData?.minNum;
      });

      if(scale && scale.length > 0) {
        columnData.scale = scale;
      }
    }
  } catch (error) {
    console.log("unable to parse column data for the question", question);
  }
  return (
    <div className="CstmScale position-relative py-2">
      <ul className="list-unstyled ps-0 mb-0 position-relative d-flex align-items-center">
        {columnData?.scale &&
          columnData?.scale?.length > 0 &&
          columnData.scale.map((scaleIndex) => {
            let color = scaleColors[scaleIndex];
            return (
              <li className="position-relative">
                <input
                  type="radio"
                  className="position-absolute w-100 h-100"
                  name={`question-${question.id}`}
                  onBlur={handleBlur}
                  value={scaleIndex}
                  onChange={onChange}
                  checked={value == scaleIndex}
                />
                <div
                  className="cstmBox p-1 d-flex align-items-center justify-content-center position-relative text-center"
                  style={{ background: color }}
                >
                  <p className="m-0 fw-sbold text-dark">{scaleIndex}</p>
                </div>
              </li>
            );
          })}
      </ul>
      <div class="CstmScale-information py-2">
        {columnData && columnData?.minTxt && (
          <p className="m-0 fw-normal text-muted">{`${columnData?.minNum}: ${columnData?.minTxt}`}</p>
        )}
        {columnData && columnData?.maxTxt && (
          <p className="m-0 fw-normal text-muted">{`${columnData?.maxNum}: ${columnData?.maxTxt}`}</p>
        )}
      </div>
    </div>
  );
};

export default Scale;