import parse from 'html-react-parser';

const BannerMessage = ({ data }) => {
  return (
    <div>
      {parse(data?.message)}
    </div>
  )
}

export default BannerMessage;