import React from "react";
import { Col, Row } from "react-bootstrap";

// icns
import { ReactComponent as Doc } from "../../../../../Assets/Images/icn/doc.svg";
import { ReactComponent as GUsers } from "../../../../../Assets/Images/icn/GreenUsers.svg";
import { ReactComponent as PCalender } from "../../../../../Assets/Images/icn/PinkCalender.svg";

const ColorCards = ({ completedToDoCount, pendingToDoCount, appointmentCount }) => {
  return (
    <>
      <Row className="colorCards">
        <Col xl="3" sm="6" className="my-2">
          <div
            className="cardCstm p-3 h-100 d-flex align-items-start gap-10"
            style={{ background: "#39796f" }}
          >
            <div
              className="imgWrp d-flex align-items-center justify-content-center rounded-circle"
              style={{ background: "#FFE8CA" }}
            >
              <Doc />
            </div>
            <dic className="content">
              <h3 className="m-0 fw-sbold text-white">Completed To-Do Forms</h3>
              <h4 className="count m-0 theme-clr fw-bold">{completedToDoCount || 0}</h4>
            </dic>
          </div>
        </Col>
        <Col xl="3" sm="6" className="my-2">
          <div
            className="cardCstm p-3 h-100 d-flex align-items-start gap-10"
            style={{ background: "#fdc169" }}
          >
            <div
              className="imgWrp d-flex align-items-center justify-content-center rounded-circle"
              style={{ background: "#ffe2e2" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <g clip-path="url(#clip0_0_8193)">
                  <path
                    d="M21.3106 2.1868C20.8241 1.69921 20.177 1.43066 19.4886 1.43066H18.3257V1.19629C18.3257 0.550117 17.8 0.0244141 17.1538 0.0244141H8.71631C8.07014 0.0244141 7.54443 0.550117 7.54443 1.19629V1.43066H6.37739C4.9589 1.43066 3.80358 2.58595 3.80198 4.00593L3.78272 21.4434C3.78197 22.1326 4.04948 22.7807 4.536 23.2683C5.02256 23.7559 5.66967 24.0244 6.35812 24.0244H19.4692C20.8878 24.0244 22.0431 22.8691 22.0447 21.4491L22.064 4.01165C22.0648 3.32245 21.7972 2.67435 21.3106 2.1868ZM8.95068 1.43066H16.9194V2.83691H8.95068V1.43066ZM20.6399 21.4476C20.6392 22.0931 20.114 22.6182 19.4692 22.6182H6.35812C6.04519 22.6182 5.75104 22.4961 5.52989 22.2745C5.30873 22.0528 5.18714 21.7582 5.18747 21.445L5.20673 4.00752C5.20744 3.36201 5.73258 2.83691 6.37739 2.83691H7.54443V3.07129C7.54443 3.71746 8.07014 4.24316 8.71631 4.24316H17.1538C17.8 4.24316 18.3257 3.71746 18.3257 3.07129V2.83691H19.4886C19.8015 2.83691 20.0956 2.95898 20.3168 3.1806C20.5379 3.40223 20.6596 3.69684 20.6592 4.01005L20.6399 21.4476Z"
                    fill="#548B54"
                  />
                  <path
                    d="M13.1623 9.39926H18.1579C18.5462 9.39926 18.861 9.08445 18.861 8.69614C18.861 8.30783 18.5462 7.99301 18.1579 7.99301H13.1623C12.774 7.99301 12.4592 8.30783 12.4592 8.69614C12.4592 9.08445 12.774 9.39926 13.1623 9.39926ZM13.1623 14.0868H18.1579C18.5462 14.0868 18.861 13.772 18.861 13.3836C18.861 12.9953 18.5462 12.6805 18.1579 12.6805H13.1623C12.774 12.6805 12.4592 12.9953 12.4592 13.3836C12.4592 13.772 12.774 14.0868 13.1623 14.0868ZM18.178 17.368H13.1623C12.774 17.368 12.4592 17.6828 12.4592 18.0711C12.4592 18.4595 12.774 18.7743 13.1623 18.7743H18.178C18.5663 18.7743 18.8811 18.4595 18.8811 18.0711C18.8811 17.6828 18.5663 17.368 18.178 17.368ZM10.1697 6.78162L8.54819 8.40312L8.16588 8.02076C7.89129 7.74617 7.44612 7.74617 7.17152 8.02076C6.89693 8.29531 6.89693 8.74053 7.17152 9.01512L8.05104 9.89469C8.1829 10.0265 8.36174 10.1006 8.54822 10.1006C8.7347 10.1006 8.91354 10.0265 9.0454 9.89469L11.1641 7.77603C11.4387 7.50148 11.4387 7.05626 11.1641 6.78167C10.8895 6.50703 10.4443 6.50703 10.1697 6.78162ZM10.1697 11.8271L8.54819 13.4486L8.16588 13.0663C7.89129 12.7917 7.44612 12.7917 7.17152 13.0663C6.89693 13.3408 6.89693 13.786 7.17152 14.0607L8.05104 14.9402C8.1829 15.0721 8.36174 15.1462 8.54822 15.1462C8.7347 15.1462 8.91354 15.0721 9.0454 14.9402L11.1641 12.8215C11.4387 12.547 11.4387 12.1017 11.1641 11.8272C10.8895 11.5526 10.4443 11.5526 10.1697 11.8271ZM10.1697 16.5146L8.54819 18.1361L8.16588 17.7538C7.89129 17.4792 7.44612 17.4792 7.17152 17.7538C6.89693 18.0283 6.89693 18.4735 7.17152 18.7482L8.05104 19.6277C8.1829 19.7596 8.36174 19.8337 8.54822 19.8337C8.7347 19.8337 8.91354 19.7596 9.0454 19.6277L11.1641 17.509C11.4387 17.2345 11.4387 16.7892 11.1641 16.5147C10.8895 16.2401 10.4443 16.2401 10.1697 16.5146Z"
                    fill="#548B54"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_0_8193">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.92334 0.0244141)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <dic className="content">
              <h3 className="m-0 fw-sbold text-white">Pending To-Do Forms</h3>
              <h4 className="count m-0 theme-clr fw-bold">{pendingToDoCount || 0}</h4>
            </dic>
          </div>
        </Col>
        <Col xl="3" sm="6" className="my-2">
          <div
            className="cardCstm p-3 h-100 d-flex align-items-start gap-10"
            style={{ background: "#f4694b" }}
          >
            <div
              className="imgWrp d-flex align-items-center justify-content-center rounded-circle"
              style={{ background: "#ebb3bd" }}
            >
              <PCalender />
            </div>
            <dic className="content">
              <h3 className="m-0 fw-sbold text-white">Upcoming Appointments</h3>
              <h4 className="count m-0 theme-clr fw-bold">{appointmentCount || 0}</h4>
            </dic>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ColorCards;
