import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { forgotPassword } from 'store/services/authService';
import { SUCCESS_MESSAGE } from "common/constantMessage";
import * as Path from "Routes/paths"
import Form from "./Form";

const ForgotPassword = ({ dispatch, submitting }) => {
  const navigate = useNavigate();
  const initialValues = {
    email: ""
  }

  const submitData = async (values, {setSubmitting, resetForm}) => {
    try{
      setSubmitting(true);
      const callback = (response) => {
        const { body } = response;
        console.log(response)
        SUCCESS_MESSAGE(response.message);
        // navigate(Path.login);
      }
      values = JSON.stringify(values)
      await dispatch(forgotPassword(values, callback))
      setSubmitting(false);
      resetForm({
        email: ""
      });
    } catch (err) {
        console.log(err,  "==============================");
        setSubmitting(false);
    }
  }
  
  return (
    <>
      {/* <AuthLayout> */}
      <div className="FormInner py-5 bg-white px-sm-5 px-4">
        <div className="top mb-3">
          <h2 className="m-0 fw-bold">Forgot Password</h2>
        </div>
        <div className="FormBody">
          <Form
            initialValues={initialValues}
            submitting={submitting}
            updateData_={submitData}
          />
        </div>
      </div>
      {/* </AuthLayout> */}
    </>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return{
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);