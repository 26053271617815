import React, { useEffect, useRef, useState } from "react";
import {
  Accordion, Button, Dropdown,
} from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
// import LinePlot from "./Component/TestChart";
import { Link, useParams } from "react-router-dom";

import * as actions from "store/actions";
import FoodMoodJournalData from "./Components/FoodMoodJournalData";
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { viewFoodMoodSubmittedData } from "store/services/foodMoodJournalService";
import FoodMoodJournalPdf from "./Components/FoodMoodJournalPdf";
import { transformFMJData } from "helpers/dataFormat";


const FoodMoodJournal = ({ dispatch, todoId, formName, submittedOn, userProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [journalData, setJournalData] = useState(null);

  useEffect(() => {
    if (isOpen && !journalData) {
      getFoodMoodJournalData(todoId);
    }
  }, [isOpen])

  const getFoodMoodJournalData = async (todoId) => {
    try {
      // setIsLoading(true);
      dispatch(actions.persist_store({ loader: true}));
      const response = await viewFoodMoodSubmittedData(todoId);
      if (response.success === true) {
        const data = transformFMJData(response.body);
        setJournalData(data);
      }
      // setIsLoading(false);
      dispatch(actions.persist_store({ loader: false}));
    } catch (err) {
      // setIsLoading(false);
      dispatch(actions.persist_store({ loader: false}));
      console.log(err);
    }
  }

  const showLoader = () => {
    dispatch(actions.persist_store({ loader: true }));
  }

  const hideLoader = () => {
    dispatch(actions.persist_store({ loader: false }));
  }

  return (
    <Accordion.Item
      eventKey={todoId}
      className=" my-2"
      style={{
        border: "1px solid #2B3674",
      }}
    >
      <Accordion.Header
        className=""
        style={{
          background: "#F9FAFF",
          // border: "1px solid #2B3674",
          color: "#2B3674",
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className="toggleBtn position-absolute"></span>

        <div className="d-flex align-items-center gap-10">
          <span className="icn me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                fill="currentColor"
              />
              <path
                d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                fill="currentColor"
              />
              <path
                d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {formName}
        </div>
        <div className="right d-flex align-items-center gap-10">
          <p className="m-0 text-dark text-lowercase fw-sbold">
            {submittedOn && `Submitted on ${moment(submittedOn).format("MMM DD, YYYY")}`}
          </p>
        </div>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #2B3674",
        }}
        // onEnter={() => {
        //   setIsOpen(true)
        // }}
        // onExit={() => {
        //   setIsOpen(false)
        // }}
      >
        <div className="py-2 AccordionInner">
          <div className="inner">
            <div className="py-2">
              <div className="top d-flex align-items-center justify-content-between gap-10">
                <div className="left d-flex align-items-start gap-10 flex-wrap">
                </div>
                <div className="right">
                  <FoodMoodJournalPdf
                    date={submittedOn}
                    client={userProfile}
                    todoId={todoId}
                    formName={formName}
                    label="Download Report"
                    showNotes={false}
                    showLoader={showLoader}
                    hideLoader={hideLoader}
                  />
                </div>
              </div>
            </div>
            <FoodMoodJournalData
              todoId={todoId}
              loading={isLoading}
              journalData={journalData}
              getDetails={getFoodMoodJournalData}
            />
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FoodMoodJournal);