import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { ReactComponent as DownloadIcn } from "../../../Assets/Images/icn/download.svg";
import { getFullscriptRecommendationDetails, getFullscriptRecommendations, getRecommendationDetails, getRecommendationList } from "store/services/recommendationService";
import SingleRecommendation from "./Components/SingleRecommendation";
import * as actions from "store/actions";

import { ReactComponent as CopyIcn } from "Assets/Images/icn/Copy.svg";
import { ReactComponent as EditIcon } from "Assets/Images/icn/pencil.svg";
import { ReactComponent as FScriptIcn } from "Assets/Images/icn/fullScript.svg";
import SingleFullscriptRecommendation from "./Components/SingleFullscriptRecommendation";
import { profile } from "store/services/profileService";
import { HEALTH_COATCH_CAT_ID } from "common/constants";
import { getActionPlanDetails, getActionPlansList } from "store/services/actionPlansService";
import SingleActionPlan from "./Components/SingleActionPlan";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "components/shared/ErrorComponent";

const Recommendation = ({
  dispatch,
  user,
  userCategory,
  recommendations,
  fullscriptRecommendations,
  actionPlans
}) => {
  console.log()
  const [isLoading, setIsLoading] = useState(false);
  const [itemName, setItemName] = useState("Recommendation");

  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true);
        dispatch(actions.persist_store({ loader: true }));
        if (userCategory === HEALTH_COATCH_CAT_ID) {
          await Promise.all([
            dispatch(getActionPlansList()),
            // dispatch(profile()),
          ])
        } else {
          await Promise.all([
            dispatch(getRecommendationList()),
            dispatch(getFullscriptRecommendations()),
            // dispatch(profile()),
          ])
        }
        setIsLoading(false);
        dispatch(actions.persist_store({ loader: false }));
      } catch (err) {
        console.log("Error in init", err);
        setIsLoading(false);
        dispatch(actions.persist_store({ loader: false }));
      }
    }

    if (userCategory) {
      init();
      const itemName = (userCategory === HEALTH_COATCH_CAT_ID) ? "Action Plan" : "Recommendation";
      setItemName(itemName);
    }
  }, [userCategory]);

  const viewRecommendationDetails = async (recommendationId) => {
    try {
      let data = {
        recommendationId
      }
      dispatch(actions.persist_store({ loader: true }));
      let response = await getRecommendationDetails(data);
      dispatch(actions.persist_store({ loader: false }));
      return response;
    } catch (error) {
      dispatch(actions.persist_store({ loader: false }));
      throw error;
    }
  }

  const viewFullscriptRecommDetails = async (recommendationId) => {
    try {
      let data = {
        recommendationId
      }
      dispatch(actions.persist_store({ loader: true }));
      let response = await getFullscriptRecommendationDetails(data);
      dispatch(actions.persist_store({ loader: false }));
      return response;
    } catch (error) {
      dispatch(actions.persist_store({ loader: false }));
      throw error;
    }
  }

  const viewActionPlanDetails = async (id) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await getActionPlanDetails(id);
      dispatch(actions.persist_store({ loader: false }));
      return response;
    } catch (error) {
      dispatch(actions.persist_store({ loader: false }));
      throw error;
    }
  }

  if (isLoading) {
    return (
      <div className="p-3">
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <>
      <section className="recommentdation position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <Accordion className="my-3 ColoredAcorrdion">
                <ErrorBoundary
                  fallback={<ErrorComponent />}
                >
                  {
                    (!recommendations || !recommendations.length) &&
                    (!fullscriptRecommendations || !fullscriptRecommendations.length) &&
                    (!actionPlans || !actionPlans.length) &&
                    <p>
                      No data found
                    </p>
                  }
                  {fullscriptRecommendations && fullscriptRecommendations.length > 0 &&
                    <>
                      <p>Below are your current {itemName}s from NTA Academics Team. Click on each row to view more information.</p>
                      {fullscriptRecommendations.map(recommendation => {
                        return (
                          <SingleFullscriptRecommendation
                            recommendation={recommendation}
                            getDetails={viewFullscriptRecommDetails}
                          />
                        )
                      })}
                    </>
                  }
                  {recommendations && recommendations.length > 0 &&
                    <>
                      {recommendations.map(recommendation => {
                        return (
                          <SingleRecommendation
                            recommendation={recommendation}
                            getDetails={viewRecommendationDetails}
                          />
                        )
                      })}
                    </>
                  }

                  {actionPlans && actionPlans.length > 0 &&
                    <>
                      {actionPlans.map(actionPlan => {
                        return (
                          <SingleActionPlan
                            actionPlan={actionPlan}
                            getDetails={viewActionPlanDetails}
                            user={user}
                          />
                        )
                      })}
                    </>
                  }
                </ErrorBoundary>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    user: state.Profile.user,
    userCategory: state.Profile.user?.user_category,
    recommendations: state.Recommendation.items,
    fullscriptRecommendations: state.Recommendation.fullscriptRecommendations,
    actionPlans: state.ActionPlan.data,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Recommendation);
