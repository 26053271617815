import { useRef } from "react";
import { Calendar } from "react-big-calendar";

function MyCalendar(props) {
  const calendarRef = useRef(null);

  const handleTouchStart = (event) => {
    if (calendarRef.current) {
      const touchEvent = new MouseEvent('mousedown', {
        bubbles: true,
        cancelable: true,
        clientX: event.touches[0].clientX,
        clientY: event.touches[0].clientY,
      });
      calendarRef.current.dispatchEvent(touchEvent);
    }
  };

  return (
    <div
      ref={calendarRef}
      onTouchStart={handleTouchStart} // Trigger mousedown immediately on touch
    >
      <Calendar
        {...props}
      />
    </div>
  );
}


export default MyCalendar;