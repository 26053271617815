import { GET_FULLSCRIPT_RECOMMENDATIONS, GET_RECOMMENDATION_LIST } from 'store/actions/recommendation';

const initialState = {
    items: [],
    fullscriptRecommendations: [],
    loading: false,
};

//get 
const getRecommendationList = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    
    const stateObj = {
        ...state,
        items: data.body.data || [],
        loading: false,
    };
  return stateObj;
};

const getFullscriptRecommendations = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    
    const stateObj = {
        ...state,
        fullscriptRecommendations: data.body,
    };
  return stateObj;
};


const Recommendation = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_RECOMMENDATION_LIST:
          return getRecommendationList(state, payload);
        case GET_FULLSCRIPT_RECOMMENDATIONS:
            return getFullscriptRecommendations(state, payload);
        default:
        return state;
    };
}
export default Recommendation;