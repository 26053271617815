import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { BOOK_APPOINTMENT, CANCEL_APPOINTMENT, CREATE_APPOINTMENT_PAYMENT_INTENT, GET_APPOINTMENT_BOOKING_LIST, GET_APPOINTMENT_SLOTS, GET_APPOINTMENT_TYPE_DETAILS, GET_APPOINTMENT_TYPE_LIST, MAIN_PATH, RESCHEDULE_APPOINTMENT  } from './apiPath'
import { appointmentBookingList, appointmentTypeList, bookAppointment, rescheduleAppointment } from 'store/actions/appointment';

// get Appointment type list
export function getAppointmentTypeList(callback) {
  let url = `${MAIN_PATH}${GET_APPOINTMENT_TYPE_LIST}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(appointmentTypeList(data, callback));
      return data;
    });
}

//appointment type view
export function getAppointmentTypeDetails(id, value, callback) {
  let url = `${MAIN_PATH}${GET_APPOINTMENT_TYPE_DETAILS}${id}`;
  return getData(url, value)
}

//appointment slots
export function getAppointmentSlots(data, callback) {
  let { appointmentTypeId, values } = data;
  let url = `${MAIN_PATH}${GET_APPOINTMENT_SLOTS}${appointmentTypeId}`;
  // let url = `http://localhost:3000/STAGING/appointment/slots/${appointmentTypeId}`;
  return (dispatch) => postData(url, values)
    .then((data) => {
      return data;
  });
}

// book appointment
export function appointmentBook_(value, callback) {
  let url = `${MAIN_PATH}${BOOK_APPOINTMENT}`;
  // let url = `http://localhost:3000/dev/appointment/book`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(bookAppointment(data, callback));
      return data;
  });
}

// create appointment payment intent
export function createAppointmentPaymentIntent(data, callback) {
  const { appointmentId, values } = data;
  let url = `${MAIN_PATH}${CREATE_APPOINTMENT_PAYMENT_INTENT.replace(":id", appointmentId)}`;
  return postData(url, values);
}

// get appointment booking list
export function getAppointmentList(callback) {
  let url = `${MAIN_PATH}${GET_APPOINTMENT_BOOKING_LIST}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(appointmentBookingList(data, callback));
      return data;
  });
}

// reschedule appointment
export function rescheduleAppointment_(id, value, callback) {
  let url = `${MAIN_PATH}${RESCHEDULE_APPOINTMENT}${id}`;
  // let url = `http://localhost:3000/dev/appointment/reschedule/${id}`;
  return (dispatch) => postData(url, value)
    .then((data) => {
      dispatch(rescheduleAppointment(data, callback));
      return data;
  });
}

// cancel appointment
export function cancelAppointment_(data, callback) {
  let { id, value } = data
  let url = `${MAIN_PATH}${CANCEL_APPOINTMENT}${id}`;
  return postData(url, value);
}