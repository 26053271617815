//action types
export const GET_RECOMMENDATION_LIST = 'GET_RECOMMENDATION_LIST';
export const VIEW_RECOMMENDATION = 'VIEW_RECOMMENDATION';
export const GET_FULLSCRIPT_RECOMMENDATIONS = "GET_FULLSCRIPT_RECOMMENDATIONS";

//get 
export function getRecommendationList_(data, callback) {
  return {
    type: GET_RECOMMENDATION_LIST,
    payload : {data, callback},
  };
}

export function getFullscriptRecommendations_(data, callback) {
  return {
    type: GET_FULLSCRIPT_RECOMMENDATIONS,
    payload : {data, callback},
  };
}
