import React from "react";
import { Button } from "react-bootstrap";

// icn
import { ReactComponent as AddIcn } from "../../../../../Assets/Images/icn/AddCircle.svg";
import { ReactComponent as StarIcn } from "../../../../../Assets/Images/icn/circleStar.svg";
import { Link } from "react-router-dom";

const TableData = () => {
  const data = [
    {
      foodName: "Alfalfa Sprouts",
      Prep: "Cooked",
      Category: "Vegetables",
      Fructose: "safe",
      Lactose: "safe",
      Fructans: "safe",
      Galactans: "safe",
      Polyols: "safe",
      Histamines: "uncertain",
      Salicylates: "avoid",
      SCD: "safe",
      Paleo: "safe",
      PaleoAIP: "safe",
      GlutenFree: "safe",
      GrainFree: "safe",
    },
    {
      foodName: "Alfalfa Sprouts",
      Prep: "Cooked",
      Category: "Vegetables",
      Fructose: "safe",
      Lactose: "safe",
      Fructans: "safe",
      Galactans: "safe",
      Polyols: "safe",
      Histamines: "uncertain",
      Salicylates: "safe",
      SCD: "safe",
      Paleo: "safe",
      PaleoAIP: "safe",
      GlutenFree: "safe",
      GrainFree: "safe",
    },
    {
      foodName: "Alfalfa Sprouts",
      Prep: "Cooked",
      Category: "Vegetables",
      Fructose: "safe",
      Lactose: "safe",
      Fructans: "safe",
      Galactans: "safe",
      Polyols: "safe",
      Histamines: "uncertain",
      Salicylates: "safe",
      SCD: "safe",
      Paleo: "safe",
      PaleoAIP: "safe",
      GlutenFree: "safe",
      GrainFree: "avoid",
    },
    {
      foodName: "Alfalfa Sprouts",
      Prep: "Cooked",
      Category: "Vegetables",
      Fructose: "safe",
      Lactose: "safe",
      Fructans: "safe",
      Galactans: "safe",
      Polyols: "safe",
      Histamines: "uncertain",
      Salicylates: "safe",
      SCD: "safe",
      Paleo: "safe",
      PaleoAIP: "safe",
      GlutenFree: "safe",
      GrainFree: "avoid",
    },
    {
      foodName: "Alfalfa Sprouts",
      Prep: "Cooked",
      Category: "Vegetables",
      Fructose: "safe",
      Lactose: "safe",
      Fructans: "safe",
      Galactans: "safe",
      Polyols: "safe",
      Histamines: "uncertain",
      Salicylates: "safe",
      SCD: "safe",
      Paleo: "safe",
      PaleoAIP: "safe",
      GlutenFree: "safe",
      GrainFree: "avoid",
    },
  ];

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="p-3 text-dark fw-sbold">Food Name </th>
              <th className="p-3 text-dark fw-sbold">Prep</th>
              <th className="p-3 text-dark fw-sbold"> Category </th>
              <th className="p-3 fw-bold text-dark thDenotion"> Fructose</th>
              <th className="p-3 fw-bold text-dark thDenotion">Lactose</th>
              <th className="p-3 fw-bold text-dark thDenotion"> Fructans</th>
              <th className="p-3 fw-bold text-dark thDenotion">Galactans</th>
              <th className="p-3 fw-bold text-dark thDenotion"> Polyols </th>
              <th className="p-3 fw-bold text-dark thDenotion"> Histamines</th>
              <th className="p-3 fw-bold text-dark thDenotion">Salicylates</th>
              <th className="p-3 fw-bold text-dark thDenotion">SCD Legal</th>
              <th className="p-3 fw-bold text-dark thDenotion">Paleo Legal</th>
              <th className="p-3 fw-bold text-dark thDenotion">
                Paleo AIP Legal
              </th>
              <th className="p-3 fw-bold text-dark thDenotion">Gluten-Free</th>
              <th className="p-3 fw-bold text-dark thDenotion">Grain-Free</th>
            </tr>
          </thead>
          <tbody className="p-3">
            {data &&
              data.length > 0 &&
              data.map((data, idx) => {
                return (
                  <>
                    <tr key={idx}>
                      <td className="p-1 border-0">
                        <Link to="/my-clients/detail" className="text-dark">
                          <b className="text-dark">{data.foodName}</b>
                        </Link>
                      </td>
                      <td className="p-1 border-0">{data.Prep}</td>
                      <td className="p-1 border-0">{data.Category}</td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.Fructose === "safe"
                              ? { background: "#548b54" }
                              : data.Fructose === "uncertain"
                              ? { background: "#f4694b" }
                              : data.Fructose === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.Lactose === "safe"
                              ? { background: "#548b54" }
                              : data.Lactose === "uncertain"
                              ? { background: "#f4694b" }
                              : data.Lactose === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.Fructans === "safe"
                              ? { background: "#548b54" }
                              : data.Fructans === "uncertain"
                              ? { background: "#f4694b" }
                              : data.Fructans === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.Galactans === "safe"
                              ? { background: "#548b54" }
                              : data.Galactans === "uncertain"
                              ? { background: "#f4694b" }
                              : data.Galactans === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.Polyols === "safe"
                              ? { background: "#548b54" }
                              : data.Polyols === "uncertain"
                              ? { background: "#f4694b" }
                              : data.Polyols === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.Histamines === "safe"
                              ? { background: "#548b54" }
                              : data.Histamines === "uncertain"
                              ? { background: "#f4694b" }
                              : data.Histamines === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.Salicylates === "safe"
                              ? { background: "#548b54" }
                              : data.Salicylates === "uncertain"
                              ? { background: "#f4694b" }
                              : data.Salicylates === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.SCD === "safe"
                              ? { background: "#548b54" }
                              : data.SCD === "uncertain"
                              ? { background: "#f4694b" }
                              : data.SCD === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.Paleo === "safe"
                              ? { background: "#548b54" }
                              : data.Paleo === "uncertain"
                              ? { background: "#f4694b" }
                              : data.Paleo === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.PaleoAIP === "safe"
                              ? { background: "#548b54" }
                              : data.PaleoAIP === "uncertain"
                              ? { background: "#f4694b" }
                              : data.PaleoAIP === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.GlutenFree === "safe"
                              ? { background: "#548b54" }
                              : data.GlutenFree === "uncertain"
                              ? { background: "#f4694b" }
                              : data.GlutenFree === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                      <td className="p-1 border-0">
                        <div
                          className="tableDenotion"
                          style={
                            data.GrainFree === "safe"
                              ? { background: "#548b54" }
                              : data.GrainFree === "uncertain"
                              ? { background: "#f4694b" }
                              : data.GrainFree === "avoid"
                              ? { background: "#ff5757" }
                              : null
                          }
                        ></div>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableData;
