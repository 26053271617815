import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { pdf } from "@react-pdf/renderer";

// import { getClientRecommendationReportsList, getRecommendationReportDetails } from "store/services/clientService";
import * as actions from "store/actions";

// import SBReportPdf from "./SBReportPdf";
import { getNAQv1Reports } from "store/services/todoService";
import { blobToBase64, validateConditions } from "helpers/common";
// import NAQRecommReportPDF from "./NAQRecommReportPDF";
// import SeverityPartOnePDF from "./SeverityPartOnePDF";
// import SeverityPartTwoPDF from "./SeverityPartTwoPDF";
// import { LineChart } from "./SymptomBurdenGraph/LineChart";
// import { MyDoc as SymptomBurdenGraph } from "./SymptomBurdenGraph/SBLineGraphPDF";
import moment from "moment";
import { formatTodoResponse } from "helpers/dataFormat";
import { FoundationSymptomGraphDoc } from "pages/SideTabs/ToDo/Component/SubmittedData/Component/NutritionalAssessmentV2/Component/NAQGraphsPdf/FoundationSymptomGraphPdf";
import CustomBarChart from "pages/SideTabs/ToDo/Component/SubmittedData/Component/NutritionalAssessmentV2/Component/CustomChart";
import html2canvas from "html2canvas";
import { BodySymptomGraphDoc } from "pages/SideTabs/ToDo/Component/SubmittedData/Component/NutritionalAssessmentV2/Component/NAQGraphsPdf/BodySymptomGraphPdf";
import { SBReportDoc } from "./SBReportPDF";
import NAQ2RecommendationReportDoc from "./NAQv2RecommReportPDF";
import { NAQ2PriorityReportSBGDoc } from "./PriorityReportSBGPDF";
import { NAQ2PriorityReportDoc } from "./PriorityReportPDF";
import { NAQ2QeuAnsDoc } from "./AllQuestionAnswersPDF";

const NAQ_COMPONENTS = [
  "Symptom Burden Graph",
  "Symptom Burden Report",
  "Recommendation Report",
  "Severity Report Part I",
  "Severity Report Part II",
  "Questions/Answers",
];

const GenerateNAQv2ReportsPdf = forwardRef(function ReportsNAQv2(
  { uuid, client, submitReports, getReports },
  ref
) {
  const [foundationGraphData, setFoundationGraphData] = useState(null);
  const [symptomBurdenGraphData, setSymptomBurdenGraphData] = useState(null);
  const [reportsData, setReportsData] = useState(null);
  const [reportsGenerated, setReportsGenerated] = useState(false);
  const [generatingReports, setGeneratingReports] = useState(false);
  const [renderTries, setRenderTries] = useState(0);

  let dateToday = moment();

  const chartRef = useRef(null);
  const fsbGraphRef = useRef(null);
  const sbGraphRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      try {
        dispatch(actions.persist_store({ loader: true }));
        let response = await getReports(uuid);
        console.log("response", response);
        // return;
        if (response?.success === true && response?.body) {
          let naqv2Reports = response.body;
          setReportsData(naqv2Reports);
          setFoundationGraphData(response?.body?.foundationGraph || {});
          setSymptomBurdenGraphData(response?.body?.symptomBurdenGraph || {});
        }
        dispatch(actions.persist_store({ loader: false }));
      } catch (err) {
        console.log("Error generating combined PDF", err);
        dispatch(actions.persist_store({ loader: false }));
      }
    }
    init();
  }, [])

  useEffect(async () => {
    async function generateReports() {
      try {
        if(!reportsData || reportsGenerated || generatingReports) {
          return;
        }
        if (fsbGraphRef.current && sbGraphRef.current) {
            dispatch(actions.persist_store({ loader: true }));
            setGeneratingReports(true);
            let naqBlobs = await getNAQv2ComponentBlobs(reportsData);
            setReportsGenerated(true);
            setGeneratingReports(false);
            await submitReports(naqBlobs);
            dispatch(actions.persist_store({ loader: false }));
        } else {
          if(renderTries < 10) {
            setRenderTries(renderTries + 1);
          }
        }
      } catch(err) {
        console.log("Error generating and submitting reports", err);
        dispatch(actions.persist_store({ loader: false }));
        setGeneratingReports(false);
      }
    }

    generateReports();
  }, [chartRef.current, reportsData, foundationGraphData, symptomBurdenGraphData]);

  /**
   * Function to generate the PDFs for selected NAQ Components
   */
  const getNAQv2ComponentBlobs = async (naqReports) => {
    try {
      const blobs = {};

      /**
       * Generate PDF for FSB Graph
       */
      let foundationSbGraphPdf = await foundationSbGrpah();
      blobs.foundationSbGraphPdf = foundationSbGraphPdf;

      /**
       * Generate PDF for SB Graph
       */
      let sbGraphPdf = await sbGrpah();
      blobs.sbGraphPdf = sbGraphPdf;

      // return blobs;

      /**
       * Generate PDF for SB Report
       */
      let sbReportPdf = await sbReport(naqReports?.symptomBurdenReport);
      // console.log("sbReportPdf", sbReportPdf);
      blobs.sbReportPdf = sbReportPdf;

      /**
       * Generate PDF for NAQ Recommendation
       */
      let recommendationPdf = await recommendationReport(
        naqReports?.recommendationReport
      );
      // console.log("recommendationPdf", recommendationPdf);
      blobs.recommendationPdf = recommendationPdf;

      /**
       * Generate PDF for Priority Report
       */
      let priorityReportPdf = await priorityReport(
        naqReports?.priorityReport
      );
      // console.log("priorityReportPdf", priorityReportPdf);
      blobs.priorityReportPdf = priorityReportPdf;

      /**
       * Generate PDF for Priority SBG Sections Report
       */
      let prioritySBGReportPdf = await priorityReportSBG(
        naqReports?.priorityReport
      );
      // console.log("prioritySBGReportPdf", prioritySBGReportPdf);
      blobs.prioritySBGReportPdf = prioritySBGReportPdf;

      /**
       * Generate PDF for NAQ All Questions/Answers with scores
       */
      let naqQueAnsPdf = await naqQueAns(naqReports?.allQuestionAnswers);
      blobs.naqQueAnsPdf = naqQueAnsPdf;

      return blobs;
    } catch (err) {
      console.log("Error generating NAQ component PDF", err);
      return null;
    }
  };

  async function foundationSbGrpah() {
    // const base64Image = fsbGraphRef.current && fsbGraphRef.current.downloadChartImage();
    const canvas = await html2canvas(fsbGraphRef.current, { scale: 3 })
    const base64Image = canvas.toDataURL('image/png');
    const doc = 
      <FoundationSymptomGraphDoc
        base64Image={base64Image}
        client={client}
        date={dateToday}
      />
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function sbGrpah() {
    // const base64Image = fsbGraphRef.current && fsbGraphRef.current.downloadChartImage();
    const canvas = await html2canvas(sbGraphRef.current, { scale: 3 })
    const base64Image = canvas.toDataURL('image/png');
    // console.log("base64Image", base64Image);
    const doc = 
      <BodySymptomGraphDoc
        base64Image={base64Image}
        client={client}
        date={dateToday}
      />
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function sbReport(symptomBurdernData) {
    // console.log("symptomBurdernData", symptomBurdernData);

    if (!symptomBurdernData) {
      return null;
    }
    let totalSymptomBurden = 0;
    symptomBurdernData.potential_conditions.map((condition) => {
      if (condition.conditionScore) {
        totalSymptomBurden += parseInt(condition.conditionScore)
      }
    })
    symptomBurdernData.potential_nutritional_deficiencies.map((deficiency) => {
      if (deficiency.conditionScore) {
        totalSymptomBurden += parseInt(deficiency.conditionScore)
      }
    })

    const doc = 
      <SBReportDoc
        symptomBurdernData={symptomBurdernData}
        totalSymptomBurden={totalSymptomBurden}
        client={client}
        date={dateToday}
      />
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function recommendationReport(recommendationData) {
    if (!recommendationData) {
      return null;
    }
    let manufacturer = "biotics";
    let nutritionalRows = recommendationData && recommendationData[manufacturer];
    const doc = (
      <NAQ2RecommendationReportDoc
        nutritionalRows={nutritionalRows}
        manufacturer={manufacturer}
        topPotentialSupports={recommendationData?.topPotentialSupports}
        client={client}
        date={dateToday}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function priorityReport(priorityReport) {
    if (!priorityReport) {
      return null;
    }

    const doc = (
      <NAQ2PriorityReportDoc
        priorityReport={priorityReport["questionnaireo"] || []}
        client={client}
        date={dateToday}
      />
    );
    const asPdf = pdf([]); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function priorityReportSBG(priorityReport) {
    if (!priorityReport) {
      return null;
    }

    const doc = (
      <NAQ2PriorityReportSBGDoc
        priorityReport={priorityReport["SBG"] || []}
        client={client}
        date={dateToday}
      />
    );
    const asPdf = pdf([]); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function naqQueAns(todoDetails) {
    const formattedData = formatTodoResponse(todoDetails);
    const todoData = validateConditions(formattedData);
    const doc = (
      <NAQ2QeuAnsDoc
        client={client}
        date={dateToday}
        todoDetails={todoData}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  // blobs = blobs.filter(blob => !!blob);

  // if(blobs.length == 0) {
  //   return null;
  // }

  // let combinedNAQBlobs = await combineBlobs(blobs);
  // return combinedNAQBlobs;

  return (
    <>
      <div style={{ position: "fixed", right: "-99999px", width: "1000px", height: "1000px" }}>
        {foundationGraphData && (
          <CustomBarChart
            innerRef={fsbGraphRef}
            dataset={foundationGraphData}
            title="Foundations Symptom Burden Graph"
            totalsTitle="Foundations Totals"
          />
        )}
        {symptomBurdenGraphData && (
          <CustomBarChart
            innerRef={sbGraphRef}
            dataset={symptomBurdenGraphData}
            title="Body Systems Symptom Burden Graph"
            totalsTitle="Body Systems Totals"
          />
        )}
      </div>
    </>
  );
});

export default GenerateNAQv2ReportsPdf;
