import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { GET_FOUNDATION_BURDEN_GRAPH_V2, GET_NAQV1_REPORTS, GET_NAQV2_REPORTS_PUBLIC, GET_SYMPTOM_BURDEN_GRAPH_V1, GET_SYMPTOM_BURDEN_GRAPH_V2, GET_TODO_RESPONSE, MAIN_PATH, SAVE_TODO_RESPONSE, SUBMIT_PRACTICE_B_NAQ, SUBMIT_PRACTICE_B_NAQ_V2, TODO_LIST, TODO_VIEW  } from './apiPath'
import { getToDo, saveToDoResponse, viewToDo} from 'store/actions/todo';

//get
export function getToDoList(value, callback) {
  const { page, limit } = value || {};
  let url = `${MAIN_PATH}${TODO_LIST}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getToDo(data, callback));
      return data;
    });
}

//view
export function viewToDoData(todoId, callback) {
  let url = `${MAIN_PATH}${TODO_VIEW.replace(":id", todoId)}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(viewToDo(data, callback));
      return data;
    });
}

// save ToDo response
export function saveTodoResponse(data, callback) {
  let {id, formData} = data;
  let url = `${MAIN_PATH}${SAVE_TODO_RESPONSE.replace(":id", id)}`;
  // let url = `http://localhost:3000/dev/todo/${id}/saveResponse`;
  return (dispatch) => postData(url, formData)
    .then((data) => {
      dispatch(saveToDoResponse(data, callback));
      return data;
    });
}

// view ToDo response
export function getTodoResponse(data) {
  let { todoId } = data;
  let url = `${MAIN_PATH}${GET_TODO_RESPONSE.replace(":id", todoId)}`;
  return getData(url);
}

export function submitPracticeBNAQ(todoId, data) {
  let url = `${MAIN_PATH}${SUBMIT_PRACTICE_B_NAQ}${todoId}`;
  // let url = `http://localhost:3000/dev/pb/submit-naq/${todoId}`;
  return postData(url, data);
}

export function submitPracticeBNAQv2(todoId, data) {
  let url = `${MAIN_PATH}${SUBMIT_PRACTICE_B_NAQ_V2}${todoId}`;
  // let url = `http://localhost:3000/STAGING/pb/submit-naq-v2/${todoId}`;
  return postData(url, data);
}

export function getNAQv1Reports(todoId) {
  let url = `${MAIN_PATH}${GET_NAQV1_REPORTS}${todoId}`;
  // let url = `http://localhost:3000/dev/reports/naq1/${todoId}`;
  return getData(url);
}

export function getNAQv2Reports(todoId) {
  let url = `${MAIN_PATH}${GET_NAQV2_REPORTS_PUBLIC}${todoId}`;
  // let url = `http://localhost:3000/dev/reports/naq1/${todoId}`;
  return getData(url);
}

export function getBodySymptomBurdenGraphV1(todoId) {
  let url = `${MAIN_PATH}${GET_SYMPTOM_BURDEN_GRAPH_V1}${todoId}`;
  // let url = `http://localhost:3000/dev/reports/naq1/sb-graph/${todoId}`;
  return getData(url);
}

export function getBodySymptomBurdenGraphV2(data) {
  const { todoId } = data;
  let url = `${MAIN_PATH}${GET_SYMPTOM_BURDEN_GRAPH_V2}${todoId}`;
  return getData(url);
}

export function getFoundationSymptomBurdenGraphV2(data) {
  const { todoId } = data;
  let url = `${MAIN_PATH}${GET_FOUNDATION_BURDEN_GRAPH_V2}${todoId}`;
  return getData(url);
}