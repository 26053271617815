import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { decode } from 'html-entities';
import { FULL_NAME } from 'helpers/common';
import moment from 'moment';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "5px",
    paddingBottom: "15px",
    // borderBottom: "2px solid #ccc",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 0",
    marginBottom: "-30px"
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "baseline",
    borderBottom: "1px solid #ccc",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "8%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "9px",
    width: "8%",
    padding: "2px",
    flexWrap: "wrap",
  },
});

export const NAQ2QeuAnsDoc = ({ client, date, todoDetails }) => {
  let questionCount = 0;
  return (
    <Document>
      <Page style={styles.page}>

        <View style={styles.pageHeader} fixed>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            NAQ Questions/Answers
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName} fixed>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>

        {todoDetails && todoDetails.map(page => {
          if(!page || page.visible === false) {
            return null;
          }
          // page.questions = page.questions.map((q) => {
          //   q.index = ++questionCount;
          //   return q;
          // })
          return (
            <View style={{ marginTop: "15px" }}>
              <View style={{ borderBottom: "1px solid #ccc" }}>
                <Text style={{fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
                  {decode(page.page_title)}
                </Text>
              </View>
              {page.questions && page.questions.length > 0 &&
                <View style={styles.tableContainer}>
                  <QuestionsTable
                    items={page.questions}
                  />
                </View>
              }
            </View>
          )
        })}
        

        {/* Disclaimer */}
        <View style={styles.disclaimer} fixed>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const QuestionsTable = ({ items }) => {
  const rows = items.map((question, i) => {
    if(!question || question.visible === false) {
      return null;
    }
    return (
      <View style={[tableStyles.row]} key={i} wrap={false}>
        <View style={[tableStyles.cell, { width: "40%" }]}>
          <View style={[tableStyles.row, { borderBottom: "none" }]} key={i}>
            <Text
              style={[tableStyles.cell, { 
                width: "15px",
                height: "15px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Helvetica-Bold",
                marginRight: "10px",
              }]}
            >
              {`${question.sorting}.`}
            </Text>
            {question.other_column_data && question.other_column_data.length > 0 &&
              question.other_column_data.map((option, i) => {
                return (
                  <Text
                    style={[tableStyles.cell, { 
                      width: "15px",
                      height: "15px",
                      borderBottom: "none",
                      border: `${option == question.answer ? "2px solid red" : "none"}`,
                      borderRadius: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }]}
                  >
                    {i}
                  </Text>
                )
              })
            }
          </View>
        </View>
        <Text style={[tableStyles.cell, { width: "60%" }]}>{decode(question.question)}</Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      {/* <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "33%", }]}>Question</Text>
        <Text style={[tableStyles.headerCell, { width: "33%", }]}>Answer Score</Text>
        <Text style={[tableStyles.headerCell, { width: "33%", }]}>Answer</Text>
      </View> */}
      {rows}
    </View>
  )
};