import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { showDateInUtc } from "helpers/common";
import { TIME_FORMAT_12, TIME_FORMAT_24 } from "pages/SideTabs/MyLog/Components/FoodMoodJournalForm/Component/FMJournalStructure";

export const fmFields = {
  foods: "Foods",
  drinks: "Drinks",
  supplements: "Supps, Herbs & Meds",
  feeling: "Energy & Mood",
  movement: "Movement & Relaxation",
  digestion: "Digestion & Reactions"
}

const FoodMoodJournalData = ({ dispatch, loading, journalData, todoId, getDetails }) => {

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="CardBody my-2">
          {journalData && journalData.length > 0 && (
            journalData.map(data => {
              return (
                <>
                  <div className="py-2">
                    <div className="top d-flex align-items-center justify-content-between gap-10">
                      <div className="left d-flex align-items-start gap-10 flex-wrap">
                        <h6 className="m-0 text-uppercase">
                          {showDateInUtc(data.log_date, "dddd, MMM DD YYYY")}
                        </h6>
                        <h6 className="m-0 text-uppercase">
                          <span>
                            Wake Time: {moment(data.wake_time, TIME_FORMAT_24).format(TIME_FORMAT_12)}
                          </span>{" "}
                          <span>
                            Sleep Time: {moment(data.sleep_time, TIME_FORMAT_24).format(TIME_FORMAT_12)}
                          </span>
                        </h6>
                      </div>
                      <div className="right">
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="commonCardBox">
                      <div className="table-responsive border-0 fm-data-table">
                        <table className="table borderedTable">
                          <thead>
                            <tr>
                              <th width="10%" className="p-3 text-uppercase text-dark">
                                Meal Time
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Foods
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Drinks
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Supps, Herbs & Meds
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Energy & Mood
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Movement & Relaxation
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Digestion & Reactions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.food_served && data?.food_served?.length > 0 && (
                              data.food_served.map((timeRow, index) => {
                                return (
                                  <tr>
                                    <td className="p-3 ws-normal">
                                      <p className="m-0 text-dark fw-sbold">
                                        Start: <br />
                                        {timeRow?.start_time}
                                      </p>
                                      <p className="m-0 text-dark fw-sbold">
                                        End: <br/>
                                        {timeRow?.end_time}
                                      </p>
                                    </td>
                                    {fmFields && Object.keys(fmFields).map((fieldKey, fieldIndex) => {
                                      return (
                                        <td
                                          className="p-3 ws-normal"
                                          style={{ background: `${timeRow[fieldKey]?.value ? "#d6e4d6" : "#fff"}` }}
                                        >
                                          <div className="content py-3 fm-data-cell">
                                            {timeRow[fieldKey]?.value &&
                                              <span
                                                className="tick position-absolute icn"
                                                style={{ top: "0px", right: "0px" }}
                                              >
                                                <CheckMarkIcon />
                                              </span>
                                            }
                                            <p className="m-0 py-1">
                                              {timeRow[fieldKey]?.value}
                                            </p>
                                            
                                          </div>
                                        </td>
                                      )
                                    })}


                                    {/* <td className="p-3 ws-normal">
                                      <div className="content py-3 h-100 position-relative">
                                        <p className="m-0 py-1 ">Drinks</p>

                                        <div
                                          className=" poistion-absolute "
                                          style={{ bottom: "0", left: "0" }}
                                        >
                                          <span className="icn">
                                            <Link
                                              onClick={() => {
                                                openNotesPopup({
                                                  type: "food_served_notes",
                                                  log_id: data.id,
                                                  rowIndex: index,
                                                  fieldKey: "foods",
                                                  notes: timeRow.foods.notes,
                                                  value: timeRow.foods.value,
                                                });
                                              }}
                                            >
                                              <NoteIcon />
                                            </Link>
                                          </span>
                                        </div>
                                      </div>
                                    </td> */}
                                  </tr>
                                )
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          )}
        </div>
      )}
    </>
  );
};

const CheckMarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <g clip-path="url(#clip0_0_6718)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.89502 0.461914C4.48252 0.461914 0.89502 4.04941 0.89502 8.46191C0.89502 12.8744 4.48252 16.4619 8.89502 16.4619C13.3075 16.4619 16.895 12.8744 16.895 8.46191C16.895 4.04941 13.3075 0.461914 8.89502 0.461914Z"
          fill="#4BAE4F"
        />
        <path
          d="M7.35439 12.1212C6.33252 11.0993 5.31689 10.0681 4.29189 9.04619C4.17939 8.93369 4.17939 8.74619 4.29189 8.63369L5.47002 7.45557C5.58252 7.34307 5.77002 7.34307 5.88252 7.45557L7.56689 9.13994L11.8981 4.80557C12.0138 4.69307 12.1981 4.69307 12.3138 4.80557L13.495 5.98682C13.6106 6.10244 13.6106 6.28682 13.495 6.39932L7.76689 12.1212C7.65439 12.2368 7.47002 12.2368 7.35439 12.1212Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_6718">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.89502 0.461914)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FoodMoodJournalData);