export const EDIT_MEAL_LOG = "EDIT_MEAL_LOG";
export const EDIT_FEELING_LOG = "EDIT_FEELING_LOG";

export function editMealLog(data) {
  return {
    type: EDIT_MEAL_LOG,
    payload: data,
  }
}

export function editFeelingLog(data) {
  return {
    type: EDIT_FEELING_LOG,
    payload: data,
  }
}
