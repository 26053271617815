import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import moment from "moment/moment";
import momentTZ from "moment-timezone";

// icn
import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";

import { currentDayFromMonth_, utcTimestamp_, currentDayFromMonth, bookingdate, bookingdatetime, convertTimezone, isDateValid, getAppointmentDateTime, convertLocalDateToUTC, getMonthStartAndEndDates } from 'helpers/common'
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";
import { cancelAppointment_, getAppointmentSlots, getApptBookingDetails, getPractSchedulerSettings, rescheduleAppointment_ } from "store/services/publicAppointmentService";
import * as Path from "Routes/paths";
import { CALENDAR_ERROR } from "common/constants";
import MyCalendar from "components/shared/MyCalendar";

const ViewAppointment = ({ dispatch, reschedulePop, setReschedulePop, setShowPopup, accountList }) => {
  const [error, setError] = useState(null);
  const [currentDetail, setCurrentDetail] = useState(null);
  const [currentTypeData, setCurrentTypeData] = useState(null);

  const [stepRes, setStepRes] = useState(1);
  const [timeZoneValue, setTimeZoneValue] = useState(moment.tz.guess())
  const localizer = momentLocalizer(moment);
  const [selectedDay_, setSelectedDay_] = useState(null);
  const [currentRescheduledSLot, setCurrentRescheduledSLot] = useState(null);

  const [availableTimes, setAvailableTimes] = useState(null);

  const [selectedDate, setSelectedDate] = useState(new Date()); // State to track the selected date
  const [stateSlotsVal, setStateSlotsVal] = useState([])
  const [showButton, setShowButton] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false);
  const [schedulerSettings, setSchedulerSettings] = useState(null);
  const [calendarError, setCalendarError] = useState(null);

  // appointment booking id
  const { uuid } = useParams();

  useEffect(() => {
    if (uuid) {
      getAppointmentDetails(uuid);
    }
  }, [])

  useEffect(() => {
    if(stepRes !== 3 || !currentTypeData) {
      return;
    }
    getPractitionerAvailability(currentTypeData, selectedDate);
  }, [stepRes, currentTypeData])

  const checkIfDateValid = (date) => {
    return isDateValid(date, schedulerSettings);
  }

  const handleSelectEvent = event => {
    // Handle event selection if needed
    console.log('Event selected:', event);
  };

  const handleNavigate = date => {
    getPractitionerAvailability(currentTypeData, date);
  };
  const handleSelectSlot = (slotInfo) => {
    const newSelectedDate = moment(slotInfo.start).toDate();
    if (!checkIfDateValid(newSelectedDate)) {
      setCalendarError(CALENDAR_ERROR);
      return;
    }
    setSelectedDate(newSelectedDate);
    setCalendarError(null);
  };

  useEffect(() => {
    if(!selectedDate || !availableTimes?.days?.length) {
      return;
    }
    const parsedDate = moment(selectedDate).format("YYYY-MM-DD");
    const findAvailability = availableTimes?.days.find(row => row.date === parsedDate);
    if(!findAvailability || !findAvailability?.available || !findAvailability?.spots?.length) {
      setStateSlotsVal([]);
      return;
    }
    setStateSlotsVal(findAvailability?.spots);
  }, [selectedDate, availableTimes])

  useEffect(() => {
    if (currentDetail) {
      // setSelectedDate(currentDetail?.booking_date);
      getSchedulerSettings();
    }
  }, [currentDetail])

  const getAppointmentDetails = async (apptId) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await getApptBookingDetails(apptId);
      if (res?.success === true) {
        setCurrentDetail(res?.body);
        setCurrentTypeData(res?.body);
      } else {
        setError("Failed to fetch appointment details");
      }
      dispatch(actions.persist_store({ loader: false }));

    } catch (err) {
      console.log(err);
      setError("Failed to fetch appointment details");
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const onClickAppointType = async (data, day) => {
    try {
      setCurrentTypeData(data);
      let finalObj = {
        calendarId: data.calender_id,
        appointmentTypeId: data?.appointment_type,
        values: {
          day: day,
          // booking_date: convertLocalDateToUTC(selectedDate, "YYYY-MM-DD"),
          booking_date: moment(selectedDate).format("YYYY-MM-DD"),
          client_appt_tz: timeZoneValue
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      // setButtonDisable(true)
      let res = await getAppointmentSlots(finalObj);
      dispatch(actions.persist_store({ loader: false }));
      setStateSlotsVal(res?.body?.slots)

    } catch (err) {
      console.log(err);
      // setButtonDisable(false)
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const getPractitionerAvailability = async (apptType, selectedDate) => {
    try {
      const range = getMonthStartAndEndDates(selectedDate);
      if(!range) {
        throw "Month range not valid";
      }
      
      let finalObj = {
        calendarId: apptType.calender_id,
        appointmentTypeId: apptType?.appointment_type,
        values: {
          start_date: range?.start,
          end_date: range?.end,
          client_appt_tz: timeZoneValue,
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      let res = await getAppointmentSlots(finalObj);
      dispatch(actions.persist_store({ loader: false }));
      setAvailableTimes(res?.body);
    } catch(err) {
      console.error("Error fetching availability", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }


  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
      width: "100%",
    };
    return {
      style,
    };
  };
  const Trips = [
    {
      title: "Trip to Paris",
      start: new Date(2023, 6, 15), // July 15, 2023
      end: new Date(2023, 6, 20), // July 20, 2023
    },
    {
      title: "Beach Vacation",
      start: new Date(2023, 7, 5), // August 5, 2023
      end: new Date(2023, 7, 12), // August 12, 2023
    },
    // Add more trips as needed
  ];
  const handleReschedulePop = () => {
    // setReschedulePop(false);
    setStepRes(1);
    // setCurrentDetail(null);
    // setCurrentTypeData(null);
  }

  const getSchedulerSettings = async () => {
    try {
      let response = await getPractSchedulerSettings(currentDetail?.calender_id);
      if (response.success === true && response.body) {
        setSchedulerSettings(response.body)
      }
    } catch (err) {
      console.log("Error fetching pracitioner details", err);
    }
  }

  const cancelAppointment = async () => {
    try {
      await CONFIRM_POPUP("Are you sure you want to cancel this appointment?")
        .then(async (isConfirmed) => {
          if (isConfirmed) {
            setIsCancelling(true);
            dispatch(actions.persist_store({ loader: true }));
            let res = await cancelAppointment_(currentDetail.bookingId);
            if (res.success === true) {
              SUCCESS_MESSAGE("Appointment cancelled");
              await getAppointmentDetails(currentDetail?.bookingId);
              cancelAppointmentPop_();
            }
            setIsCancelling(false);
            dispatch(actions.persist_store({ loader: false }));
          }
        }).catch(err => {
          setIsCancelling(false);
          dispatch(actions.persist_store({ loader: false }));
          throw err
        })

    } catch (err) {
      console.log("Error cancelling the appointment", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const cancelAppointmentPop_ = () => {
    // setReschedulePop(false);
    setStepRes(1);
  }
  const cancelRes_ = () => {
    setStepRes(1);
  }

  const slotselctForBooking = (slot) => {
    setCurrentRescheduledSLot(slot);
    setStepRes(2)
    // setShowPopup(false)
    setReschedulePop(true)
  }

  const confirmResAppointment = async () => {
    try {
      let finalObj = {
        booking_date: moment().format("YYYY-MM-DD"),
        start_time: getAppointmentDateTime(currentRescheduledSLot.date, currentRescheduledSLot.start_time, timeZoneValue),
        end_time: getAppointmentDateTime(currentRescheduledSLot.date, currentRescheduledSLot.end_time, timeZoneValue, true, currentRescheduledSLot.start_time),
      }
      dispatch(actions.persist_store({ loader: true }));
      setShowButton(true)
      let res = await rescheduleAppointment_(currentDetail?.bookingId, finalObj);
      await getAppointmentDetails(currentDetail?.bookingId);
      dispatch(actions.persist_store({ loader: false }));
      setShowButton(false)
      setStepRes(4)
    } catch (err) {
      console.log(err);
      setShowButton(false)
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const checkIfValidDay = (date) => {
    try {
      let isValid = checkIfDateValid(date);
      if (!isValid) {
        return {
          className: "blocked-day"
        }
      }

    } catch (err) {
      // console.log("Error validating date", err);
    }
  }

  if (!currentDetail && !error) {
    return "";
  }

  return (
    <>
      {error && (
        <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
          <div className="top py-2 bg-white ">
            <p className="m-0 py-2">
              {error}
            </p>
          </div>
        </div>
      )}
      {currentDetail && (
        <>
          {stepRes === 1 &&
            <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
              <div className="top py-2 border-bottom bg-white ">
                <h2 className="m-0">
                  Your Appointment
                </h2>
                <p className="m-0 py-2">
                  Edit your previously booked appointment
                </p>
              </div>
              <div className="CardBody py-3">
                <div className="py-2">
                  <div
                    className="commonCardBox p-3"
                    style={{ background: "#f6f6f6" }}
                  >
                    {currentDetail?.status === "archived" && (
                      <>
                        {currentDetail?.payment_status === "pending" ? (
                          <p className="m-0">
                            Your payment for this appointment is currently being processed.
                          </p>
                        ) : (
                          <p className="m-0">
                            This appointment was canceled.{` `}
                            <Link
                              to={Path.appointmentForm.replace(":id", currentDetail.calender_id)}
                            >
                              Find a New Appointment
                            </Link>
                          </p>
                        )
                      }
                      </>
                    )}
                    <div className="top py-2">
                      <h4 className="m-0 fw-sbold">{currentDetail?.title}</h4>
                      <span className="text-muted m-0">
                        (duration : {currentDetail?.duration} minutes)
                      </span>
                    </div>

                    <div className="content">

                      <div className="py-2">
                        <h6 className="m-0">{moment(currentDetail?.start_time).format("hh:mm A")} to {moment(currentDetail?.end_time).format("hh:mm A")} on {currentDayFromMonth_(currentDetail?.start_time, timeZoneValue)}</h6>
                      </div>

                      {currentTypeData?.location_notes && (
                        <div className="py-2">
                          <p className="m-0">
                            <strong>
                              {currentTypeData?.location === 'by_phone'
                                ? 'By Phone' : currentTypeData?.location === 'in_person'
                                  ? 'In Person' : currentTypeData?.location === 'video_zoom'
                                    ? 'Video/Zoom' : ''
                              }{` `}
                              Instructions
                            </strong>
                          </p>
                          <p className="m-0">{currentTypeData?.location_notes}</p>
                        </div>
                      )}

                      {(currentTypeData?.location === "video_zoom" && currentTypeData?.video_url) && (
                        <div className="py-2">
                          <p>
                            Video Link:{" "}
                            <a
                              href={currentTypeData?.video_url}
                              target="_blank"
                            >
                              {currentTypeData?.video_url}
                            </a>
                          </p>
                        </div>
                      )}
                      
                      {currentTypeData?.additonal_notes && (
                        <div className="py-2">
                          <p className="m-0">
                            <strong>Additional Notes</strong>
                          </p>
                          <p className="m-0">{currentTypeData?.additonal_notes}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {currentDetail?.status !== "archived" && (
                  <>
                    <h6 className="m-0 fw-sbold">Need to reschedule or cancel ?</h6>
                    <div className="top py-2">
                      <Link onClick={() => setStepRes(3)}>
                        <span className="text m-0">
                          Reschedule Appointment
                        </span>
                      </Link><br />
                      <Link onClick={() => cancelAppointment()}>
                        <span className="text m-0">
                          {isCancelling ? "Please wait..." : "Cancel Appointment"}
                        </span>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          }
          {stepRes === 2 &&
            <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
              <div className="top py-2 border-bottom bg-white ">
                <h3 className="m-0">
                  Confirm reschedule appointment.
                </h3>
                <p className="m-0 py-2">
                  <Link onClick={() => cancelRes_()}>Cancel Rescheduling</Link>
                </p>
              </div>
              <div className="CardBody py-3">
                <div className="py-2">
                  <div
                    className="commonCardBox p-3"
                    style={{ background: "#f6f6f6" }}
                  >
                    <div className="top py-2">
                      <h4 className="m-0 fw-sbold">{currentDetail?.title}</h4>
                      <span className="text-muted m-0">
                        (duration : {currentDetail?.duration} minutes)
                      </span>
                    </div>
                    <div className="py-2">
                      <h6 className="m-0">{(currentRescheduledSLot?.start_time)} to {(currentRescheduledSLot?.end_time)} on {currentDayFromMonth_(currentRescheduledSLot?.date, timeZoneValue)}</h6>
                    </div>
                    <br />
                    <h6 className="m-0 fw-sbold">What's Changing:</h6>
                    <div className="top py-2">
                      <span className="text m-0">
                        <Link to="#">Old Appointment</Link>: {moment(currentDetail?.start_time).format("hh:mm A")} to {moment(currentDetail?.end_time).format("hh:mm A")} on {currentDayFromMonth_(currentDetail?.start_time, timeZoneValue)}
                      </span><br />
                      <span className="text m-0">
                        <Link to="#">New Appointment</Link>: {(currentRescheduledSLot?.start_time)} to {(currentRescheduledSLot?.end_time)} on {currentDayFromMonth_(currentRescheduledSLot?.date)}
                      </span>
                      <br />
                    </div>
                    <br />
                    <span className="text-muted m-0">
                      Please confirm that the updated data and time is correct. Once you click'Confirm Rescheduled Appointment' your old appointment will be canceled and rescheduled to the updated date and time above.
                    </span>
                  </div>
                </div>
                <div className="d-flex gap-10">
                  <Button
                    className="d-flex align-items-center justify-content-center btnSm btn-danger"
                    onClick={() => cancelAppointmentPop_()}
                  >
                    Cancel Rescheduling
                  </Button>
                  <Button
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    onClick={() => confirmResAppointment()}
                    disabled={showButton ? true : false}
                  >
                    {showButton ? 'Wait...' : 'Confirm Rescheduled Appointment'}
                  </Button>
                </div>

              </div>
            </div>
          }
          {stepRes === 3 &&
            <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
              <div className="top py-2 border-bottom bg-white ">
                <h2 className="m-0">Reschedule your Appointment</h2>
                <p className="m-0">
                  Select a highlighted date in the calendar to view
                  available times below.
                </p>
                <p className="m-0">
                  All dates/times are in <b> {timeZoneValue}</b>{" "}
                  time zone.
                </p>
                <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                  <li className="">
                    <Button
                      onClick={() => cancelAppointmentPop_()}
                      className="themeLink fw-sbold border-0 p-0"
                      variant="transparent"
                    >
                      Cancel Rescheduling
                    </Button>
                  </li>
                </ul>
              </div>
              <div className="py-2">
                <h6 className="m-0">{currentDetail?.title}</h6>
              </div>
              <div className="py-2">
                <h6 className="m-0">Duration : {currentDetail?.duration} minutes</h6>
              </div>
              {currentDetail?.is_paid_appointment === 'yes' &&
                <div className="py-2">
                  <h6 className="m-0">Price : {currentDetail?.currency_sign}{currentDetail?.price}</h6>
                </div>
              }
              <div className="CardBody py-3">
                <Row>
                  <Col lg="12" className="my-2">
                    <MyCalendar
                      localizer={localizer}
                      events={Trips}
                      eventPropGetter={eventStyleGetter}
                      startAccessor="start"
                      endAccessor="end"
                      onSelectEvent={handleSelectEvent}
                      // defaultDate={selectedDate}
                      views={['month']}
                      onNavigate={handleNavigate}
                      style={{ height: 500 }}
                      selectable
                      selected={selectedDate}
                      onSelectSlot={handleSelectSlot}
                      dayPropGetter={checkIfValidDay}
                      longPressThreshold={1}
                    />
                    {calendarError &&
                      <p className="invalid-feedback d-block">{calendarError}</p>
                    }
                  </Col>
                  {selectedDate && (
                    <Col lg="12" className="my-2">
                      <div className="commonContent">
                        <h4 className="m-0 py-2">
                          Open Times on {currentDayFromMonth_(selectedDate)}
                        </h4>
                        {timeZoneValue && (
                          <p>
                            All dates/times are in <strong>{timeZoneValue}</strong> time zone.
                          </p>
                        )}
                        <ul className="list-unstyled ps-0 mb-0 py-2">
                        {stateSlotsVal.length > 0 ? (
                          <>
                            {stateSlotsVal.map((slot, i) => {
                              return (
                                <li className="py-1" key={i}>
                                  <div
                                    className="commonCardBox p-2 d-flex align-items-center justify-content-between gap-10 flex-wrap"
                                    style={{ background: "#f6f6f6" }}
                                  >
                                    <h6 className="m-0 py-2">
                                      {slot.start_time} to {slot.end_time} on {currentDayFromMonth(slot.date)}
                                    </h6>
                                    <Button
                                      onClick={() => slotselctForBooking(slot)}
                                      className="d-flex align-items-center justify-content-center commonBtn btnSm px-4"
                                      disabled={slot?.is_booked}
                                    >
                                      Select
                                    </Button>
                                  </div>
                                </li>
                              )
                            })}
                          </>
                        ) : (
                          <p className="m-0">No slots found, please choose another date/time.</p>
                        )}
                        </ul>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          }
          {stepRes === 4 &&
            <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
              <div className="top py-2 border-bottom bg-white ">
                <h3 className="m-0">
                  Appointment Rescheduled!
                </h3>
              </div>
              <div className="CardBody py-3">
                <div className="py-2">
                  <span className="text-muted m-0">
                    Your appointment has been rescheduled. We have also sent you an email confirmations as well.
                  </span>
                  <div
                    className="commonCardBox p-3"
                    style={{ background: "#f6f6f6" }}
                  >
                    <div className="top py-2">
                      <h4 className="m-0 fw-sbold">{currentDetail?.title}</h4>
                      <span className="text-muted m-0">
                        (duration : {currentDetail?.duration} minutes)
                      </span>
                    </div>
                    <div className="py-2">
                      <h6 className="m-0">{moment(currentDetail?.start_time).format("hh:mm A")} to {moment(currentDetail?.end_time).format("hh:mm A")} on {currentDayFromMonth(currentRescheduledSLot?.date)}</h6>
                    </div>
                    <br />
                    <span className="text-muted m-0">
                      Everything is complete. Your confirmation code is {currentDetail.bookingId}. <br />
                      Please click the 'Close' button to close this window.
                    </span>
                  </div>
                </div>
                <div className="py-2">
                  <div className="btnWrp mt-2 FormbtnWrpper">
                    <Button
                      onClick={() => handleReschedulePop()}
                      className="d-flex align-items-center justify-content-center commonBtn "
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    accountList: state?.AccountSetting?.accountSettingList,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ViewAppointment);
