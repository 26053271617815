import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

import Form from './Form'
import { accpetPolicy_ } from 'store/services/profileService';
import { viewContentPageData } from 'store/services/contentPageService';
import * as Path from 'Routes/paths';
import * as actions from 'store/actions';
import { logout } from "store/services/authService";

const TermsCondition = ({ dispatch, submitting, user, pageDetail }) => {
  const navigate = useNavigate();
  const initialValues = {
    terms_acceptance: user && user.terms_accepted === 'yes' ? true : "",
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (user && user?.terms_accepted === "yes") {
      navigate(Path.dashboard);
    }
  }, [user]);

  //submit
  const submitData_ = async (values, { setSubmitting }) => {
    try {
      values.terms_acceptance = values.terms_acceptance ? 'yes' : 'no'
      values = JSON.stringify(values)
      dispatch(actions.persist_store({ loader: true }));
      setSubmitting(true);
      let response = await dispatch(accpetPolicy_(values));
      // if(response?.body?.terms_accepted === 'yes' && response?.body?.is_free_account==='no'){
      //   navigate(Path.dashboardSubscription)
      // }
      // if(response?.body?.is_free_account==='yes'){
      navigate(Path.dashboard)
      // }
      dispatch(actions.persist_store({ loader: false }));
      setSubmitting(false);
    } catch (err) {
      // console.log(err,  "==============================");
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const handleLogout = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      const authToken = user ? user?.access_token : "";
      await dispatch(logout(authToken)).then((res) => {
        navigate("/login");
        dispatch(actions.persist_store({ loader: false }));
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  //get data
  async function getData() {
    try {
      let slug = {
        "slug": 'terms_conditions'
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(viewContentPageData(slug));
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  return (
    <>
      <section className="Subscription position-relative">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              {pageDetail && pageDetail?.title && (
                <div className="commonContent">
                  <div className="py-2">
                    <h3 className="m-0 fw-bold py-1">
                      {pageDetail.title}
                    </h3>
                  </div>
                  <div className="py-2">
                    <p dangerouslySetInnerHTML={{ __html: pageDetail?.description }} />
                  </div>
                  <Form
                    initialValues={initialValues}
                    submitting={submitting}
                    updateData_={submitData_}
                    handleLogout={handleLogout}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    user: state.Profile.user,
    pageDetail: state.ContentPage.page,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TermsCondition);
