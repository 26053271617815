//action types
export const PROFILE = 'PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const ACCEPT_POLICY = 'ACCEPT_POLICY';
export const ACTIVE_PLAN_STATUS = 'ACTIVE_PLAN_STATUS';

//get profile
export function getProfile(data, callback) {
  return {
    type: PROFILE,
    payload : {data, callback},
  };
}
//update profile
export function updateProfile(data, callback) {
    return {
      type: UPDATE_PROFILE,
      payload : {data, callback},
    };
  }
  
//terms condition accept
export function accpetPolicy(data, callback) {
  return {
    type: ACCEPT_POLICY,
    payload : {data, callback},
  };
}


//active plan api
export function activePlanStatus(data, callback) {
  return {
    type: ACTIVE_PLAN_STATUS,
    payload : {data, callback},
  };
}



