import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { ACTION_PLANS_DETAILS, ACTION_PLANS_LIST, MAIN_PATH  } from './apiPath'
import { getClientActionPlansList } from 'store/actions/actionPlans';

// get recommendations list
export function getActionPlansList(callback) {
  let url = `${MAIN_PATH}${ACTION_PLANS_LIST}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getClientActionPlansList(data, callback));
      return data;
    });
}

export function getActionPlanDetails(id, callback) {
  let url = `${MAIN_PATH}${ACTION_PLANS_DETAILS}${id}`;
  return getData(url)
}
