import { PDFDownloadLink, Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { Fragment, useState } from 'react';
import { decode } from 'html-entities';
import { FULL_NAME, showDateInUtc } from 'helpers/common';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import { viewFoodMoodSubmittedData } from 'store/services/foodMoodJournalService';
import { transformFMJData } from "helpers/dataFormat";
import {Table, TR, TH, TD} from '@ag-media/react-pdf-table';
import { TIME_FORMAT_12, TIME_FORMAT_24 } from "pages/SideTabs/MyLog/Components/FoodMoodJournalForm/Component/FMJournalStructure";


export const fmFields = {
  foods: "Foods",
  drinks: "Drinks",
  supplements: "Supps, Herbs & Meds",
  feeling: "Energy & Mood",
  movement: "Movement & Relaxation",
  digestion: "Digestion & Reactions"
}

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "5px",
    paddingBottom: "15px"
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  foodLogTableDivider: {
    borderBottom: "4px solid #08415c",
    margin: "20px 0",
  }
});

const tableStyles = StyleSheet.create({
  table: {
    paddingTop: "5px",
    display: "table",
    width: "auto",
  },
  row: {
    borderBottom: "1px solid #ccc",
  },
  headerCell: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    padding: "5px",
    border: "none",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "10px",
    padding: "5px",
    border: "none"
  },
  notes: {
    paddingTop: "2px",
    color: "#777",
  },
});

const FoodMoodJournalPdf = ({ client, date, todoId, label, showNotes, showLoader, hideLoader }) => {

  const getFoodMoodJournalData = async (todoId) => {
    try {
      const response = await viewFoodMoodSubmittedData(todoId);
      if (response.success === true) {
        const data = transformFMJData(response.body);
        return data;
      }

      return false;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const downloadPDFReport = async () => {
    try {
      showLoader();
      const journalDetails = await getFoodMoodJournalData(todoId, client);
      const doc = <MyDoc
        client={client}
        date={date}
        journalDetails={journalDetails}
        showNotes={showNotes}
      />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'Food & Mood Journal.pdf');
      hideLoader();
    } catch (err) {
      console.log("Error downloading report", err);
      hideLoader();
    }
  }

  return (
    <Button
      className="d-flex align-items-center justify-content-center commonBtn btnSm"
      onClick={downloadPDFReport}
    >
      <span className="icn me-2">
        <DownloadIcn />
      </span>{" "}
      {label}
    </Button>
  )
}

export const MyDoc = ({ client, date, journalDetails, showNotes }) => {
  return (
    <Document>
      <Page style={styles.page} orientation="landscape">

        <View style={styles.pageHeader} fixed>
          <Text style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Food & Mood Journal
          </Text>
          <Text style={{ fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c" }}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName} fixed>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>

        {journalDetails && journalDetails.length > 0 &&
          journalDetails.map((data, i) => {
            return (
              <View key={i} style={{ marginTop: "30px" }}>
                <View style={{ display: "flex", flexDirection: "row", paddingBottom: "5px" }}>
                  <Text style={{ width: "25%", fontWeight: "bold", fontFamily: "Helvetica-Bold", fontSize: "12px" }}>
                    {showDateInUtc(data.log_date)}
                  </Text>
                  <View style={{ width: "25%", display: "flex", flexDirection: "row", gap: "5px" }}>
                    <Text>
                      Wake Time:
                    </Text>
                    <Text style={{ width: "30%", borderBottom: "1px solid #000" }}>
                      {moment(data.wake_time, TIME_FORMAT_24).format(TIME_FORMAT_12)}
                    </Text>
                  </View>
                  <View style={{ width: "25%", display: "flex", flexDirection: "row", gap: "5px" }}>
                    <Text>
                      Sleep Time:
                    </Text>
                    <Text style={{ width: "30%", borderBottom: "1px solid #000" }}>
                      {moment(data.sleep_time, TIME_FORMAT_24).format(TIME_FORMAT_12)}
                    </Text>
                  </View>
                </View>
                <FoodMoodTable
                  itemKey={`table_${i}`}
                  foodServed={data.food_served}
                  additionalNotes={data.additional_notes}
                  showNotes={showNotes}
                />
              </View>
            )
          })
        }

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner.
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const FoodMoodTable = ({ foodServed, additionalNotes, showNotes, itemKey }) => {
  const rows = foodServed?.map((timeRow, idx) => {
    return (
      <TR style={tableStyles.row}>
        <TD style={tableStyles.cell}>
          <Text style={{ flexDirection: "column" }}>
            <Text>Start: {timeRow.start_time} {`\n`}</Text>
            <Text>End: {timeRow.end_time}</Text>
          </Text>
        </TD>
        {fmFields && Object.keys(fmFields)?.map((fieldKey, fieldIndex) => {
          return (
            <TD key={fieldIndex} style={tableStyles.cell}>
              <Text>{timeRow[fieldKey].value}</Text>
            </TD>
          )
        })}
      </TR>
    )
  })

  return (
    <View style={{ border: "1px solid #ccc" }} key={`main_table_${itemKey}`}>
      <Table>
        <TH style={[tableStyles.row, {borderBottom: "2px solid #ccc"}]}>
            <TD style={tableStyles.headerCell}>Meal Time</TD>
            <TD style={tableStyles.headerCell}>Foods</TD>
            <TD style={tableStyles.headerCell}>Drinks</TD>
            <TD style={tableStyles.headerCell}>Supps, Herbs & Meds</TD>
            <TD style={tableStyles.headerCell}>Energy & Mood</TD>
            <TD style={tableStyles.headerCell}>Movement & Relaxation</TD>
            <TD style={tableStyles.headerCell}>Digestion & Reactions</TD>
        </TH>
        {rows}

      </Table>
    </View>
  )
};

export default FoodMoodJournalPdf;