import { GET_ACTION_PLAN_LIST } from 'store/actions/actionPlans';
import { GET_FULLSCRIPT_RECOMMENDATIONS, GET_RECOMMENDATION_LIST } from 'store/actions/recommendation';

const initialState = {
  data: [],
  loading: false,
};

//get 
const getActionPlansList = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  
  const stateObj = {
      ...state,
      data: data.body || [],
      loading: false,
  };
  return stateObj;
};


const ActionPlan = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_ACTION_PLAN_LIST:
          return getActionPlansList(state, payload);
        default:
        return state;
    };
}
export default ActionPlan;