import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { changePassword, updateEmail } from "store/services/authService";
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "common/constantMessage";

const UpdateEmail = ({ currentEmail, getProfile }) => {
  console.log("getProfile", getProfile)

  const handleSubmit = async (values, {setSubmitting}) => {
    try{
      setSubmitting(true);
      values = JSON.stringify(values)
      const response = await updateEmail(values);
      if(response.success == true) {
        SUCCESS_MESSAGE(response.message);
        await getProfile();
      }
      setSubmitting(false);
    } catch (err) {
        console.log("Error updating login email", err);
        setSubmitting(false);
    }
  }

  return (
    <>
      <div className="py-2">
        <p className="text-bold m-0 text-dark fw-bold text-uppercase">
          Update Log In Email
        </p>
        <p className="tex-muted m-0">
          Your current log in email is{" "}
          <Link to="" className="underline">
            {currentEmail}
          </Link>{" "}
          To update your log in email please enter in your email along
          with your current password.
        </p>
      </div>
      <div className="py-2">
      <Form_ onSubmit={handleSubmit} />
      </div>
    </>
  )
}

const Form_ = ({ onSubmit }) => {
  const validation = Yup.object({
    currentPassword: Yup.string().required("Password Required").min(8, "Password must be at least 8 characters long."),
    email: Yup.string().required("Email Required").email("Invalid email address")
  });
  return (
    <>
      <Formik
        initialValues={{
          currentPassword: "",
          email: "",
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
          <Row className="py-2">
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-sbold theme-clr m-0"
              >
                New Log In Email
              </label>
              <input
                type="email"
                className={`form-control ${errors.email && 'is-invalid'}`}
                name="email"
                onChange={handleChange}
                placeholder="New Log In Email Address"
                onBlur={handleBlur}
                value={values.email}
                autoComplete="off"
              />
              {(errors.email && touched.email) &&
                <p className="invalid-feedback">{errors.email}</p>
              }
            </Col>
            <Col lg="6" className="my-2">
              <label
                htmlFor=""
                className="form-label fw-sbold theme-clr m-0"
              >
                Current Password
              </label>
              <input
                type="password"
                className={`form-control ${errors.currentPassword && 'is-invalid'}`}
                name="currentPassword"
                onChange={handleChange}
                placeholder="Current Password"
                onBlur={handleBlur}
                value={values.currentPassword}
                autoComplete="off"
              />
              {(errors.currentPassword && touched.currentPassword) &&
                <p className="invalid-feedback">{errors.currentPassword}</p>
              }
            </Col>
            <Col lg="12" className="my-2 btnWrp">
              <Button
                type="submit"
                className="d-flex align-items-center justify-conten-center commonBtn btnSm"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Loading...' : 'Submit'}
              </Button>
            </Col>
          </Row>
        </Form>
        )}
      </Formik>
    </>
  )
}

export default UpdateEmail;