import React from "react";
import { Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik  } from 'formik';
//icn
import { ReactComponent as OTick } from "../../../Assets/Images/icn/OrangeTick.svg";


//imported
// import validation from "./validation";
import LoadingButton from "components/shared/LoadingButton"

const Form_ = ({initialValues, submitting, updateData_, loader, rememberMe, handleRememberMeChange, handleLogout}) => {
   return(
        <>
        <Formik
            initialValues={initialValues}
            enableReinitialize
            // validationSchema={validation}
            onSubmit={updateData_}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form onSubmit={handleSubmit}  autoComplete="off" >
                    <div className="py-2">
                        <div className="py-2 d-flex align-items-start gap-10">
                            <div className="cstmInputCheckWrp position-relative ">
                                <input
                                    type="checkbox"
                                    className="position-absolute w-100 h-100"
                                    name="terms_acceptance"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.terms_acceptance}
                                    required
                                />

                            <div className="cstmInputCheck h-100 w-100 d-flex align-items-center justify-content-center">
                                <span className="icn d-none">
                                <OTick />
                                </span>
                            </div>
                            </div>
                            <p className=" m-0 ">
                            I have read and agree to the{" "}
                            {/* <Link to="" className=" fw-sbold"> */}
                                Privacy Policy
                            {/* </Link> */}
                            </p>
                        </div>
                    </div>
                    <div className="FormbtnWrpper d-flex align-items-center gap-10 mt-5">
                    <LoadingButton
                        type="submit"
                        className="d-inline-flex align-items-center justify-content-center commonBtn btn text-white"
                        loading={loader}
                        disabled={submitting}
                    >
                    {isSubmitting ? 'wait...' : 'Submit'}
                    </LoadingButton>

                    <Button
                        className="d-inline-flex align-items-center justify-content-center commonBtn"
                        onClick={handleLogout}
                    >
                        Cancel
                    </Button>
                    
                    </div>
              </Form>
            )}
        </Formik>
        </>
    )
}

export default Form_;