import {AUTH_LOGIN, AUTH_REGISTER, AUTH_ACCEPT_INVITE, AUTH_CHECK, AUTH_LOGOUT, FORGOT_PASSWORD,RESET_PASSWORD_FORM, RESET_PASSWORD, UPDATE_PASSWORD, GET_PRACTITIONER_DETAILS, GET_AUTH_PROFILES, SET_AUTH_TOKEN, GET_PRACTITIONER_SETTINGS} from 'store/actions/auth';
import Http from 'helpers/Http';

const initialState = {
    isAuthenticated: false,
    user: null,
    authProfiles: null,
    practitionerDetails: null,
    practitionerSettings: null,
    loader:false,
};

const setToken = (state, payload) => {
  if (payload && payload.token) {
    const { token } = payload;
    // localStorage.setItem('access_token', token);
    Http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  return state;
}
const setTokenAndUser = (state, payload) => {
  if (payload && payload.user && payload.user.token) {
    const { user } = payload;
    localStorage.removeItem('signup_key');
    localStorage.setItem('access_token', user.token);
    localStorage.setItem('user', JSON.stringify(user));
    Http.defaults.headers.common.Authorization = `Bearer ${user.token}`;
    return {
      ...state,
      isAuthenticated: true,
      user: user,
      loader: true
    };
  } else {
    return state;
  }
};

//login 
const authLogin = (state, payload) => {
  const { user, callback } = payload;
  if (typeof callback === 'function') {
    callback(user);
  }
  return setTokenAndUser(state, payload);
};
//register
const authRegister = (state, payload) => {
  const { user, callback } = payload;
  if (typeof callback === 'function') {
    callback(user);
  }
  return setTokenAndUser(state, payload);
};

// accept invite
const acceptInvite = (state, payload) => {
  const { user, callback } = payload;
  if (typeof callback === 'function') {
    callback(user);
  }

  return setTokenAndUser(state, payload);
};

//check auth
const authCheck = (state) => {
  const storedToken = localStorage.getItem('access_token');
  const storedUser = localStorage.getItem('user');

  const newState = {
    ...state,
    isAuthenticated: !!storedToken,
    user: storedUser ? JSON.parse(storedUser) : null,
  };

  if (newState.isAuthenticated) {
    Http.defaults.headers.common.Authorization = `Bearer ${storedToken}`;
  }

  return newState;
};

//logout
const authLogout = (state) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    const newState = {
      ...state,
      isAuthenticated: false,
      user: null,
      loader: false
    };

  return newState;
};

//forgotPassword
const forgotPassword = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  return state;
};

//resetPasswordForm
const resetPasswordForm = (state, payload) => {
  return setTokenAndUser(state, payload);
};

//resetPassword
const resetPassword = (state, payload) => {
  return setTokenAndUser(state, payload);
};

//change password
const changePassword = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  return setTokenAndUser(state, payload);
};

// get practitioner details
const getPractitionerDetails = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }

  const newState = {
    ...state,
    practitionerDetails: data.body,
  };

  return newState;
};

// get practitioner settings
const getPractitionerSettings = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }

  const newState = {
    ...state,
    practitionerSettings: data.body,
  };

  return newState;
};

// multi auth profiles
const getAuthProfiles = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }

  const newState = {
    ...state,
    authProfiles: data.body,
  };

  return newState;
};


const Auth = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case AUTH_LOGIN:
          return authLogin(state, payload);
        case AUTH_REGISTER:
          return authRegister(state, payload);
        case AUTH_CHECK:
          return authCheck(state, payload);
        case AUTH_LOGOUT:
          return authLogout(state);
        case FORGOT_PASSWORD:
          return forgotPassword(state, payload);
        case RESET_PASSWORD_FORM:
          return resetPasswordForm(state);
        case RESET_PASSWORD:
          return resetPassword(state);
        case UPDATE_PASSWORD: 
          return changePassword(state, payload);
        case AUTH_ACCEPT_INVITE: 
          return acceptInvite(state, payload);
        case GET_PRACTITIONER_DETAILS: 
          return getPractitionerDetails(state, payload);
        case GET_PRACTITIONER_SETTINGS: 
          return getPractitionerSettings(state, payload);
        case GET_AUTH_PROFILES: 
          return getAuthProfiles(state, payload);
        case SET_AUTH_TOKEN:
          return setToken(state, payload);
        default:
        return state;
    };
}
export default Auth;