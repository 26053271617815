import React from "react";
import { Link } from "react-router-dom";

const SubscriptionInfo = () => {
  return (
    <>
      <div className="top py-2">
        <h4 className="m-0 py-1 fw-bold">Subscription Information</h4>
        <p className="m-0 py-1 text-muted">
          You must be cool since you have a free account! Paying users would see
          their billing information here.
        </p>
      </div>
      <div className="TabBody py-3"></div>
    </>
  );
};

export default SubscriptionInfo;
