import { postData, getData, putData, deleteData, getDataAuth } from 'helpers/apiHelper'
import { MAIN_PATH, ACCOUNT_SETTING_LIST, ADD_UPDATE_ACCOUNT_SETTING } from './apiPath'
import { getAccountSettingList_, addUpdateAccountSetting } from 'store/actions/accountSetting';


//get
export function getAccountSettingList(value, callback) {
  let url = `${MAIN_PATH}${ACCOUNT_SETTING_LIST}`;
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getAccountSettingList_(data, callback));
      return data;
    });
}

//add update account setting
export function addUpdateAccountSetting_(id, value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${ADD_UPDATE_ACCOUNT_SETTING}${id}`, value)
    .then((data) => {
      dispatch(addUpdateAccountSetting(data, callback));
      return data;
    });
}