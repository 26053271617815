import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { decode } from 'html-entities';
import { FULL_NAME, saveAs, UC_FIRST } from 'helpers/common';
import moment from 'moment';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  heading: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    color: "#08415c"
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "15px",
    marginBottom: "15px",
    borderBottom: "2px solid #ccc",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  potentialSuppContainer: {
    // flexDirection: "row",
    // padding: "10px",
    // backgroundColor: "#eee",
  },
  potentialSuppRow: {
    flexDirection: "row",
    marginBottom: "10px",
  },
  potentialSuppHeading: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    // color: "#08415c",
    fontSize: "13px",
    marginBottom: "3px",
  },
  potentialSuppText: {
    margin: "1px 0",
  },
  potentialSuppCol: {
    width: "50%",
    paddingRight: "20px",
  },
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "start",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "12px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "8%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "11px",
    width: "8%",
    padding: "5px",
    paddingBottom: "10px",
    paddingTop: "10px",
    flexWrap: "wrap",
    borderBottom: "1px solid #ccc",
  },
});

const NAQ2RecommendationReportDoc = ({ nutritionalRows, manufacturer, topPotentialSupports, client, date }) => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.page} wrap={false}>

        <View style={styles.pageHeader}>
          <Text style={[styles.heading, {fontSize: "18px", }]}>
            Client Recommendation Report
          </Text>
          <Text style={[styles.heading, {fontSize: "22px"}]}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>
        
        <View>
          <View style={{ borderBottom: "1px solid #ccc" }}>
            <Text style={[styles.heading, {fontSize: "14px"}]}>
              Recommendation
            </Text>
          </View>
          <Text style={{ paddingTop: "5px" }}>
            The following supplements are based on the potential conditions and nutritional deficiencies shown in the Symptom Burden Report.
          </Text>
          <View style={{ flexDirection: "row", paddingTop: "5px", gap: "5px", alignItems: "baseline" }}>
            <Text>
              Supplement Brand:
            </Text>
            <Text
              style={{fontSize: "12px", fontWeight: "bold", fontFamily: "Helvetica-Bold"}}
            >
              {UC_FIRST(manufacturer.replaceAll("_", " "))}
            </Text>
          </View>
        </View>
        
        {/* Nutritional recommendation details */}
        {nutritionalRows && nutritionalRows.length > 0 &&
          <View style={styles.tableContainer}>
            <NutriRecommTable items={nutritionalRows} />
          </View>
        }

        {/* Top potential support */}
        <View style={styles.potentialSuppContainer}>
          <View style={styles.potentialSuppRow}>
            <View>
              <Text style={[styles.heading, {fontSize: "14px"}]}>
                Top Potential Supports
              </Text>
              <Text style={{ paddingTop: "5px" }}>
                The below list of Potential Supports looks across your client's questions/answers
                from this NAQ and it shows the most frequently listed supports for those questions.
                Unlike the Recommendation report, this list is completely based off the individual
                questions and not the Potential Conditions/Deficiencies in the Symptom Burden Report.
              </Text>
            </View>
          </View>
          <View style={styles.potentialSuppRow}>
            <View style={styles.potentialSuppCol}>
              <Text style={styles.potentialSuppHeading}>Nutrients</Text>
              {topPotentialSupports && topPotentialSupports?.nutrients?.length > 0 && (
                <View style={{ paddingLeft: "5px" }}>
                  {
                    topPotentialSupports.nutrients.map(nutrient => {
                      return (
                        <Text style={styles.potentialSuppText}>
                          • {decode(nutrient.supportText)}
                        </Text>
                      )
                    })
                  }
                </View> 
              )}
            </View>
            <View style={styles.potentialSuppCol}>
              <Text style={styles.potentialSuppHeading}>Supplements</Text>
              {topPotentialSupports && topPotentialSupports?.supplements?.length > 0 && (
                <View style={{ paddingLeft: "5px" }}>
                  {
                    topPotentialSupports.supplements.map(supplement => {
                      return (
                        <Text style={styles.potentialSuppText}>
                          • {decode(supplement.supportText)}
                        </Text>
                      )
                    })
                  }
                </View> 
              )}
            </View>
          </View>
          <View style={styles.potentialSuppRow}>
            <View style={styles.potentialSuppCol}>
              <Text style={styles.potentialSuppHeading}>Foods</Text>
              {topPotentialSupports && topPotentialSupports?.foods?.length > 0 && (
                <View style={{ paddingLeft: "5px" }}>
                  {
                    topPotentialSupports.foods.map(food => {
                      return (
                        <Text style={styles.potentialSuppText}>
                          • {decode(food.supportText)}
                        </Text>
                      )
                    })
                  }
                </View> 
              )}
            </View>
            <View style={styles.potentialSuppCol}>
              <Text style={styles.potentialSuppHeading}>Lifestyle</Text>
              {topPotentialSupports && topPotentialSupports?.lifestyle?.length > 0 && (
                <View style={{ paddingLeft: "5px" }}>
                  {
                    topPotentialSupports.lifestyle.map(lifestyleIdea => {
                      return (
                        <Text style={styles.potentialSuppText}>
                          • {decode(lifestyleIdea.supportText)}
                        </Text>
                      )
                    })
                  }
                </View> 
              )}
            </View>
          </View>
        </View>

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const NutriRecommTable = ({ items }) => {
  const rows = items.map((row, i) => {
    return (
      <View style={[tableStyles.row, { backgroundColor: (i%2 == 0) ? "#fff" : "#eee" }]} key={i}>
        <Text style={[tableStyles.cell, { width: "20%" }]}>{decode(row.productName)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageUponRising)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageBreakfast)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageMidMorning)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageLunch)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageMidAfternoon)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageDinner)}</Text>
        <Text style={tableStyles.cell}>{decode(row.dosageBeforeBed)}</Text>
        <Text style={[tableStyles.cell, { width: "26%" }]}>{decode(row.instructions)}</Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "20%", }]}>Product</Text>
        <Text style={tableStyles.headerCell}>Upon Waking</Text>
        <Text style={tableStyles.headerCell}>With Breakfast</Text>
        <Text style={tableStyles.headerCell}>Midmorning</Text>
        <Text style={tableStyles.headerCell}>With Lunch</Text>
        <Text style={tableStyles.headerCell}>Midafternoon</Text>
        <Text style={tableStyles.headerCell}>With Dinner</Text>
        <Text style={tableStyles.headerCell}>Before Bed</Text>
        <Text style={[tableStyles.headerCell, { width: "26%" }]}>Benefit / Instructions / Notes</Text>
      </View>
      {rows}
    </View>
  )
};

export default NAQ2RecommendationReportDoc;