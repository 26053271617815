import { useState, useEffect, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';

const useFieldValidation = (value, schema) => {
  const [error, setError] = useState('');

  // Memoize the schema to avoid unnecessary re-creations
  const memoizedSchema = useMemo(() => schema, [schema]);

  // Debounced validation function to avoid rapid re-validation
  const validate = useCallback(
    debounce(async (valueToValidate) => {
      try {
        await memoizedSchema.validate(valueToValidate);
        setError('');
      } catch (validationError) {
        setError(validationError.message);
      }
    }, 100), // 300ms delay
    [memoizedSchema]
  );

  useEffect(() => {
    validate(value);

    // Cleanup function to cancel the debounce on unmount or before the next validation
    return () => {
      validate.cancel();
    };
  }, [value, validate]);

  return error;
};

export default useFieldValidation;