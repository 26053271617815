import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { GET_NAQV1_REPORTS_PUBLIC, MAIN_PATH, PUBLIC_SAVE_TODO_RESPONSE, PUBLIC_SUBMIT_TODO, PUBLIC_TODO_VIEW } from './apiPath'
import { viewToDo } from 'store/actions/todo';

//view
export function viewPublicToDoData(todoId, callback) {
  let url = `${MAIN_PATH}${PUBLIC_TODO_VIEW.replace(":id", todoId)}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(viewToDo(data, callback));
      return data;
    });
}

// save ToDo response
export function savePublicTodoResponse(data, callback) {
  let {id, formData} = data;
  return postData(`${MAIN_PATH}${PUBLIC_SAVE_TODO_RESPONSE.replace(":id", id)}`, formData)
}

// submit ToDo
export function submitPublicTodo(todoId) {
  let url = `${MAIN_PATH}${PUBLIC_SUBMIT_TODO.replace(":id", todoId)}`;
  // let url = `http://localhost:3000/dev/todo/public/${todoId}/submit`;
  return postData(url);
}

export function getNAQv1ReportsPublic(todoId) {
  let url = `${MAIN_PATH}${GET_NAQV1_REPORTS_PUBLIC}${todoId}`;
  // let url = `http://localhost:3000/dev/reports/public/naq1/${todoId}`;
  return getData(url);
}