const Height = ({ question }) => {
    // let answer = question.answer && JSON.parse(question.answer);
    let answer = question.answer;
    let unit = answer?.unit;
    let value = answer?.value;
    let ftVal = value && value[0];
    let inchesVal = value && value.slice(1, value?.length);
    console.log(value, ftVal, inchesVal);
    return (
      <>
        {unit == "ft_in" ? (
          <p className="m-0">
            {ftVal && `${ftVal} ft`}
            {inchesVal && ` ${inchesVal} in`}
          </p>
        ): unit == "cm" ? (
          <p className="m-0">
            {value && `${value} ${unit}`}
          </p>
        ):(
          ""
        )}
      </>
    )
}

export default Height;