import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik  } from 'formik';

//imported
import validation from "./validation";
import LoadingButton from "components/shared/LoadingButton"

const Form_ = ({
  initialValues,
  submitting,
  updateData_,
  loader,
  rememberMe,
  handleRememberMeChange
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

    return(
        <>
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validation}
            onSubmit={updateData_}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <Form onSubmit={handleSubmit}  autoComplete="off" >
                <Row>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                      Email*
                    </label>
                    <input
                        type="email"
                        className={`form-control ${errors.email && 'is-invalid'}`}
                        name="email"
                        onChange={handleChange}
                        placeholder="Email"
                        onBlur={handleBlur}
                        value={values.email}
                        autoComplete="off" 
                    />
                    {(errors.email && touched.email) &&
                      <p className="invalid-feedback">{errors.email}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                      Password*
                    </label>
                    <div style={{position: "relative"}}>
                      <input
                          type={`${showPassword ? "text" : "password"}`}
                          className={`form-control ${errors.password && 'is-invalid'}`}
                          name="password"
                          onChange={handleChange}
                          placeholder="********"
                          onBlur={handleBlur}
                          value={values.password}
                          autoComplete="off" 
                      />
                      {!errors.password &&
                        <span
                          onClick={togglePasswordVisibility}
                          className="password-eye-icon"
                        >
                          <i className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                        </span>
                      }
                    </div>
                    {(errors.password && touched.password) &&
                      <p className="invalid-feedback">{errors.password}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <div className="d-flex align-items-center justify-content-between gap-10">
                      <div className="left d-flex align-items-center gap-10">
                        <input
                            type="checkbox"
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                        />
                        <label htmlFor="" className="form-label m-0">
                          Remember me
                        </label>
                      </div>
                      <dir className="right">
                        <Link className="form-link fw-sbold" to="/forgot-password">
                          Forgot password ?
                        </Link>
                      </dir>
                    </div>
                  </Col>
                  <Col lg="12" className="my-2">
                    <LoadingButton
                        type="submit"
                        className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                        loading={loader}
                        disabled={submitting}
                    >
                    {isSubmitting ? 'Signing in...' : 'Sign in Now'}
                    </LoadingButton>

                    <div className="mt-4">
                      <p className="formText m-0 text-center text-muted">
                        Don't have an account?{" "}
                        <Link
                          to="/signup"
                          className=" form-link fw-sbold underline"
                        >
                          Join Free Today
                        </Link>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
        </Formik>
        </>
    )
}

export default Form_;