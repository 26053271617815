//action types
export const GET_APPOINTMENT_TYPE_LIST = 'GET_APPOINTMENT_TYPE_LIST';
export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT";
export const APPOINTMENT_BOOKING_LIST = "APPOINTMENT_BOOKING_LIST";
export const RESCHEDULE_APPOINTMENT = "RESCHEDULE_APPOINTMENT";

// get appointment type list
export function appointmentTypeList(data, callback) {
  return {
    type: GET_APPOINTMENT_TYPE_LIST,
    payload : {data, callback},
  };
}

// book appointment
export function bookAppointment(data, callback) {
  return {
    type: BOOK_APPOINTMENT,
    payload : {data, callback},
  };
}

// appointment booking list
export function appointmentBookingList(data, callback) {
  return {
    type: APPOINTMENT_BOOKING_LIST,
    payload : {data, callback},
  };
}

//reschedule
export function rescheduleAppointment(data, callback) {
  return {
    type: RESCHEDULE_APPOINTMENT,
    payload : {data, callback},
  };
}